import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faChevronDown } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Filters, type FiltersField, useEntityCount } from '@trustyou/shared';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Switch,
  Typography,
  styled,
} from '@trustyou/ui';

import { NewViewButton } from './new-view-button-and-dialog';

import useListSurveysPermission from '../../hooks/permissions/use-list-surveys-permission';
import useListVisitDataPermission from '../../hooks/permissions/use-list-visit-data-permission';
import { useFilters } from '../../hooks/use-filters';
import { DropdownLanguage } from '../dropdowns/dropdown-language';
import { DropdownScore } from '../dropdowns/dropdown-score';
import { DropdownSource } from '../dropdowns/dropdown-source';
import { DropdownStatus } from '../dropdowns/dropdown-status';
import { DropdownTimeRange } from '../dropdowns/dropdown-time-range';
import { CategoriesFilterSection } from '../filters/categories';
import { EntityFilter } from '../filters/entities/entity-filter';
import { SurveyFilter } from '../filters/surveys/survey-filter';
import { VisitDataFilter } from '../filters/visit-data/visit-data-filter';

export function DropdownFilters() {
  return (
    <Stack spacing={3} sx={{ paddingBlock: 1 }}>
      <DropdownTimeRange fieldName="timerange" />
      <DropdownStatus fieldName="statuses" />
      <DropdownScore fieldName="scores" />
      <DropdownSource fieldName="sources" />
      <DropdownLanguage fieldName="languages" />
    </Stack>
  );
}

export function AdvancedFiltersSection() {
  const { data: entityCount = 0 } = useEntityCount();
  const hasVisitDataPermission = useListVisitDataPermission();
  const hasSurveyPermission = useListSurveysPermission();

  return (
    <Accordion
      elevation={0}
      sx={{
        '&.MuiAccordion-root': { margin: 0, '&.Mui-expanded': { minHeight: 0 } },
        ':before': { display: 'none' },
      }}
    >
      <AccordionSummary
        sx={{
          paddingX: 0,
          flexDirection: 'row-reverse',
          gap: 2,
          '&.MuiAccordionSummary-root.Mui-expanded': { minHeight: '49px' },
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
        expandIcon={<Icon icon={faChevronDown} />}
      >
        <Typography variant="subtitle1" fontWeight={500}>
          <FormattedMessage id="inbox.filter.section.advanced" defaultMessage="Advanced" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 0 }}>
        <Stack spacing={3}>
          {entityCount > 1 && <EntityFilter />}
          {hasSurveyPermission && <SurveyFilter fieldName={'survey.ids' as FiltersField} />}
          <CategoriesFilterSection />
          {hasVisitDataPermission && <VisitDataFilter />}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export function AnswerableReviewsOnly() {
  const { currentView } = useFilters();
  const { register } = useFormContext<Filters>();

  return (
    <FormControlLabel
      control={
        <Switch
          {...register('answerable')}
          defaultChecked={currentView.filters?.answerable ?? undefined}
        />
      }
      label={
        <>
          <Typography variant="body2" gutterBottom>
            <FormattedMessage
              id="inbox.filter.respondable.label"
              defaultMessage="Answerable reviews only"
            />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="inbox.filter.respondable.description"
              defaultMessage="Excludes reviews that sources do not allow responses to, such as reviews without text"
            />
          </Typography>
        </>
      }
      labelPlacement="start"
      componentsProps={{ typography: { variant: 'body2' } }}
      sx={{ justifyContent: 'space-between', alignItems: 'start' }}
      data-gtm-id="inbox_filters_only_show_respondable_reviews_switch"
    />
  );
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `${theme.spacing(-3)} !important`,
}));

type FiltersTabContentProps = {
  onSave: () => void;
};

export function FiltersTabContent({ onSave }: FiltersTabContentProps) {
  return (
    <Stack spacing={2}>
      <DropdownFilters />
      <AnswerableReviewsOnly />
      <StyledDivider />
      <AdvancedFiltersSection />
      <StyledDivider sx={{ marginTop: '0 !important' }} />
      <Box sx={{ alignSelf: 'start' }}>
        <NewViewButton onSave={onSave} />
      </Box>
    </Stack>
  );
}
