import { MenuItem, type MenuItemProps } from '@mui/material';

export function StyledMenuItem({ sx, ...props }: MenuItemProps) {
  return (
    <MenuItem
      dense
      disableRipple
      sx={{
        paddingBlock: 0,
        paddingInlineStart: 0.5,
        ...sx,
      }}
      {...props}
    />
  );
}
