import { useIntl } from 'react-intl';

import { getLocalizedURL, useMembershipStore } from '@trustyou/shared';

// https://support.cxp.trustyou.com/hc/en-us/requests/new
const ZENDESK = {
  zendeskUrl: process.env.REACT_APP_URLS__ZENDESK,
  newRequestURLSegment: '/requests/new',
  requestSupportFormId: '13260452897693',
  shareIdeasFormId: '13260452897693',
  reportSentimentIssueFormId: '23811313197597',
  requestManualUploadFormId: '24277716292893',
} as const;

export function useZendeskLinks() {
  const { locale } = useIntl();
  const { membership } = useMembershipStore();

  const baseUrl = new URL(ZENDESK.zendeskUrl as string);
  const localizedBaseUrl = getLocalizedURL(baseUrl, locale);

  const newRequestUrl = localizedBaseUrl;
  newRequestUrl.pathname = newRequestUrl.pathname.concat(ZENDESK.newRequestURLSegment);
  newRequestUrl.searchParams.set('org', membership?.organizationId as string);

  const reportSentimentIssueUrl = newRequestUrl;
  reportSentimentIssueUrl.searchParams.set('ticket_form_id', ZENDESK.reportSentimentIssueFormId);

  const getZendeskLink = () => {
    const baseUrl = new URL(ZENDESK.zendeskUrl as string);
    return getLocalizedURL(baseUrl, locale).toString();
  };

  const getNewRequestLink = () => {
    const newRequestUrl = new URL(getZendeskLink());
    newRequestUrl.pathname = newRequestUrl.pathname.concat(ZENDESK.newRequestURLSegment);
    newRequestUrl.searchParams.set('org', membership?.organizationId as string);
    return newRequestUrl.toString();
  };

  const getShareIdeasLink = () => {
    const shareIdeasUrl = new URL(getNewRequestLink());
    shareIdeasUrl.searchParams.set('ticket_form_id', ZENDESK.shareIdeasFormId);
    return shareIdeasUrl.toString();
  };

  const getReportSentimentIssueLink = () => {
    const reportSentimentIssueUrl = new URL(getNewRequestLink());
    reportSentimentIssueUrl.searchParams.set('ticket_form_id', ZENDESK.reportSentimentIssueFormId);
    return reportSentimentIssueUrl.toString();
  };

  const getRequestManualUploadLink = () => {
    const requestManualUploadUrl = new URL(getNewRequestLink());
    requestManualUploadUrl.searchParams.set('ticket_form_id', ZENDESK.requestManualUploadFormId);
    return requestManualUploadUrl.toString();
  };

  return {
    getZendeskLink,
    getNewRequestLink,
    getShareIdeasLink,
    getReportSentimentIssueLink,
    getRequestManualUploadLink,
  };
}
