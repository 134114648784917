import { useIntl } from 'react-intl';

import { ALL } from '@trustyou/shared';

import type { Group } from '../../types';

export function useTimeRangeGroups() {
  const intl = useIntl();

  const timeRangeGroups: Group[] = [
    {
      label: '',
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.all',
            defaultMessage: 'All time',
          }),
          value: ALL,
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.daysAndWeeks',
        defaultMessage: 'Days and weeks',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.yesterday',
            defaultMessage: 'Yesterday',
          }),
          value: 'yesterday',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.last7Days',
            defaultMessage: 'Last 7 days',
          }),
          value: 'last-7-days',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.lastWeek',
            defaultMessage: 'Last week',
          }),
          value: 'last-week',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.months',
        defaultMessage: 'Months',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.monthToDate',
            defaultMessage: 'Month to date',
          }),
          value: 'month-to-date',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.lastFullMonth',
            defaultMessage: 'Last full month',
          }),
          value: 'last-full-month',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.last24Months',
            defaultMessage: 'Last 24 months',
          }),
          value: 'last-24-months',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.quarters',
        defaultMessage: 'Quarters',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.quarterToDate',
            defaultMessage: 'Quarter to Date',
          }),
          value: 'quarter-to-date',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.lastFullQuarter',
            defaultMessage: 'Last full quarter',
          }),
          value: 'last-full-quarter',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.years',
        defaultMessage: 'Years',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.yearToDate',
            defaultMessage: 'Year to date',
          }),
          value: 'year-to-date',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.yearToLastMonth',
            defaultMessage: 'Year to last month',
          }),
          value: 'year-to-last-month',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.timeRange.lastFullYear',
            defaultMessage: 'Last full year',
          }),
          value: 'last-full-year',
        },
      ],
    },
  ];

  return timeRangeGroups;
}
