import type { SyntheticEvent } from 'react';
import { type NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { useMembershipStore } from '../store';

export const useRouteUtils = () => {
  const { pathname } = useLocation();
  const { membership } = useMembershipStore();
  const navigate = useNavigate();

  const goUp = (level?: number | SyntheticEvent) => {
    const actualLevel = typeof level === 'number' ? level : 1;
    const newPath = pathname
      .split('/')
      .slice(0, 0 - actualLevel)
      .join('/');
    navigate(newPath);
  };

  const navigateWithBase = (path: string, options?: NavigateOptions) => {
    if (membership?.id) {
      navigate(`/org/${membership?.id}/${path}`, options);
    }
  };

  const navigateWithEntityDetails = (entityId: string) => {
    navigate(
      pathname.replace(/\/organization\/entities\/[^/]+/, `/organization/entities/${entityId}`)
    );
  };

  const navigateToHome = () => {
    navigateWithBase('home');
  };

  return {
    goUp,
    navigateWithBase,
    navigateToHome,
    navigateWithEntityDetails,
  };
};
