import { defineMessages } from 'react-intl';

import { USER_ROLES } from '../types';

export const userActions = defineMessages({
  logout: {
    id: 'tyDesign.userMenu.logout',
    defaultMessage: 'Logout',
  },
});

export const errorMessages = defineMessages({
  genericError: {
    // TODO: Replace this translation with { id: 'common.error', defaultMessage: 'Something went wrong' }.
    // For now, we are taking advantage that the translation 'tyDesign.somethingWentWrong' is already done in Phrase.
    id: 'tyDesign.somethingWentWrong',
    defaultMessage: 'Oops! Something went wrong',
  },
  invitationAcceptance: {
    id: 'error.invitation-acceptance',
    defaultMessage:
      'Your invitation could not be activated at this time. Please try again later or contact support if the issue persists.',
  },
});

export const searchPlaceholders = defineMessages({
  search: {
    id: 'common.search',
    defaultMessage: 'Search',
  },
  typeToSearch: {
    id: 'common.type-to-search',
    defaultMessage: 'Type to search',
  },
  searchEntities: {
    id: 'entities.search',
    defaultMessage: 'Search entities',
  },
  searchGroups: {
    id: 'groups.search',
    defaultMessage: 'Search groups',
  },
  searchCompetitors: {
    id: 'competitors.search',
    defaultMessage: 'Search competitors',
  },
  searchUsers: {
    id: 'users.search',
    defaultMessage: 'Search users',
  },
  searchAccessGroups: {
    id: 'access-groups.search',
    defaultMessage: 'Search access groups',
  },
  searchSubscriptions: {
    id: 'subscriptions.search',
    defaultMessage: 'Search subscriptions',
  },
});

export const validationErrors = defineMessages({
  missingEmail: {
    id: 'common.email.missing',
    defaultMessage: 'Enter an email address',
  },
  invalidEmail: {
    id: 'common.email.invalid',
    defaultMessage: 'Enter a valid email address',
  },
  existingEmail: {
    id: 'common.email.exists',
    defaultMessage: 'This email address already exists',
  },
  maxCharacters: {
    id: 'common.max.characters',
    defaultMessage: 'Maximum 50 characters',
  },
  validURLExample: {
    id: 'common.valid.url.example',
    defaultMessage: 'Enter a valid URL e.g. https://www.example.com',
  },
  required: {
    id: 'common.required',
    defaultMessage: 'This field is required',
  },
  missingOrganizationName: {
    id: 'common.organization.name.missing',
    defaultMessage: 'Enter an organization name',
  },
  existingAccessGroup: {
    id: 'common.access-group.exists',
    defaultMessage: 'Entered name already exists',
  },
  requiredAccessGroup: {
    id: 'common.access-group.required',
    defaultMessage: 'Enter a name of access group',
  },
});

export const nothingFound = defineMessages({
  noEntities: {
    id: 'entities.nothing.found',
    defaultMessage: 'No entities found. Make sure the name is spelled correctly',
  },
  noGroups: {
    id: 'groups.nothing.found',
    defaultMessage: 'No groups found. Make sure the name is spelled correctly.',
  },
});

export const homeHeadings = defineMessages({
  dashboards: {
    id: 'home.heading.dashboards',
    defaultMessage: 'Dashboards',
  },
  reports: {
    id: 'home.heading.reports',
    defaultMessage: 'Reports',
  },
  managers: {
    id: 'home.heading.managers',
    defaultMessage: 'Manage your setup',
  },
});

export const navigationNodes = defineMessages({
  home: {
    id: 'navigation.home',
    defaultMessage: 'Home',
  },
  reviewsInbox: {
    id: 'navigation.reviews-inbox.title',
    defaultMessage: 'Reviews inbox',
  },
  reviewsInboxDescription: {
    id: 'navigation.reviews-inbox.description',
    defaultMessage: 'Read and respond to you online- and survey reviews',
  },
  reviewPerformance: {
    id: 'navigation.review-performance.title',
    defaultMessage: 'Review performance',
  },
  reviewPerformanceDescription: {
    id: 'navigation.review-performance.description',
    defaultMessage: 'See how your entities are performing',
  },
  insights: {
    id: 'navigation.insights',
    defaultMessage: 'Insights',
  },
  insightsReviews: {
    id: 'navigation.insights.reviews',
    defaultMessage: 'Reviews',
  },
  insightsSentiment: {
    id: 'navigation.insights.sentiment',
    defaultMessage: 'Sentiment',
  },
  insightsSentimentDescription: {
    id: 'navigation.insights.sentiment.description',
    defaultMessage: 'Analyze the content of received feedback',
  },
  insightsCompetitors: {
    id: 'navigation.insights.competitors',
    defaultMessage: 'Competitors',
  },
  insightsCompetitorsDescription: {
    id: 'navigation.insights.competitors.description',
    defaultMessage: 'See how you perform against your competitors',
  },
  insightsBenchmarks: {
    id: 'navigation.insights.benchmarks',
    defaultMessage: 'Benchmarks',
  },
  insightsSurveyReviews: {
    id: 'navigation.insights.survey.reviews',
    defaultMessage: 'Survey reviews',
  },
  insightsSurveyReviewsDescription: {
    id: 'navigation.insights.survey.reviews.description',
    defaultMessage: 'Explore guest feedback from your surveys',
  },
  insightsSurveyStatistics: {
    id: 'navigation.insights.survey.statistics',
    defaultMessage: 'Survey statistics',
  },
  insightsSurveyStatisticsDescription: {
    id: 'navigation.insights.survey.statistics.description',
    defaultMessage: 'Check in detail how your surveys perform',
  },
  inbox: {
    id: 'navigation.inbox',
    defaultMessage: 'Inbox',
  },
  surveyManager: {
    id: 'navigation.survey-manager',
    defaultMessage: 'Survey manager',
  },
  surveyInsights: {
    id: 'navigation.survey-insights',
    defaultMessage: 'Survey insights',
  },
  widgets: {
    id: 'navigation.widgets',
    defaultMessage: 'Widgets',
  },
  organization: {
    id: 'navigation.organization',
    defaultMessage: 'Organization',
  },
  entities: {
    id: 'navigation.entities',
    defaultMessage: 'Entities',
  },
  users: {
    id: 'navigation.users',
    defaultMessage: 'Users',
  },
  accessGroups: {
    id: 'navigation.access-groups',
    defaultMessage: 'Access Groups',
  },
  support: {
    id: 'navigation.support',
    defaultMessage: 'Support',
  },
  userSettings: {
    id: 'navigation.settings.user',
    defaultMessage: 'Profile',
  },
  appSettings: {
    id: 'navigation.settings.title',
    defaultMessage: 'Settings',
  },
  appGeneralSettings: {
    id: 'navigation.settings.general',
    defaultMessage: 'General',
  },
  appSubscriptionsSettings: {
    id: 'navigation.settings.subscription',
    defaultMessage: 'Subscription',
  },
  appReviewProvidersSettings: {
    id: 'navigation.settings.reviewProviders',
    defaultMessage: 'Review Providers',
  },
  subscriptions: {
    id: 'navigation.subscriptions',
    defaultMessage: 'Subscriptions',
  },
  benchmarks: {
    id: 'navigation.benchmarks',
    defaultMessage: 'Benchmarks',
  },
  benchmarksDescription: {
    id: 'navigation.benchmarks.description',
    defaultMessage: 'Compare your entities’ performance to benchmarks',
  },
  reports: {
    id: 'navigation.reports',
    defaultMessage: 'Reports',
  },
  customAttributes: {
    id: 'navigation.settings.custom-attributes',
    defaultMessage: 'Custom Attributes',
  },
  visitData: {
    id: 'navigation.visit-data',
    defaultMessage: 'Visit data',
  },
});

export const introductionInstructions = defineMessages({
  download: {
    id: 'entities.importer.introduction.download.spreadsheet',
    defaultMessage: 'Download the spreadsheet',
  },
  downloadCustomized: {
    id: 'entities.importer.introduction.download.customized.spreadsheet',
    defaultMessage: 'Download the customized spreadsheet',
  },
  fillMandatory: {
    id: 'entities.importer.introduction.fill.mandatory',
    defaultMessage: 'Fill in all the mandatory fields',
  },
  fillNewEntities: {
    id: 'entities.importer.introduction.fill.new',
    defaultMessage:
      'Fill in all new entities according to the predefined table header. Avoid adding existing entities, as this will not override your organization data.',
  },
  addCustom: {
    id: 'entities.importer.introduction.add.custom.attributes',
    defaultMessage: 'Add up to 3 {custom_attributes} and fill in the values',
  },
  next: {
    id: 'entities.importer.introduction.next',
    defaultMessage: 'Click ”Next” to upload the spreadsheet',
  },
  saveAs: {
    id: 'entities.importer.introduction.save.as',
    defaultMessage: 'Save the file as CSV and upload it in the next step',
  },
});

export const numberNames = defineMessages({
  '1': {
    id: 'common.number-one',
    defaultMessage: 'one',
  },
  '2': {
    id: 'common.number-two',
    defaultMessage: 'two',
  },
  '3': {
    id: 'common.number-three',
    defaultMessage: 'three',
  },
  '4': {
    id: 'common.number-four',
    defaultMessage: 'four',
  },
  '5': {
    id: 'common.number-five',
    defaultMessage: 'five',
  },
  '6': {
    id: 'common.number-six',
    defaultMessage: 'six',
  },
  '7': {
    id: 'common.number-seven',
    defaultMessage: 'seven',
  },
  '8': {
    id: 'common.number-eight',
    defaultMessage: 'eight',
  },
  '9': {
    id: 'common.number-nine',
    defaultMessage: 'nine',
  },
  '10': {
    id: 'common.number-ten',
    defaultMessage: 'ten',
  },
  '15': {
    id: 'common.number-fifteen',
    defaultMessage: 'fifteen',
  },
});

export const roleDefinitions = defineMessages({
  [USER_ROLES.ADMIN]: {
    id: 'user.roles.admin',
    defaultMessage: 'Admin',
  },
  [USER_ROLES.OWNER]: {
    id: 'user.roles.owner',
    defaultMessage: 'Owner',
  },
  [USER_ROLES.EDITOR]: {
    id: 'user.roles.editor',
    defaultMessage: 'Editor',
  },
  [USER_ROLES.READ_ONLY]: {
    id: 'user.roles.read.only',
    defaultMessage: 'Read Only',
  },
  [USER_ROLES.SERVICE]: {
    id: 'user.roles.service',
    defaultMessage: 'Service',
  },
  unknown: {
    id: 'user.roles.unknown',
    defaultMessage: 'Unknown',
  },
});

export const roleDescriptions = defineMessages({
  [USER_ROLES.ADMIN]: {
    id: 'user.roles.description.admin',
    defaultMessage: 'Access to all features, including user management and product settings',
  },
  [USER_ROLES.EDITOR]: {
    id: 'user.roles.descriptions.editor',
    defaultMessage:
      'Access to all regular features, excluding user management and product settings',
  },
  [USER_ROLES.READ_ONLY]: {
    id: 'user.roles.descriptions.read.only',
    defaultMessage: 'View access only to all regular features',
  },
});

export const commonFields = defineMessages({
  all: {
    id: 'common.all',
    defaultMessage: 'All',
  },
  sector: {
    id: 'common.sector',
    defaultMessage: 'Sector',
  },
  brand: {
    id: 'common.brand',
    defaultMessage: 'Brand',
  },
  created: {
    id: 'common.created',
    defaultMessage: 'Created',
  },
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
  },
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
  },
  lastEdited: {
    id: 'common.last.edited',
    defaultMessage: 'Last edited',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
  },
  continent: {
    id: 'common.continent',
    defaultMessage: 'Continent',
  },
  country: {
    id: 'common.country',
    defaultMessage: 'Country',
  },
  countryCode: {
    id: 'common.country.code',
    defaultMessage: 'Country Code',
  },
  countryName: {
    id: 'common.country.name',
    defaultMessage: 'Country Name',
  },
  zip: {
    id: 'common.zip',
    defaultMessage: 'Zip',
  },
  city: {
    id: 'common.city',
    defaultMessage: 'City',
  },
  address: {
    id: 'common.address',
    defaultMessage: 'Address',
  },
  reviewProviders: {
    id: 'common.review-providers',
    defaultMessage: 'Review providers',
  },
  changesSaved: {
    id: 'common.changesSaved',
    defaultMessage: 'Changes saved',
  },
  saveChanges: {
    id: 'common.saveChanges',
    defaultMessage: 'Save Changes',
  },
  signature: {
    id: 'common.signature',
    defaultMessage: 'Signature',
  },
  language: {
    id: 'common.language',
    defaultMessage: 'Language',
  },
  status: {
    id: 'common.status',
    defaultMessage: 'Status',
  },
  general: {
    id: 'common.general',
    defaultMessage: 'General',
  },
  notifications: {
    id: 'common.notifications',
    defaultMessage: 'Notifications',
  },
  inbox: {
    id: 'common.inbox',
    defaultMessage: 'Inbox',
  },
  dashboard: {
    id: 'common.dashboard',
    defaultMessage: 'Dashboard',
  },
  accessGroup: {
    id: 'common.access-group',
    defaultMessage: 'Access Group',
  },
  subscription: {
    id: 'common.subscription',
    defaultMessage: 'Subscription',
  },
  entity: {
    id: 'common.entity',
    defaultMessage: 'Entity',
  },
  entities: {
    id: 'common.entities',
    defaultMessage: 'Entities',
  },
  users: {
    id: 'common.users',
    defaultMessage: 'Users',
  },
  owner: {
    id: 'common.owner',
    defaultMessage: 'Owner',
  },
  subscriptionPlan: {
    id: 'common.subscription-plan',
    defaultMessage: 'Subscription plan',
  },
  numberOfUsers: {
    id: 'common.number-of-users',
    defaultMessage: 'Number of users',
  },
  numberOfEntities: {
    id: 'common.number-of-entities',
    defaultMessage: 'Number of entities',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Confirm',
  },
  selectFile: {
    id: 'common.select-file',
    defaultMessage: 'Select file',
  },
  uploadImage: {
    id: 'common.upload-image',
    defaultMessage: 'Upload image',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
  },
  customAttributes: {
    id: 'common.custom-attributes',
    defaultMessage: 'Custom attributes',
  },
  segments: {
    id: 'common.segments',
    defaultMessage: 'Segments',
  },
  connections: {
    id: 'common.connections',
    defaultMessage: 'Connections',
  },
  sources: {
    id: 'common.sources',
    defaultMessage: 'Sources',
  },
  competitors: {
    id: 'common.competitors',
    defaultMessage: 'Competitors',
  },
  notSet: {
    id: 'common.not-set',
    defaultMessage: 'Not set',
  },
});

export const absentValues = defineMessages({
  notAvailable: {
    id: 'common.not-available',
    defaultMessage: 'Not available',
  },
  notApplicable: {
    id: 'common.not-applicable',
    defaultMessage: 'Not applicable',
  },
});

export const actionMessages = defineMessages({
  okay: {
    id: 'action.okay',
    defaultMessage: 'Okay',
  },
  cancel: {
    id: 'action.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'action.save',
    defaultMessage: 'Save',
  },
  edit: {
    id: 'action.edit',
    defaultMessage: 'Edit',
  },
  next: {
    id: 'action.next',
    defaultMessage: 'Next',
  },
  delete: {
    id: 'action.delete',
    defaultMessage: 'Delete',
  },
  select: {
    id: 'action.select',
    defaultMessage: 'Select',
  },
  confirmUpdate: {
    id: 'action.confirm-update',
    defaultMessage: 'Confirm update',
  },
  // TODO: Bring other "action.* keys here.
});

export const usersMessages = defineMessages({
  scopesAdvancedRules: {
    id: 'users.scopes.advanced-rules',
    defaultMessage: 'Advanced rules',
  },
  scopesEntityCount: {
    id: 'users.scopes.entity-count',
    defaultMessage: '{count, plural, one {# entity} other {# entities}}',
  },
});
