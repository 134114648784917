/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  CONNECT_PROVIDER_NAMES,
  CREATE_BOOKING_CONNECTION,
  commonFields,
  useReviewProviders,
} from '@trustyou/shared';
import {
  Box,
  CircularProgress,
  ContentPane,
  EmptyReviewProviderState,
  FullScreenModal,
  Typography,
} from '@trustyou/ui';

import ReviewProviderIntro from './ReviewProviderIntro';

import { BookingConnectManager, GoogleConnectManager } from '../../..//components';
import { ReviewProviderCard } from '../../../components/ReviewProviderCard/ReviewProviderCard';
import { useConnectReviewProviderPermission } from '../../../hooks';
import { styles } from './styles';

const ReviewProvidersSettings = () => {
  const isConnectEnabled = useConnectReviewProviderPermission();
  const { isLoading, data: reviewProviders, isError } = useReviewProviders();

  const queryClient = useQueryClient();
  const bookingConnectStatus = queryClient.getQueryData([CREATE_BOOKING_CONNECTION]) as
    | string
    | undefined;

  const [bookingConnectDialogOpen, setBookingConnectDialogOpen] = useState(false);

  const openBookingConnectDialog = () => setBookingConnectDialogOpen(true);

  const closeBookingConnectDialog = () => setBookingConnectDialogOpen(false);

  const [googleConnectDialogOpen, setGoogleConnectDialogOpen] = useState(false);

  const openGoogleConnectDialog = () => setGoogleConnectDialogOpen(true);

  const closeGoogleConnectDialog = () => setGoogleConnectDialogOpen(false);

  const handleConnectProvider = (name: string) => {
    if (name === CONNECT_PROVIDER_NAMES.BOOKING) {
      openBookingConnectDialog();
    }
    if (name === CONNECT_PROVIDER_NAMES.GOOGLE) {
      openGoogleConnectDialog();
    }
  };

  const needShowIntroBannerOnStart = () => {
    if (!reviewProviders) return false;

    if (!isConnectEnabled) return false;

    const bookingProvider = reviewProviders.find(
      (provider) => provider.name === CONNECT_PROVIDER_NAMES.BOOKING
    );
    if (!bookingProvider) return false;
    const googleProvider = reviewProviders.find(
      (provider) => provider.name === CONNECT_PROVIDER_NAMES.GOOGLE
    );
    if (!googleProvider) return false;

    return (
      googleProvider.provider_account_count === 0 && bookingProvider.provider_account_count === 0
    );
  };

  const getGridContent = () => {
    if (isError) return <EmptyReviewProviderState />;
    if (isLoading) return <CircularProgress size={24} />;
    if (reviewProviders?.length === 0) return <EmptyReviewProviderState />;
    return reviewProviders
      ?.filter((row) => row.name !== 'analytics.trustyou.com')
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name, provider_account_count: connectedAPIs, is_configuring: isConfiguring }) => (
        <Box key={id}>
          <ReviewProviderCard
            id={id}
            title={name}
            isConfiguring={isConfiguring}
            connectedAPIs={connectedAPIs}
            isConnectEnabled={isConnectEnabled}
            handleConnectProvider={handleConnectProvider}
          />
        </Box>
      ));
  };

  return (
    <ContentPane
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
      headers={[
        <Box>
          <Typography variant="h6" color="text.primary">
            <FormattedMessage
              id={commonFields.reviewProviders.id}
              defaultMessage={commonFields.reviewProviders.defaultMessage}
            />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="app.settings.review-providers.description"
              defaultMessage="Manage review providers to aggregate your reviews and responses"
            />
          </Typography>
        </Box>,
      ]}
    >
      {reviewProviders && <ReviewProviderIntro showOnStart={needShowIntroBannerOnStart()} />}
      <Box sx={styles.gridContent}>{getGridContent()}</Box>
      <FullScreenModal
        open={bookingConnectDialogOpen}
        onClose={closeBookingConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
        closeButtonGTMID={bookingConnectStatus}
      >
        <BookingConnectManager onClose={closeBookingConnectDialog} />
      </FullScreenModal>
      <FullScreenModal
        open={googleConnectDialogOpen}
        onClose={closeGoogleConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <GoogleConnectManager />
      </FullScreenModal>
    </ContentPane>
  );
};

export default ReviewProvidersSettings;
