/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Control, UseFormWatch } from 'react-hook-form';

import { Stack } from '@trustyou/ui';

import { QUESTION_TYPES_BY_CATEGORY } from '../../../components/feedback/constants';
import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NumberQuestion,
  ScaleQuestion,
  SelectQuestion,
  type SelectQuestionVariant,
  TextQuestion,
  type TextQuestionVariant,
} from '../../../components/feedback/question-types';
import type { Option } from '../../../components/feedback/types';
import type { SurveyQuestionEditorProps } from '../../../types/question';

export const QuestionLibraryEditorPreview = ({
  watch,
  control,
}: {
  watch: UseFormWatch<SurveyQuestionEditorProps>;
  control: Control<any>;
}) => {
  return (
    <Stack sx={{ width: '80%' }}>
      {QUESTION_TYPES_BY_CATEGORY.select.includes(watch('questionType') as string) && (
        <SelectQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          variant={watch('questionTypeVariation') as SelectQuestionVariant}
          options={(watch('selectOptions') ?? []) as Option[]}
        />
      )}
      {QUESTION_TYPES_BY_CATEGORY.scale.includes(watch('questionType') as string) && (
        <ScaleQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          options={(watch('ratingOptions') ?? []) as Option[]}
        />
      )}
      {watch('questionType') === 'text' && (
        <TextQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          variant={watch('questionTypeVariation') as TextQuestionVariant}
          maxCharacters={watch('textMaxCharacters') as number}
        />
      )}
      {watch('questionType') === 'number' && (
        <NumberQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          min={watch('minNumber') as number}
          max={watch('maxNumber') as number}
        />
      )}
      {watch('questionType') === 'date' && (
        <DateQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          variant={watch('questionTypeVariation') as DateQuestionVariant}
        />
      )}
      {watch('questionType') === 'binary' && (
        <BooleanQuestion
          title={watch('questionText') as string}
          description={watch('additionalText') as string}
          variant={watch('questionTypeVariation') as BooleanQuestionVariant}
          options={(watch('binaryOptions') ?? []) as Option[]}
        />
      )}
    </Stack>
  );
};
