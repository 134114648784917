import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  contentPane: { height: '100%' },
  container: {
    height: '100%',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingX: 3,
  },
  body: {
    paddingX: 3,
    paddingY: 2,
    paddingBottom: 1.5,
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
  tabsBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  searchBar: {
    textAlign: 'right',
    '& .MuiFormControl-root': {
      width: '216px',
    },
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
  },
};
