import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  FETCH_ENTITY_SUBSCRIPTIONS,
  SEARCH_DEBOUNCE_TIME_MS,
  type SubscriptionStatus,
  absentValues,
  commonFields,
  getPaginationRequest,
  searchPlaceholders,
  useEntitySubscriptions,
  useEntitySubscriptionsGridStore,
  useGridParams,
} from '@trustyou/shared';
import { DataGrid, DataGridCell, EmptyEntitySubscriptions, SearchBar } from '@trustyou/ui';

import { getNumberOfEntitiesValue } from './Common/utils';

import { SUBSCRIPTION_STATUS_CHIP_MAP } from '../../components';
import { styles } from './styles';

const EntitySubscriptions = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const gridState = useEntitySubscriptionsGridStore();

  const { paginationModel, searchKey } = gridState;

  const { onPaginationModelChange, onSearch } = useGridParams(
    FETCH_ENTITY_SUBSCRIPTIONS,
    gridState
  );

  const { data, isPending: isLoading } = useEntitySubscriptions(
    getPaginationRequest(paginationModel),
    searchKey
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell primaryValue={row.name} secondaryValue={row.plan_name} />
      ),
    },
    {
      field: 'owner',
      headerName: intl.formatMessage(commonFields.owner),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell
          primaryValue={row.owner ? row.owner.name : intl.formatMessage(absentValues.notAvailable)}
          secondaryValue={row.owner?.email}
        />
      ),
    },
    {
      field: 'number_of_entities',
      headerName: intl.formatMessage(commonFields.numberOfEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell
          primaryValue={getNumberOfEntitiesValue(
            row.number_of_consumed_entities,
            row.number_of_allowed_entities
          )}
        />
      ),
    },
    {
      field: 'number_of_users',
      headerName: intl.formatMessage(commonFields.numberOfUsers),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell
          primaryValue={`${row.number_of_consumed_members}/${row.number_of_allowed_members}`}
        />
      ),
    },
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) =>
        row.status && SUBSCRIPTION_STATUS_CHIP_MAP[row.status as SubscriptionStatus],
    },
  ];

  if (data?.pagination?.total === 0 && !isLoading && !searchKey)
    return <EmptyEntitySubscriptions />;

  const rows = data?.data || [];

  return (
    <>
      <SearchBar
        onSearch={onSearch}
        debounceTime={SEARCH_DEBOUNCE_TIME_MS}
        placeholder={intl.formatMessage(searchPlaceholders.searchSubscriptions)}
        id="subscriptions"
        variant="standard"
        defaultValue={searchKey}
        sx={styles.searchBar}
      />
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowHeight={64}
        columns={columns}
        rows={rows}
        rowCount={data?.pagination.total}
        onRowClick={(params) => navigate(`${params.row.id}/details`)}
        loading={isLoading}
        autoHeight={false}
        containerStyle={styles.entitySubTableContainer}
      />
    </>
  );
};

export default EntitySubscriptions;
