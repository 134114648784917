import { type ChangeEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { Filters } from '@trustyou/shared';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@trustyou/ui';

import { useFilters } from '../../hooks';

type NewViewButtonProps = {
  onSave: () => void;
};

export function NewViewButton({ onSave }: NewViewButtonProps) {
  const intl = useIntl();
  const { views, saveView } = useFilters();
  const { control, getValues, watch } = useFormContext<Filters>();

  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const watchedViewname = watch('viewname') ?? '';
  const isValid = watchedViewname.trim().length > 0 && !errorMessage;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: unknown[]) => void
  ) => {
    onChange(event.target.value);
    const viewname = event.target.value.trim();
    const isEmpty = viewname.length === 0;
    const isViewLabelAlreadyTaken = views.some((view) => view.label === viewname);
    if (isEmpty) {
      setErrorMessage(
        intl.formatMessage({
          id: 'inbox.views.label.error.required',
          defaultMessage: 'View label is required',
        })
      );
    } else if (isViewLabelAlreadyTaken) {
      setErrorMessage(
        intl.formatMessage({
          id: 'inbox.views.label.error.already-exists',
          defaultMessage: 'This View label already exists',
        })
      );
    } else {
      setErrorMessage('');
    }
  };

  const handleSave = () => {
    saveView({ label: watchedViewname, filters: getValues() }, () => {
      onSave();
      setIsDialogOpen(false);
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValid) {
      handleSave();
    }
  };

  return (
    <>
      <Button
        data-gtm-id="inbox_filters_save_as_view_button"
        onClick={() => setIsDialogOpen(true)}
        startIcon={<FontAwesomeIcon icon={faBookmark} />}
      >
        <FormattedMessage id="inbox.filter.saveAsView" defaultMessage="Save filters as View" />
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle>
          <FormattedMessage
            id="inbox.filters.custom-view.dialog.title"
            defaultMessage="New custom View"
          />
        </DialogTitle>
        <DialogContent sx={{ height: (theme) => theme.spacing(15) }}>
          <Controller
            name="viewname"
            control={control}
            render={({ field: { value = '', onChange } }) => (
              <TextField
                value={value}
                onChange={(event) => handleChange(event, onChange)}
                onKeyDown={handleKeyDown}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="dense"
                autoComplete="off"
                autoFocus
                fullWidth
                label={intl.formatMessage({
                  id: 'inbox.filters.custom-view.label',
                  defaultMessage: 'Custom View label',
                })}
                placeholder={intl.formatMessage({
                  id: 'inbox.filters.custom-view.placeholder',
                  defaultMessage: 'My custom View',
                })}
                helperText={errorMessage}
                error={!!errorMessage}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" color="inherit" onClick={() => setIsDialogOpen(false)}>
            <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="button" variant="contained" disabled={!isValid} onClick={handleSave}>
            <FormattedMessage id="inbox.action.save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
