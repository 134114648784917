import { useIntl } from 'react-intl';

import type { Group } from '../../types';

export function useStatusGroups() {
  const intl = useIntl();

  const statusGroups: Group[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.unresponded',
        defaultMessage: 'Unresponded',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.unread',
            defaultMessage: 'Unread',
          }),
          value: 'unread',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.read',
            defaultMessage: 'Read',
          }),
          value: 'read',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.responded',
        defaultMessage: 'Responded',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.marked-as-responded',
            defaultMessage: 'Marked as responded',
          }),
          value: 'responded',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.confirmed',
            defaultMessage: 'Response confirmed',
          }),
          value: 'confirmed',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.moderated',
        defaultMessage: 'Moderated',
      }),
      options: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.marked-as-deleted',
            defaultMessage: 'Marked as deleted',
          }),
          value: 'marked_as_deleted',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.deleted',
            defaultMessage: 'Deleted',
          }),
          value: 'deleted',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.marked-as-inappropriate',
            defaultMessage: 'Marked as inappropriate',
          }),
          value: 'marked_as_inappropriate',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.inappropriate',
            defaultMessage: 'Inappropriate',
          }),
          value: 'inappropriate',
        },
      ],
    },
  ];

  return statusGroups;
}
