import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type Subscription,
  USER_SCOPES,
  isTemporaryHidden,
  useAssignableEntityCount,
  useScopeStore,
} from '@trustyou/shared';
import { Alert, ScopeSelector, SingleEntitySelector, Stack, Typography } from '@trustyou/ui';

import { AdvancedScopeSelection } from '../../../../../components';
import { useCheckEntityManager, useManageAccessGroups } from '../../../../../hooks';
import { AccessGroupSelection } from '../AccessGroupSelection';
import { SubscriptionEntitySelection } from '../EntitySelection';
import { GroupSelection } from '../GroupSelection';

type ScopeSelectionProps = {
  subscription: Subscription;
  validated?: boolean;
};

export const ScopeSelection = ({ subscription, validated }: ScopeSelectionProps) => {
  const [isScopeByGroupsEnabled, setIsScopeByGroupsEnabled] = useState(false);
  const { selectedScope, advancedSelectionScope, setAdvancedSelectionScope } = useScopeStore();
  const isEntityManager = useCheckEntityManager();
  const isManageAccessGroupsAllowed = useManageAccessGroups();
  const { data: assignableEntityCount } = useAssignableEntityCount(subscription.id);

  // TODO: Remove this logic once all scopes by groups are migrated to advanced scope
  useEffect(() => {
    if (selectedScope === USER_SCOPES.BY_GROUPS) {
      setIsScopeByGroupsEnabled(true);
    } else if (selectedScope === USER_SCOPES.NONE) {
      setIsScopeByGroupsEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope]);

  const getAvailableScopes = (): USER_SCOPES[] => {
    if (subscription.seat_type === 'single' || isEntityManager) return [USER_SCOPES.BY_ENTITIES];
    if (subscription.is_org_subscription === false) {
      return [USER_SCOPES.ALL_ENTITIES, USER_SCOPES.BY_ENTITIES];
    }

    const showAccessGroups =
      !isTemporaryHidden('CXP-customers-access-groups') && isManageAccessGroupsAllowed;

    return Object.values(USER_SCOPES).filter((value) => {
      if (value === USER_SCOPES.NONE) return false;
      if (value === USER_SCOPES.ACCESS_GROUP && !showAccessGroups) return false;
      if (value === USER_SCOPES.BY_GROUPS && !isScopeByGroupsEnabled) return false;
      return true;
    });
  };

  const renderSelectionContent = () => {
    switch (selectedScope) {
      case USER_SCOPES.ALL_ENTITIES:
      case USER_SCOPES.BY_ENTITIES:
        if (selectedScope === USER_SCOPES.ALL_ENTITIES && subscription.seat_type !== 'single') {
          // allow to render entity picker for wrong-chosen all entities scope with single type
          return null;
        }
        if (subscription.number_of_allowed_entities === 1 || assignableEntityCount === 1)
          return <SingleEntitySelector subscription={subscription} />;
        // eslint-disable-next-line no-case-declarations
        const mode = subscription.seat_type === 'single' || isEntityManager ? 'single' : 'multiple';
        return (
          <SubscriptionEntitySelection
            subscriptionId={subscription.id}
            mode={mode}
            validated={validated}
          />
        );
      case USER_SCOPES.NONE:
        return validated ? (
          <Alert severity="error">
            <FormattedMessage id="organization.users.scopeSelect" defaultMessage="Select a scope" />
          </Alert>
        ) : null;
      case USER_SCOPES.ACCESS_GROUP:
        return <AccessGroupSelection validated={validated} />;
      case USER_SCOPES.BY_GROUPS:
        return <GroupSelection validated={validated} />;
      case USER_SCOPES.ADVANCED_SELECTION:
        return (
          <AdvancedScopeSelection
            validated={validated}
            scope={advancedSelectionScope}
            setScope={(scope) => setAdvancedSelectionScope(scope, true)}
          />
        );
      default:
        return null;
    }
  };

  if (subscription.number_of_consumed_entities === 0 && subscription.number_of_allowed_entities > 0)
    return null;

  const availableScopes = getAvailableScopes();

  const showScopeSelector = availableScopes.length > 1;

  return (
    <>
      {showScopeSelector && (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="organization.users.set-user-data-access"
              defaultMessage="Set data access for this user"
            />
          </Typography>
          <ScopeSelector subscription={subscription} scopes={availableScopes} />
        </Stack>
      )}
      {renderSelectionContent()}
    </>
  );
};
