import { useRef } from 'react';

import {
  BENCHMARKS_DEFAULT_SORT_MODEL,
  DEFAULT_PAGINATION_MODEL,
  getPaginationRequest,
  getSortRequest,
  useBenchmarks,
  useDashboardId,
} from '@trustyou/shared';

import { BaseDashboard } from './BaseDashboard';
import { BenchmarksEmptyState } from './BenchmarksEmptyState';

import { useBenchmarkAddButton } from '../../hooks';

export const BenchmarksDashboard = () => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  useBenchmarkAddButton(iFrameRef);

  const { data: dashboardIdResponse } = useDashboardId();

  /* Requesting the benchmark first page to check the count. 
     If its empty and when user go to benchmark page by clicking add benchmarks, 
     user wont see a glitch as first page is already loaded*/
  const { data, isPending: isLoading } = useBenchmarks(
    getPaginationRequest(DEFAULT_PAGINATION_MODEL),
    getSortRequest(BENCHMARKS_DEFAULT_SORT_MODEL)
  );

  if (isLoading || !data?.data) return null;
  if (!data.data.length) return <BenchmarksEmptyState />;
  if (!dashboardIdResponse?.benchmark_dashboard_id) return null;
  return (
    <BaseDashboard
      iFrameRef={iFrameRef}
      dashboardId={dashboardIdResponse?.benchmark_dashboard_id}
    />
  );
};
