import type { DropResult } from 'react-beautiful-dnd';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';

import { type Filters, type View, reorder, useInboxStore } from '@trustyou/shared';
import { snackbar } from '@trustyou/ui';

import { useFetchViews, useSaveViews } from './api-query';
import { usePredefinedViews } from './use-predefined-views';

import { mapFetchedViewsToUsableViews } from '../utils/mappers';

export function useFilters() {
  const viewsQuery = useFetchViews();
  const saveViews = useSaveViews();
  const views = mapFetchedViewsToUsableViews(viewsQuery.data);

  const { allReviewsView, lastSevenDaysView } = usePredefinedViews();

  const currentView = useInboxStore((state) => state.currentView);
  const setCurrentView = useInboxStore((state) => state.setCurrentView);

  const applyDefaultFilters = () => {
    setCurrentView(lastSevenDaysView);
  };

  const clearFilters = () => {
    setCurrentView(allReviewsView);
  };

  const applyFilters: SubmitHandler<Filters> = (data) => {
    setCurrentView({ label: '', filters: data });
  };

  const saveView = (newView: View, onSuccessCallback?: () => void) => {
    saveViews.mutate(views.concat(newView), {
      onSuccess: () => {
        onSuccessCallback?.();
        setCurrentView(newView);
        snackbar.success('View saved successfully');
      },
    });
  };

  const reorderViews = (result: DropResult) => {
    if (!result.destination) return;
    const newOrder = reorder(views, result.source.index, result.destination.index);
    saveViews.mutate(newOrder);
  };

  const deleteView = (viewNameToDelete: string) => {
    const viewsWithoutTheOneToDelete = views.filter((view) => view.label !== viewNameToDelete);
    saveViews.mutate(viewsWithoutTheOneToDelete, {
      onSuccess: () => {
        applyDefaultFilters();
        snackbar.success('View deleted successfully');
      },
    });
  };

  const debugInvalidForm: SubmitErrorHandler<Filters> = (event) => {
    console.error('onInvalid inbox filters', event);
  };

  return {
    views,
    currentView,
    setCurrentView,
    applyDefaultFilters,
    clearFilters,
    applyFilters,
    saveView,
    reorderViews,
    deleteView,
    debugInvalidForm,
  };
}
