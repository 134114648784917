import { CategoriesFilter } from './categories';

import { useFetchSubcategoryMapping, useFetchTopLevelCategoryMapping } from '../../../hooks';
import { SubcategoriesFilter } from './subcategories';

export function CategoriesFilterSection() {
  const categoryMappingQuery = useFetchTopLevelCategoryMapping();
  const subcategoryMappingQuery = useFetchSubcategoryMapping();

  return (
    <>
      <CategoriesFilter isLoading={categoryMappingQuery.isPending} />
      <SubcategoriesFilter isLoading={subcategoryMappingQuery.isPending} />
    </>
  );
}
