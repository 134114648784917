import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { stringToShortDate } from '@trustyou/shared';
import { Chip, CustomCell } from '@trustyou/ui';

import type { Questionnaire_Output } from '../../../../client';
import { messages } from '../../../../utils/messages';

export function useColumns() {
  const intl = useIntl();

  const columns: GridColDef<Questionnaire_Output>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'version',
      headerName: intl.formatMessage(messages.questionnaire),
      flex: 1,
      valueGetter: ({ row }) => row.internal_name,
      renderCell: ({ value }) => <CustomCell text={value} variant="emphasized" />,
    },
    {
      field: 'entityCount',
      headerName: intl.formatMessage(messages.entities),
      flex: 1,
      valueGetter: ({ row }) => row.metadata.scopes?.length ?? 0,
    },
    {
      field: 'entitiesAssigned',
      headerName: intl.formatMessage(messages.entitiesAssigned),
      flex: 1,
      valueGetter: ({ row }) =>
        row.metadata.scopes && row.metadata.scopes?.length > 0
          ? intl.formatMessage(messages.assigned)
          : intl.formatMessage(messages.notAssigned),
      renderCell: ({ value }) => (
        <Chip
          label={value}
          size="small"
          variant={
            value === intl.formatMessage(messages.assigned) ? 'pastelSuccess' : 'pastelWarning'
          }
        />
      ),
    },
    {
      field: 'created',
      headerName: intl.formatMessage(messages.created),
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.created_at ?? ''),
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
    {
      field: 'modified',
      headerName: intl.formatMessage(messages.lastModified),
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.updated_at ?? ''),
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
  ];

  return columns;
}
