import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { SURVEY_CREATE_PERMISSION, usePermissionStore } from '@trustyou/shared';
import { CustomCell } from '@trustyou/ui';

import { DistributionDrawer } from './distribution-drawer';
import { PreviewButton } from './preview-button';

import type { SurveyByEntity } from '../../../../types/survey';
import { messages } from '../../../../utils/messages';

export function useColumns() {
  const intl = useIntl();
  const { hasPermissions } = usePermissionStore();
  // TODO: remove permissions check when preview & distribution are available for all users
  const hasSurveyCreatePermission = hasPermissions(SURVEY_CREATE_PERMISSION);

  const columns: GridColDef<SurveyByEntity>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'entity',
      headerName: intl.formatMessage(messages.entity),
      flex: 1,
      renderCell: ({ value }) => <CustomCell text={value} variant="emphasized" />,
    },
    {
      field: 'version',
      headerName: intl.formatMessage(messages.questionnaire),
      flex: 1,
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
    ...(hasSurveyCreatePermission
      ? [
          {
            field: 'distribution',
            headerName: '',
            flex: 1,
            // @ts-expect-error Binding element 'row' implicitly has an 'any' type.
            renderCell: ({ row }) => <DistributionDrawer survey={row} />,
          },
          {
            field: 'preview',
            headerName: '',
            flex: 1,
            // @ts-expect-error Binding element 'row' implicitly has an 'any' type.
            renderCell: ({ row }) => <PreviewButton survey={row} />,
          },
        ]
      : []),
  ];

  return columns;
}
