import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DEFAULT_SORT_KEY,
  getNumberName,
  useInfiniteSegments,
  useMembershipStore,
  useUpdateDashboardSegments,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectionCard,
  Stack,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { ConfirmationDialog } from './ConfirmationDialog';

import { messages } from '../../../../../constants/messages/segments';
import { useDashboardDelayMessage } from '../../../../../hooks';
import styles from './styles';

type EditDashboardSegmentsProps = {
  onClose: () => void;
};

const MAX_DASHBOARD_SEGMENTS = 15;

const EditDashboardSegments = ({ onClose }: EditDashboardSegmentsProps) => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] = useState(false);
  const [initialSelectedSegments, setInitialSelectedSegments] = useState<string>('');
  const { data } = useInfiniteSegments(100, DEFAULT_SORT_KEY);
  const segments = useMemo(() => data?.pages.map((page) => page.data).flat() || [], [data]);
  let total = (data?.pages.length && data?.pages[0].pagination.total) || 0;
  total = total > MAX_DASHBOARD_SEGMENTS ? MAX_DASHBOARD_SEGMENTS : total;
  const totalStr = getNumberName(total);
  const { membership } = useMembershipStore();

  const updateDashboardSegments = useUpdateDashboardSegments();

  useEffect(() => {
    const selected = segments
      .filter((segment) => segment.is_global || segment.selected_for_dashboard)
      .map((segment) => segment.id);
    setSelectedSegments(selected);
    setInitialSelectedSegments(selected.sort().join('_'));
  }, [segments]);

  const isChanged = useMemo(
    () => initialSelectedSegments !== selectedSegments.sort().join('_'),
    [initialSelectedSegments, selectedSegments]
  );

  const toggleSelection = (segmentId: string) => {
    setSelectedSegments(
      selectedSegments.includes(segmentId)
        ? selectedSegments.filter((id) => id !== segmentId)
        : [...selectedSegments, segmentId]
    );
  };

  const handleSave = () => {
    updateDashboardSegments.mutate(
      {
        organization_id: membership?.organizationId as string,
        segment_ids: selectedSegments,
      },
      {
        onSuccess: () => {
          onClose();
          snackbar.success(
            intl.formatMessage(messages.changesSaved, { delay: dashboardDelayMessage })
          );
        },
      }
    );
  };

  return (
    <>
      <Dialog open onClose={onClose} sx={styles.dialog}>
        <DialogTitle>
          <FormattedMessage
            id="organization.segments.editDashboardSegments"
            defaultMessage="Edit dashboard segments"
          />
          <Stack spacing={1} sx={styles.dialogSubTitle}>
            <Typography variant="body2">
              {totalStr && (
                <FormattedMessage
                  id="organization.segments.dashboardSegmentsSelectionMessage"
                  defaultMessage="Select up to {count} segments to display on the dashboard."
                  values={{ count: intl.formatMessage(totalStr) }}
                />
              )}
            </Typography>
            <Typography variant="subtitle2" textAlign="right" color="text.secondary">
              <FormattedMessage
                id="organization.segments.dashboardSegmentsSelectionCountMessage"
                defaultMessage="{count} of {total} selected"
                values={{ count: selectedSegments.length, total }}
              />
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Stack spacing={1}>
            {segments.map((row) => (
              <SelectionCard
                sx={styles.selectionCard}
                key={row.id}
                title={row.name}
                id={row.id}
                selected={selectedSegments.includes(row.id)}
                disabled={
                  row.is_global ||
                  (!selectedSegments.includes(row.id) &&
                    selectedSegments.length === MAX_DASHBOARD_SEGMENTS)
                }
                onClick={() => {
                  toggleSelection(row.id);
                }}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-save-dashboard-segments" onClick={onClose} color="primary">
            <FormattedMessage id="organization.segments.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            data-testid="save-dashboard-segments"
            variant="contained"
            onClick={() => {
              setIsUpdateConfirmationDialogOpen(true);
            }}
            disabled={!isChanged}
          >
            <FormattedMessage
              id="organization.segments.saveChanges"
              defaultMessage="Save changes"
            />
          </Button>
        </DialogActions>
      </Dialog>
      <BackdropSpinner isLoading={updateDashboardSegments.isPending} />
      {isUpdateConfirmationDialogOpen && (
        <ConfirmationDialog
          onCancel={() => {
            setIsUpdateConfirmationDialogOpen(false);
          }}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditDashboardSegments;
