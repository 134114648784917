import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPane: { height: '100%' },
  contentPaneBody: {
    height: 'calc(100% - 72px)',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    maxWidth: '1000px',
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: '480px',
  },
  footer: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '1000px',
      },
    },
  },
  changelingMode: {
    pt: 8,
  },
  checkboxControllerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  checkboxFormControlLabel: { marginX: 0, gap: 1 },
};

export default styles;
