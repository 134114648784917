import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { openInNewTab, useZendeskLinks } from '@trustyou/shared';
import { Box, Button, Stack, Typography } from '@trustyou/ui';

import { useSemanticCategoryItems } from './use-semantic-category-items';

import type { SemanticCategory } from '../../../types';

export function SemanticAnalysis() {
  const intl = useIntl();
  const { getReportSentimentIssueLink } = useZendeskLinks();
  const { getSemanticCategoryItems } = useSemanticCategoryItems();

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="subtitle2">
          {intl.formatMessage({
            id: 'inbox.review.semantic-analysis',
            defaultMessage: 'Semantic analysis',
          })}
        </Typography>
        <Button
          endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          onClick={() => openInNewTab(getReportSentimentIssueLink())}
        >
          {intl.formatMessage({
            id: 'inbox.review.semantic-analysis.suggest-improvement',
            defaultMessage: 'Suggest improvement',
          })}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <SemanticGroup category="positive" items={getSemanticCategoryItems('p')} />
        <SemanticGroup category="neutral" items={getSemanticCategoryItems('o')} />
        <SemanticGroup category="negative" items={getSemanticCategoryItems('n')} />
      </Box>
    </Stack>
  );
}

const SEPARATOR = '/';

const STYLE_MAP = {
  positive: {
    icon: faFaceSmile,
    color: 'success.main',
  },
  neutral: {
    icon: faFaceMeh,
    color: 'warning.main',
  },
  negative: {
    icon: faFaceFrown,
    color: 'error.main',
  },
};

export function SemanticGroup({
  category,
  items,
}: {
  category: SemanticCategory;
  items: string[];
}) {
  const icon = STYLE_MAP[category].icon;
  const color = STYLE_MAP[category].color;

  if (items.length === 0) {
    return null;
  }

  return (
    <Typography component="span" color={color} data-testid={`${category}-sema-category`}>
      <FontAwesomeIcon icon={icon} style={{ marginInlineEnd: '10px' }} />
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        return (
          <span key={index} style={{ fontSize: '14px' }}>
            <span
              style={{
                marginInline: '8px',
                marginInlineStart: isFirst ? 0 : '8px',
                marginInlineEnd: isLast ? '10px' : '8px',
              }}
            >
              {item}
            </span>
            {!isLast && <span>{SEPARATOR}</span>}
          </span>
        );
      })}
    </Typography>
  );
}
