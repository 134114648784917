import { useState } from 'react';

import type { RadioGroupProps } from '@mui/material';
import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, Radio } from '@trustyou/ui';

import type { QuestionProps } from './question';
import { useResetState } from './use-reset-state';

import type { Option } from '../types';

type SurveyRadioGroupProps = QuestionProps & {
  options: Option[];
};

export function RadioGroup({ field, options }: SurveyRadioGroupProps) {
  const [value, setValue] = useState<Option['value']>(field?.value?.[0] ?? null);

  useResetState({ field, onReset: () => setValue('') });

  const handleChange: RadioGroupProps['onChange'] = (event, value) => {
    field?.onChange([value]);
    setValue(value);
  };

  return (
    <FormControl>
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id ?? option.value.toString()}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
