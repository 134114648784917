import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';

import { Thumbnail } from './thumbnail';

import type { ImageRow } from '../../../types/content';
import { messages } from '../../../utils/messages';

export function useColumns() {
  const intl = useIntl();

  const columns: GridColDef<ImageRow>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'preview',
      headerName: intl.formatMessage(messages.preview),
      flex: 3,
      renderCell: ({ row }) => <Thumbnail row={row} />,
    },
    {
      field: 'fileName',
      headerName: intl.formatMessage(messages.fileName),
      flex: 3,
    },
    // TODO: The `category` column isn't provided by the backend yet.
    // {
    //   field: 'category',
    //   headerName: intl.formatMessage({
    //     id: 'survey.common.category',
    //     defaultMessage: 'Category',
    //   }),
    //   flex: 1,
    //   // valueGetter: ({ row }) => 'draft',
    //   renderCell: ({ value }) => (
    //     <Chip
    //       label={value.toUpperCase()}
    //       size="small"
    //       sx={{
    //         backgroundColor: blueGrey[50],
    //         color: blueGrey[600],
    //         '& > span': {
    //           fontWeight: 600,
    //         },
    //       }}
    //     />
    //   ),
    // },
    {
      field: 'imageType',
      headerName: intl.formatMessage(messages.type),
      flex: 1,
      valueGetter: ({ row }) =>
        row.imageType === 'banner'
          ? intl.formatMessage(messages.banner)
          : intl.formatMessage(messages.logo),
    },
    {
      field: 'size',
      headerName: intl.formatMessage(messages.size),
      flex: 1,
    },
  ];

  return columns;
}
