import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_MODEL,
  FETCH_SEGMENTS,
  type Segment,
  getPaginationRequest,
  getSessionState,
  getSortRequest,
  setSessionState,
  useDeleteSegment,
  useSegments,
} from '@trustyou/shared';
import { DataGrid, EmptySegmentState, snackbar } from '@trustyou/ui';

import DeleteSegments from './DeleteSegments';
import EditSegments from './EditSegments';
import Groups from './Groups';
import GroupEntities from './Groups/GroupEntities';
import { UpdateGroups } from './Groups/UpdateGroups';
import SegmentNavBar from './SegmentNavBar';
import useSegmentsTableColumns from './useSegmentsTableColumns';

import { useDashboardDelayMessage } from '../../../../hooks';
import { useSegmentStore } from '../../../../store';
import styles from './styles';

const PAGINATION_STATE_KEY = 'SEGMENTS_PAGINATION';
const SORT_STATE_KEY = 'SEGMENTS_SORT';

export const Segments = () => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();

  const queryClient = useQueryClient();
  const {
    selectedSegment,
    setSelectedSegment,
    selectedGroup,
    setSelectedGroup,
    editDashboardSegmentsModelOpen,
    setEditDashboardSegmentsModelOpen,
  } = useSegmentStore();

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    getSessionState(PAGINATION_STATE_KEY) || DEFAULT_PAGINATION_MODEL
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getSessionState(SORT_STATE_KEY) || DEFAULT_SORT_MODEL
  );
  const { data, isFetching } = useSegments(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel)
  );
  const [deletableSegment, setDeletableSegment] = useState<Segment>();
  const [openEditPopup, setOpenEditPopup] = useState(false);

  const toggleEditPopup = () => {
    setOpenEditPopup(!openEditPopup);
  };
  const handleRowClick = (params: GridRowParams) => {
    setSelectedSegment(params.row);
  };

  const handleGoBack = () => {
    if (selectedGroup) {
      setSelectedGroup(undefined);
    } else {
      setSelectedSegment(undefined);
    }
  };

  const deleteSegment = useDeleteSegment();

  const handleDelete = () => {
    deleteSegment.mutate(deletableSegment?.id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(
            {
              id: 'organization.segments.deleted',
              defaultMessage: '{segment} deleted. {delay}',
            },
            { segment: deletableSegment?.name, delay: dashboardDelayMessage }
          )
        );
      },

      onSettled: () => setDeletableSegment(undefined),
    });
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENTS] });
    setPaginationModel(model);
    setSessionState(PAGINATION_STATE_KEY, model);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (!model.length) return;
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENTS] });
    setSortModel(model);
    setSessionState(SORT_STATE_KEY, model);
    setPaginationModel((state) => {
      const newState = { ...state, page: DEFAULT_PAGINATION_MODEL.page };
      setSessionState(PAGINATION_STATE_KEY, newState);
      return newState;
    });
  };

  const columns = useSegmentsTableColumns({
    onEditDashboardSegments: () =>
      setEditDashboardSegmentsModelOpen(!editDashboardSegmentsModelOpen),
    onDelete: setDeletableSegment,
  });

  if (selectedSegment) {
    return (
      <>
        <SegmentNavBar
          segment={selectedSegment}
          group={selectedGroup}
          onGoBack={handleGoBack}
          onEdit={toggleEditPopup}
        />
        {selectedGroup ? (
          <>
            <GroupEntities group={selectedGroup} />
            {openEditPopup && (
              <UpdateGroups
                segment={selectedSegment}
                group={selectedGroup}
                onGroupEdit={setSelectedGroup}
                onClose={toggleEditPopup}
              />
            )}
          </>
        ) : (
          <>
            <Groups segment={selectedSegment} onGroupSelect={setSelectedGroup} />
            {openEditPopup && (
              <EditSegments
                segment={selectedSegment}
                onSegmentEdit={setSelectedSegment}
                onClose={toggleEditPopup}
              />
            )}
          </>
        )}
      </>
    );
  }

  if (!isFetching && data?.pagination.total === 0) return <EmptySegmentState />;

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        rowCount={data?.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={columns}
        rows={data?.data || []}
        onRowClick={handleRowClick}
        containerStyle={styles.tableContainer}
        autoHeight={false}
        loading={isFetching}
      />
      <DeleteSegments
        onClose={() => {
          setDeletableSegment(undefined);
        }}
        onDelete={handleDelete}
        segment={deletableSegment}
      />
    </>
  );
};
