import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, Typography } from '@trustyou/ui';

export interface ReviewProviderIntroProps {
  showOnStart: boolean;
}

const ReviewProviderIntro = ({ showOnStart }: ReviewProviderIntroProps) => {
  const [showIntro, setShowIntro] = useState(showOnStart);

  if (!showIntro) return null;

  return (
    <Alert severity="info" onClose={() => setShowIntro(false)}>
      <Typography variant="body2">
        <FormattedMessage
          id="review-providers.intro-message"
          defaultMessage="Connect to the APIs of Google Business Profile and Booking.com to get accurate reviews faster and use direct responses in your inbox."
        />
      </Typography>
    </Alert>
  );
};

export default ReviewProviderIntro;
