import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { snackbar } from '@trustyou/ui';

import { ReviewDeletionDialog } from './review-deletion-dialog';
import { TrashIconButton } from './trash-icon-button';

import { useMarkAsDeleted, useReview } from '../../hooks';
import { useDialogState } from '../../hooks/use-dialog-state';

const deletionMessages = defineMessages({
  success: {
    id: 'inbox.review.delete.success',
    defaultMessage: 'Marked as deleted',
  },
});

export function ReviewDeletion() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { accessTokenPayload } = useOidcAccessToken();
  const markAsDeleted = useMarkAsDeleted();

  const { isDialogOpen, openDialog, closeDialog } = useDialogState();

  const handleConfirm = () => {
    markAsDeleted.mutate(
      {
        username: accessTokenPayload.name,
        review_id: reviewRoot?.review.id ?? '',
      },
      {
        onSuccess: () => {
          closeDialog();
          snackbar.success(intl.formatMessage(deletionMessages.success));
        },
      }
    );
  };

  return (
    <>
      <TrashIconButton onClick={openDialog} />
      <ReviewDeletionDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onCancel={closeDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
}
