import { useIntl } from 'react-intl';

import type { FiltersField } from '@trustyou/shared';

import { DropdownMultiple } from './dropdown-multiple';
import { useStatusGroups } from './use-status-groups';

import { useFilters } from '../../hooks/use-filters';

export type DropdownStatusProps = {
  fieldName: FiltersField;
};

export function DropdownStatus({ fieldName }: DropdownStatusProps) {
  const intl = useIntl();
  const statusGroup = useStatusGroups();
  const { currentView } = useFilters();

  return (
    <DropdownMultiple
      fieldName={fieldName}
      groups={statusGroup}
      label={intl.formatMessage({
        id: 'inbox.filter.status.label',
        defaultMessage: 'Status',
      })}
      initialValues={currentView.filters?.statuses ?? []}
      labelForAllOption={intl.formatMessage({
        id: 'inbox.filter.status.all',
        defaultMessage: 'All statuses',
      })}
    />
  );
}
