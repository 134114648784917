import { type SyntheticEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { FormControlProps } from '@mui/material';
import { type FiltersField, commonFields } from '@trustyou/shared';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  ListItem,
  ListItemText,
  TextField,
} from '@trustyou/ui';

import { FONT_SIZE, MIN_FIELD_HEIGHT } from './constants';
import { CustomPaperComponent } from './custom-paper-component';

import type { Option } from '../../types';

export type DropdownMultipleAutocompleteProps = FormControlProps & {
  fieldName: FiltersField;
  label: string;
  options: Option[];
  initialValues: string[];
};

export function DropdownMultipleAutocomplete({
  fieldName,
  label,
  options,
  initialValues,
  ...props
}: DropdownMultipleAutocompleteProps) {
  const intl = useIntl();
  const { control } = useFormContext();

  const getPlaceholder = (value: Option[] | string[] | null) => {
    if (!value || value.length === 0) {
      return intl.formatMessage(commonFields.all);
    }
    if (value.length === options.length) {
      return '';
    }
    return 'More...';
  };

  const getValue = (value: string[]) => {
    return options.filter((option) => Array.isArray(value) && value.includes(option.value));
  };

  const handleChange = (
    event: SyntheticEvent,
    value: Option[],
    onChange: (...event: unknown[]) => void
  ) => {
    setWatchedValue(value.map((option) => option.value));
    setPlaceholder(getPlaceholder(value));
    onChange(value.map((option) => option.value));
  };

  const [watchedValue, setWatchedValue] = useState(initialValues);
  const [placeholder, setPlaceholder] = useState(getPlaceholder(watchedValue));

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange } }) => (
        <FormControl {...props}>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            fullWidth
            disableCloseOnSelect
            openOnFocus
            autoHighlight
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={getValue(value)}
            onChange={(event, value) => handleChange(event, value, onChange)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps, sx: { minHeight: MIN_FIELD_HEIGHT } }}
                inputProps={{ ...params.inputProps, sx: { fontSize: FONT_SIZE } }}
              />
            )}
            renderTags={(tagValue) =>
              tagValue.map((option) => (
                <Box
                  key={option.value}
                  component="span"
                  sx={{
                    fontSize: FONT_SIZE,
                    marginInlineStart: 0.75,
                  }}
                >
                  {option.label},
                </Box>
              ))
            }
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} key={option.value} dense>
                <Checkbox
                  edge="start"
                  size="small"
                  checked={selected}
                  disableRipple
                  inputProps={{ 'aria-labelledby': option.value }}
                  sx={{
                    paddingBlock: 0,
                    paddingInline: 1,
                  }}
                />
                <ListItemText id={option.value} primary={option.label} />
              </ListItem>
            )}
            PaperComponent={CustomPaperComponent}
            sx={{
              '& .MuiAutocomplete-tag': {
                fontSize: FONT_SIZE,
                marginInline: 0.5,
              },
            }}
          />
        </FormControl>
      )}
    />
  );
}
