import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  type CombinedUserAndInvitation,
  FETCH_SUBSCRIPTION_MEMBERS,
  SEARCH_DEBOUNCE_TIME_MS,
  USER_STATUSES,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useChangelingStore,
  useDeleteInvitation,
  useDeleteUser,
  useGridParams,
  useResendInvitation,
  useSubscriptionMembers,
  useSubscriptionMembersGridStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  EmptyMemberState,
  SearchBar,
  SecondaryDrawer,
  UserDetailsView,
  snackbar,
} from '@trustyou/ui';

import { useCurrentUser, useManageUsers } from '../../../hooks';
import InvitationDeleteModal from '../../Organization/Users/components/DeleteModal/InvitationDeleteModal';
import UserDeleteModal from '../../Organization/Users/components/DeleteModal/UserDeleteModal';
import UsersTable from '../../Organization/Users/components/UsersTable/UsersTable';
import { styles } from './styles';

const messages = defineMessages({
  userRemovedAlert: {
    id: 'organization.users.userRemovedAlert',
    defaultMessage: '{user} removed',
  },
  invitationResendAlert: {
    id: 'organization.users.invitationResendAlert',
    defaultMessage: 'Invitation to {email} is resent',
  },
  invitationDeleteAlert: {
    id: 'organization.users.invitationDeleteAlert',
    defaultMessage: 'Invitation for {email} was canceled',
  },
});

export interface SubscriptionMembersProps {
  onEdit?: (user: CombinedUserAndInvitation) => void;
}

export const SubscriptionMembers = ({ onEdit }: SubscriptionMembersProps) => {
  const intl = useIntl();
  const [chosenUser, setChosenUser] = useState<CombinedUserAndInvitation>();
  const [deletingUser, setDeletingUser] = useState<CombinedUserAndInvitation>();

  const { isChangeling } = useChangelingStore();
  const { chosenSubscriptionId } = useParams();

  const isManageUserAllowed = useManageUsers();

  const gridState = useSubscriptionMembersGridStore();
  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_SUBSCRIPTION_MEMBERS,
    gridState
  );

  const currentUser = useCurrentUser();

  const { data, isFetching } = useSubscriptionMembers(
    chosenSubscriptionId as string,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const onCloseDrawer = () => {
    setChosenUser(undefined);
  };

  const onCloseDelete = () => {
    setDeletingUser(undefined);
  };

  const deleteUser = useDeleteUser({ shouldRefetchSubscriptionMembers: true });
  const resendInvitation = useResendInvitation();
  const deleteInvitation = useDeleteInvitation({ shouldRefetchSubscriptionMembers: true });
  const isLoading =
    deleteUser.isPending || deleteInvitation.isPending || resendInvitation.isPending;

  const handleResendInvitation = (resendUser: CombinedUserAndInvitation) => {
    resendInvitation.mutate(
      {
        email: resendUser.email,
        invitation_id: resendUser.membership_id,
      },
      {
        onSuccess: (data) => {
          snackbar.success(intl.formatMessage(messages.invitationResendAlert, { email: data }));
        },
      }
    );
  };

  const handleDeleteUser = () => {
    deleteUser.mutate(deletingUser?.membership_id as string, { onSettled: onCloseDelete });
  };

  const handleDeleteInvitation = () => {
    deleteInvitation.mutate(deletingUser?.membership_id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(messages.invitationDeleteAlert, { email: deletingUser?.email })
        );
      },
      onSettled: onCloseDelete,
    });
  };

  const handleDelete = (userToDelete: CombinedUserAndInvitation) => {
    setDeletingUser(userToDelete);
  };

  if (data?.pagination?.total === 0 && !isFetching && !searchKey) {
    return <EmptyMemberState />;
  }

  return (
    <>
      <SearchBar
        onSearch={onSearch}
        debounceTime={SEARCH_DEBOUNCE_TIME_MS}
        id="subscription-members"
        variant="standard"
        placeholder={intl.formatMessage(searchPlaceholders.searchUsers)}
        defaultValue={searchKey}
        sx={styles.searchBar}
      />
      <UsersTable
        data={data}
        isLoading={isFetching}
        handleEdit={onEdit}
        handleDelete={handleDelete}
        handleResendInvitation={handleResendInvitation}
        handleUserDetails={setChosenUser}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        enabledColumns={['name', 'role', 'scope', 'status', 'action']}
        containerStyle={styles.tableContainerStyle}
      />
      <SecondaryDrawer
        header={null}
        open={!!chosenUser}
        onClose={onCloseDrawer}
        width="400px"
        changelingMode={isChangeling}
      >
        {chosenUser && (
          <UserDetailsView
            user={chosenUser}
            isManageUserAllowed={isManageUserAllowed}
            currentUser={currentUser}
            handleEditUser={onEdit}
            handleDeleteUser={handleDelete}
          />
        )}
      </SecondaryDrawer>
      <UserDeleteModal
        onClose={onCloseDelete}
        onDelete={handleDeleteUser}
        user={deletingUser?.status === USER_STATUSES.ACTIVE ? deletingUser : undefined}
      />
      <InvitationDeleteModal
        onClose={onCloseDelete}
        onDelete={handleDeleteInvitation}
        invitation={deletingUser?.status === USER_STATUSES.INVITED ? deletingUser : undefined}
      />
      <BackdropSpinner isLoading={isLoading} />
    </>
  );
};
