import type { IStyles } from '@trustyou/shared';
import { CONTENT_MAX_WIDTH } from '@trustyou/ui';

export const styles: IStyles = {
  contentPane: { flex: 1 },
  contentPaneBody: {
    paddingTop: '0 !important',
    height: 'calc(100% - 84px)',
  },
  gridContent: {
    maxWidth: CONTENT_MAX_WIDTH,
    marginBlockStart: 3,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(256px, 1fr))',
    gap: 2,
    overflow: 'auto',
  },
};
