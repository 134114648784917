import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { SxProps, Theme } from '@mui/system';
import {
  faCircleExclamation,
  faFingerprint,
  faTrash,
  faUser,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type BookingConnectionRequestCredentials,
  CONNECT_PROVIDER_NAMES,
  type GoogleAccountCredentials,
  type ProviderAccount,
  defineProvider,
  getDisplayedProviderName,
} from '@trustyou/shared';
import {
  Avatar,
  Box,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  StyledFontAwesomeIcon,
  Tooltip,
  Typography,
} from '@trustyou/ui';

import styles from './styles';

type AccountViewProps = {
  account: ProviderAccount;
  sx?: SxProps<Theme>;
  onDelete: (id: string) => void;
};

export function AccountView({ account, sx, onDelete }: AccountViewProps) {
  const provider: CONNECT_PROVIDER_NAMES = defineProvider(account);

  const displayedProviderName = getDisplayedProviderName(provider);

  const isGoogleAccount = provider === CONNECT_PROVIDER_NAMES.GOOGLE;
  const isBookingAccount = provider === CONNECT_PROVIDER_NAMES.BOOKING;

  const getProviderAvatarIcon = () => {
    if (isGoogleAccount) return faUser;
    return faFingerprint;
  };

  const avatarIcon = getProviderAvatarIcon();

  const renderProviderAccountInfo = () => {
    if (isGoogleAccount)
      return (
        <>
          <Typography variant="body2">
            {(account.credential as GoogleAccountCredentials).name}
          </Typography>
          <Typography variant="caption">
            {(account.credential as GoogleAccountCredentials).email}
          </Typography>
        </>
      );
    if (isBookingAccount) {
      const credential: BookingConnectionRequestCredentials =
        account.credential as BookingConnectionRequestCredentials;
      if (credential.legal_entity_id)
        return (
          <>
            <Typography variant="body2">{credential.legal_entity_id}</Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="review-providers.provider-account.legal-entity-id"
                defaultMessage="Legal Entity ID"
              />
            </Typography>
          </>
        );
      if (credential.contact_email)
        return (
          <>
            <Typography variant="body2">{credential.contact_email}</Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="review-providers.provider-account.contact-email"
                defaultMessage="Contact Email"
              />
            </Typography>
          </>
        );
    }
    throw new Error('Provider cannot be determined or is not supported.');
  };

  return (
    <Box sx={{ ...styles.providerAccountWrapper, ...sx }}>
      <Avatar sx={styles.providerAccountAvatar}>
        <Icon icon={avatarIcon} />
      </Avatar>
      <Box sx={styles.providerAccountInfo}>{renderProviderAccountInfo()}</Box>
      <Stack flexDirection="row" sx={styles.iconWrapper}>
        {account.status === 'not_working' ? (
          <Tooltip
            placement="top"
            arrow
            title={
              <FormattedMessage
                id="review-providers.provider-account.error"
                defaultMessage="The connection does not work. Please remove and add again."
              />
            }
          >
            <StyledFontAwesomeIcon
              fontSize="20px"
              sx={{ color: 'error.main' }}
              icon={faCircleExclamation}
            />
          </Tooltip>
        ) : null}
        <IconButton
          data-testid="delete-account-button"
          data-gtm-id={`${displayedProviderName}-delete-account-button`}
          size="medium"
          onClick={() => onDelete(account.provider_account_id)}
        >
          <Icon icon={faTrash} />
        </IconButton>
      </Stack>
    </Box>
  );
}
