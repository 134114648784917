import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type { ViewSchema_Input, ViewSchema_Output } from '../../../client';

export async function fetchViews() {
  const { data } = await $assApi.get<ViewSchema_Output[]>(`${INBOX_BASE_PATH}/views/get-views`);
  return data;
}

export async function saveViews(newViews: ViewSchema_Input[]) {
  const { data } = await $assApi.post<ViewSchema_Output[]>(
    `${INBOX_BASE_PATH}/views/set-views`,
    newViews
  );
  return data;
}
