import { FormattedMessage } from 'react-intl';

import { Skeleton, Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

import { useReviewsInfo } from '../hooks';

export function LastUpdateTimestamp() {
  const { lastProducedAt } = useReviewsInfo();

  return (
    <Typography variant="caption" color="text.secondary">
      {!lastProducedAt ? (
        <Skeleton width={230} />
      ) : (
        <FormattedMessage
          id="inbox.reviews-last-update"
          defaultMessage="Last update: {lastUpdate} UTC"
          values={{
            lastUpdate: dayjs(lastProducedAt).format('DD.MM.YYYY, HH:mm'),
          }}
        />
      )}
    </Typography>
  );
}
