import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  CONTENT_LIBRARY_LIST_PERMISSION,
  QUESTION_CREATE_PERMISSION,
  QUESTION_LIST_PERMISSION,
  SURVEY_CREATE_PERMISSION,
  SURVEY_LIST_PERMISSION,
  THEME_CREATE_PERMISSION,
  THEME_LIST_PERMISSION,
  isTemporaryHidden,
  usePermissionStore,
} from '@trustyou/shared';
import { Box, Stack, Tab, Tabs } from '@trustyou/ui';

import type { SurveyManagerProps } from '..';
import { NewQuestionButton } from '../../components/question-library/new-question-button';
import { NewSurveyButton } from '../../components/survey/new-survey-button';
import { NewThemeButton } from '../../components/theme/new-theme-button';
import { ContentLibraryImages } from '../content-library/images/content-library-images';
import { ContentLibraryList } from '../content-library/list/content-library-list';
import { ContentLibraryTexts } from '../content-library/texts/content-library-texts';
import { QuestionLibraryEditor } from '../question-library/editor/question-library-editor';
import { QuestionLibraryList } from '../question-library/list/question-library-list';
import { SurveyDetail } from '../survey/detail/survey-detail';
import { SurveyEditor } from '../survey/editor/survey-editor';
import { SurveyList } from '../survey/list/survey-list';
import { SurveyQuestionnaireEditor } from '../survey/questionnaire-editor/survey-questionnaire-editor';
import { ThemeDetail } from '../theme/detail/theme-detail';
import { ThemeEditor } from '../theme/editor/theme-editor';
import { ThemeList } from '../theme/list/theme-list';

const tabPaths: Record<number, string> = {
  0: 'survey/overview',
  1: 'question/overview',
  2: 'content-library/overview',
  3: 'theme/overview',
};

export interface TabPanelProps {
  index: number;
  value: number;
  children?: ReactNode;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { index, value, children, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`survey-manager-tabpanel-${index}`}
      aria-labelledby={`survey-manager-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `survey-manager-tab-${index}`,
    'aria-controls': `survey-manager-tabpanel-${index}`,
  };
}

export const SurveyManagerTabs = ({ isChangeling }: SurveyManagerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const isHidden = isTemporaryHidden('CXP-customers-survey-management');

  const { hasPermissions } = usePermissionStore();
  const hasSurveyListPermission = hasPermissions(SURVEY_LIST_PERMISSION);
  const hasQuestionListPermission = hasPermissions(QUESTION_LIST_PERMISSION);
  const hasContentListPermission = hasPermissions(CONTENT_LIBRARY_LIST_PERMISSION);
  const hasThemeListPermission = hasPermissions(THEME_LIST_PERMISSION);

  const hasSurveyCreatePermission = hasPermissions(SURVEY_CREATE_PERMISSION);
  const hasQuestionCreatePermission = hasPermissions(QUESTION_CREATE_PERMISSION);
  const hasThemeCreatePermission = hasPermissions(THEME_CREATE_PERMISSION);

  const isNavigationPathSurvey = pathSegments.includes('survey');
  const isNavigationPathQuestionLibrary = pathSegments.includes('question');
  const isNavigationPathContentLibrary = pathSegments.includes('content-library');
  const isNavigationPathTheme = pathSegments.includes('theme');
  const defaultValue = isNavigationPathTheme
    ? 3
    : isNavigationPathContentLibrary
      ? 2
      : isNavigationPathQuestionLibrary
        ? 1
        : 0;
  const [value, setValue] = useState(defaultValue);

  const isSurveyDetail = isNavigationPathSurvey && pathSegments.includes('detail');
  const isSurveyQuestionnaireEditor =
    isNavigationPathSurvey && pathSegments.includes('questionnaire-editor');
  const isSurveyEditor = isNavigationPathSurvey && pathSegments.includes('editor');
  const isSurveyList = !isSurveyDetail && !isSurveyQuestionnaireEditor;

  const isQuestionLibraryEditor =
    isNavigationPathQuestionLibrary && pathSegments.includes('editor');
  const isQuestionLibraryList = !isQuestionLibraryEditor;

  const isContentLibraryTexts = isNavigationPathContentLibrary && pathSegments.includes('texts');
  const isContentLibraryImages = isNavigationPathContentLibrary && pathSegments.includes('images');
  const isContentLibraryList =
    isNavigationPathContentLibrary && !isContentLibraryTexts && !isContentLibraryImages;

  const isThemeDetail = isNavigationPathTheme && pathSegments.includes('detail');
  const isThemeEditor = isNavigationPathTheme && pathSegments.includes('editor');
  const isThemeList = !isThemeDetail && isNavigationPathTheme;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(tabPaths[newValue]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Tabs value={value} onChange={handleChange} aria-label="survey manager tabs">
          {hasSurveyListPermission && (
            <Tab
              label={<FormattedMessage id="survey.tab.surveys" defaultMessage="Surveys" />}
              {...a11yProps(0)}
            />
          )}
          {hasQuestionListPermission && !isHidden && (
            <Tab
              label={
                <FormattedMessage
                  id="survey.tab.question-library"
                  defaultMessage="Question library"
                />
              }
              {...a11yProps(1)}
            />
          )}
          {hasContentListPermission && !isHidden && (
            <Tab
              label={
                <FormattedMessage
                  id="survey.common.content-library"
                  defaultMessage="Content library"
                />
              }
              {...a11yProps(2)}
            />
          )}
          {hasThemeListPermission && !isHidden && (
            <Tab
              label={<FormattedMessage id="survey.tab.themes" defaultMessage="Themes" />}
              {...a11yProps(3)}
            />
          )}
        </Tabs>
        {defaultValue === 0 && !isSurveyDetail && hasSurveyCreatePermission && !isHidden && (
          <NewSurveyButton />
        )}
        {defaultValue === 1 && hasQuestionCreatePermission && <NewQuestionButton />}
        {defaultValue === 3 && !isThemeDetail && hasThemeCreatePermission && <NewThemeButton />}
      </Stack>
      <CustomTabPanel value={value} index={0}>
        {isSurveyList && <SurveyList />}
        {isSurveyDetail && <SurveyDetail />}
        {isSurveyEditor && <SurveyEditor />}
        {isSurveyQuestionnaireEditor && <SurveyQuestionnaireEditor />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isQuestionLibraryList && <QuestionLibraryList />}
        {isQuestionLibraryEditor && <QuestionLibraryEditor isChangeling={isChangeling} />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {isContentLibraryList && <ContentLibraryList />}
        {isContentLibraryTexts && <ContentLibraryTexts />}
        {isContentLibraryImages && <ContentLibraryImages />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {isThemeList && <ThemeList />}
        {isThemeDetail && <ThemeDetail />}
        {isThemeEditor && <ThemeEditor />}
      </CustomTabPanel>
    </Box>
  );
};
