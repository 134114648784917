import { useEffect, useState } from 'react';

import { useLanguageStore } from '@trustyou/shared';

import type { SemaLanguage } from '../../../../client';
import { useFetchSemaLanguages } from '../../../../hooks';
import type { GetResponseTemplatesResponse } from '../../../../types';

export function useTemplateLanguages({ templates }: { templates?: GetResponseTemplatesResponse }) {
  const { locale } = useLanguageStore();
  const { data: languages = {}, isPending } = useFetchSemaLanguages(locale as SemaLanguage);

  const [templateLanguages, setTemplateLanguages] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isPending || !templates) return;
    // get the list of languages that have templates for the language selection
    const allTemplates = {
      ...templates?.default_templates,
      ...templates?.user_templates,
    };

    const templateLanguages = Object.keys(languages).reduce(
      (acc, lang) => {
        const templateLang = lang.toUpperCase();
        if (allTemplates[templateLang]) {
          acc[lang] = languages[lang];
        }
        return acc;
      },
      {} as Record<string, string>
    );

    setTemplateLanguages(templateLanguages);
  }, [isPending, languages, templates]);

  return { templateLanguages };
}
