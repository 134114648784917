import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { FiltersField } from '@trustyou/shared';
import { Box, FormGroup, Stack } from '@trustyou/ui';

import { EnabledSwitch, ScoreSelect, type SelectProps } from './form-fields';
import { NestedFilters } from './nested-filters';

import type { EmailNotificationSettings_Input } from '../../../client';
import { MAX_FIELD_WIDTH } from '../../../constants';
import useListSurveysPermission from '../../../hooks/permissions/use-list-surveys-permission';
import { SurveyFilter } from '../../filters/surveys/survey-filter';

export function FeedbacksSection() {
  const intl = useIntl();
  const { control, watch } = useFormContext<EmailNotificationSettings_Input>();
  const { fields } = useFieldArray({ control, name: 'feedbacks' });

  const hasSurveyPermission = useListSurveysPermission();

  return (
    <FormGroup sx={{ gap: 2 }}>
      {fields.map((field, index) => (
        <Stack key={field.id}>
          <EnabledSwitch
            fieldName={`feedbacks.${index}.enabled`}
            label={intl.formatMessage({
              id: 'inbox.settings.notifications.realtime.label',
              defaultMessage: 'Receive email notifications for new survey reviews on arrival',
            })}
          />
          <NestedFilters isVisible={watch(`feedbacks.${index}.enabled`)}>
            <ScoreSelect fieldName={`feedbacks.${index}.filters.score`} />
            {hasSurveyPermission && (
              <SurveysSelect fieldName={`feedbacks.${index}.filters.survey.ids`} />
            )}
          </NestedFilters>
        </Stack>
      ))}
    </FormGroup>
  );
}

function SurveysSelect({ fieldName }: SelectProps) {
  return (
    <Box maxWidth={MAX_FIELD_WIDTH}>
      <SurveyFilter fieldName={fieldName as FiltersField} isNotificationButton />
    </Box>
  );
}
