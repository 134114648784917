import { useState } from 'react';

import { IconButton, OutlinedInput, type OutlinedInputProps } from '@mui/material';
import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { StyledFontAwesomeIcon } from '@trustyou/ui';

type StyledOutlinedInputProps = OutlinedInputProps & {
  label: string;
  isAllSelected: boolean;
  onClear: () => void;
};

export function StyledOutlinedInput({
  label,
  isAllSelected,
  onClear,
  ...props
}: StyledOutlinedInputProps) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <OutlinedInput
      {...props}
      label={label}
      endAdornment={
        isHovering &&
        !isAllSelected && (
          <IconButton title="Clear" size="small" sx={{ marginInlineEnd: 2 }} onClick={onClear}>
            <StyledFontAwesomeIcon icon={faXmark} sx={{ color: 'text.secondary' }} />
          </IconButton>
        )
      }
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    />
  );
}
