import type { AccessGroup } from './accessGroup';
import type { BasicEntity } from './entity';
import type { Organization } from './organization';

import type { Segment, SegmentGroup } from './segment';
import type { PaginationResponse, USER_ROLES, USER_STATUSES } from './shared';
import type { SubscriptionShort } from './subscription';

export interface CurrentUser {
  id: string;
  email: string;
  role: USER_ROLES;
  membershipId?: string;
}

export interface UserScopeGroup extends SegmentGroup {
  segment: Segment;
}

export interface UserScopeEntity {
  city: string;
  country_name: string;
  id: string;
  name: string;
}

export interface ManagedEntityListScope {
  rule: string;
  additional_entities: string[];
}

export interface UserScopeDetails {
  all: boolean;
  groups: UserScopeGroup[];
  entities: UserScopeEntity[];
  subscription_id?: string | null;
  mel?: ManagedEntityListScope | null;
  entities_count?: number;
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: USER_ROLES;
  status: string;
  organization_id: string;
  organization: Organization;
  scope: UserScopeDetails;
  created_at: string;
  updated_at: string;
}

export interface CombinedUserAndInvitation {
  id: string;
  organization_id: string;
  membership_id: string;
  subscription: SubscriptionShort;
  name: string;
  email: string;
  job_title: string;
  invited_by: string;
  status: USER_STATUSES;
  role: USER_ROLES;
  scope: UserScopeDetails | AccessGroup;
  scope_id: string;
  created_at: string;
  updated_at: string;
}

export interface InviteUser {
  email: string;
  name?: string;
  role: string;
  subscription_id?: string | null;
  silent?: boolean;
  user_scope?: {
    all: boolean;
    group_ids: string[];
    entity_ids: string[];
    mel: null | ManagedEntityListScope;
  };
}
export interface UpdateUser extends InviteUser {
  membership_id: string;
}

export interface AddFirstUserFormData {
  firstName?: string;
  lastName?: string;
  email: string;
  jobTitle?: string;
}

export interface InviteOwner extends InviteUser {
  job_title?: string;
  org_id: string;
}

export interface InviteServiceUser {
  email: string;
}

export enum UserViewTypes {
  CREATE = 'create',
  EDIT = 'edit',
  LIST = 'list',
  INVITE = 'invite',
  DETAIL = 'detail',
}

export enum GlobalUsersViewTypes {
  ORGANIZATION_LIST = 'organization_list',
  SERVICE_LIST = 'service_list',
  INVITE_SERVICE_USER = 'invite_service_user',
  ORGANIZATION_USER_DETAILS = 'organization_user_details',
}

export type UsersResponse = PaginationResponse<User>;

export type UserSettings = {
  settings: {
    language: string;
    signature: string;
  };
};

export type CombinedUserResponse = PaginationResponse<CombinedUserAndInvitation>;

export interface ImpersonateUser {
  scope_id: string;
  membership_id: string;
  role: USER_ROLES;
  organization: Organization;
  subscription: SubscriptionShort;
  name?: string;
  email?: string;
  scope?: UserScopeDetails;
}

export interface ChangelingUserInfo {
  name?: string;
  email?: string;
  isOwner: boolean;
}

export interface UpdateInvitation extends InviteUser {
  invitation_id: string;
}

export interface ResendInvitation {
  invitation_id: string;
  email: string;
}

export interface AdvancedSelectionScope {
  rules: AdvancedSelectionScopeRule[];
  additional_entities: BasicEntity[];
}

export interface AdvancedSelectionScopeRule {
  attribute?: string;
  values: string[];
}
