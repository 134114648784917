import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CREATE_BENCHMARK,
  DELETE_BENCHMARK,
  EDIT_BENCHMARK,
  FETCH_ATTRIBUTE_VALUES_FOR_BENCHMARK,
  FETCH_BENCHMARKS,
  FETCH_BENCHMARK_ADDITIONAL_ENTITIES,
  FETCH_BENCHMARK_COMPETITOR_ENTITIES,
  FETCH_BENCHMARK_OWNED_ENTITIES,
  FETCH_BENCHMARK_SETS,
  IS_VALID_BENCHMARK_NAME,
  type PaginationRequest,
  createOrganizationBenchmark,
  createUserBenchmark,
  deleteOrganizationBenchmark,
  deleteUserBenchmark,
  editOrganizationBenchmark,
  editUserBenchmark,
  fetchBenchmarkAdditionalEntitiesByIds,
  fetchBenchmarkCompetitorEntities,
  fetchBenchmarkOwnedEntities,
  fetchCompetitorsAttributeValues,
  fetchOrganizationAttributeValues,
  fetchOrganizationBenchmarkSets,
  fetchOrganizationBenchmarks,
  fetchUserBenchmarkSets,
  fetchUserBenchmarks,
  isValidBenchmarkName,
  useManageBenchmarksPermission,
  useViewBenchmarksPermission,
} from '@trustyou/shared';

export function useBenchmarks(pagination: PaginationRequest, sortKey: string) {
  const viewBenchmarksPermission = useViewBenchmarksPermission();
  return useQuery({
    queryKey: [FETCH_BENCHMARKS, { pagination, sortKey }],
    queryFn: () =>
      viewBenchmarksPermission === 'organization'
        ? fetchOrganizationBenchmarks(pagination, sortKey)
        : fetchUserBenchmarks(pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: !!viewBenchmarksPermission,
  });
}

export function useBenchmarkOwnedEntities(
  benchmarkId: string,
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_BENCHMARK_OWNED_ENTITIES, { pagination, sortKey }],
    queryFn: () => fetchBenchmarkOwnedEntities(benchmarkId, pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useBenchmarkCompetitorEntities(
  benchmarkId: string,
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_BENCHMARK_COMPETITOR_ENTITIES, { benchmarkId, pagination, sortKey }],
    queryFn: () => fetchBenchmarkCompetitorEntities(benchmarkId, pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useIsValidBenchmarkName() {
  return useMutation({
    mutationFn: isValidBenchmarkName,
    mutationKey: [IS_VALID_BENCHMARK_NAME],
  });
}

export function useBenchmarkSets() {
  const manageBenchmarksPermission = useManageBenchmarksPermission();
  return useQuery({
    queryKey: [FETCH_BENCHMARK_SETS],
    queryFn:
      manageBenchmarksPermission === 'organization'
        ? fetchOrganizationBenchmarkSets
        : fetchUserBenchmarkSets,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useCreateBenchmark() {
  const queryClient = useQueryClient();
  const manageBenchmarksPermission = useManageBenchmarksPermission();
  return useMutation({
    mutationFn:
      manageBenchmarksPermission === 'organization'
        ? createOrganizationBenchmark
        : createUserBenchmark,
    mutationKey: [CREATE_BENCHMARK],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_BENCHMARKS] });
    },
  });
}

export function useEditBenchmark() {
  const queryClient = useQueryClient();
  const manageBenchmarksPermission = useManageBenchmarksPermission();
  return useMutation({
    mutationFn:
      manageBenchmarksPermission === 'organization' ? editOrganizationBenchmark : editUserBenchmark,
    mutationKey: [EDIT_BENCHMARK],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_BENCHMARKS] });
    },
  });
}

export function useDeleteBenchmark() {
  const queryClient = useQueryClient();
  const manageBenchmarksPermission = useManageBenchmarksPermission();
  return useMutation({
    mutationFn:
      manageBenchmarksPermission === 'organization'
        ? deleteOrganizationBenchmark
        : deleteUserBenchmark,
    mutationKey: [DELETE_BENCHMARK],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_BENCHMARKS] });
    },
  });
}

export function useBenchmarkAdditionalEntitiesByIds(entityIds: string[]) {
  return useQuery({
    queryKey: [FETCH_BENCHMARK_ADDITIONAL_ENTITIES, { entityIds }],
    queryFn: () => fetchBenchmarkAdditionalEntitiesByIds(entityIds),
    refetchOnWindowFocus: false,
    enabled: entityIds.length > 0,
  });
}

export function useAttributeValuesForBenchmark(
  type: 'organization' | 'competitor',
  attribute?: string,
  searchValue?: string,
  enabled = true
) {
  return useQuery({
    queryKey: [FETCH_ATTRIBUTE_VALUES_FOR_BENCHMARK, { type, attribute, searchValue }],
    queryFn: () =>
      type === 'organization'
        ? fetchOrganizationAttributeValues(attribute, searchValue)
        : fetchCompetitorsAttributeValues(attribute, searchValue),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
  });
}
