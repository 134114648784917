import { useIntl } from 'react-intl';

import { ALL_REVIEWS_FILTERS, EXAMPLE_FILTERS, LAST_SEVEN_DAYS_FILTERS } from '@trustyou/shared';

export function usePredefinedViews() {
  const intl = useIntl();

  return {
    allReviewsView: {
      label: intl.formatMessage({
        id: 'inbox.view.all-reviews',
        defaultMessage: 'All reviews',
      }),
      filters: ALL_REVIEWS_FILTERS,
    },
    lastSevenDaysView: {
      label: intl.formatMessage({
        id: 'inbox.view.last-seven-days',
        defaultMessage: 'Last 7 days',
      }),
      filters: LAST_SEVEN_DAYS_FILTERS,
    },
    exampleView: {
      label: intl.formatMessage({
        id: 'inbox.view.example',
        defaultMessage: 'Example View',
      }),
      filters: EXAMPLE_FILTERS,
    },
  };
}
