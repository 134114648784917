import {
  faBell,
  faCalendarLinesPen,
  faCircleQuestion,
  faClipboardListCheck,
  faFileArrowDown,
  faFileInvoiceDollar,
  faGaugeMax,
  faGear,
  faGrid2Plus,
  faHouseBlank,
  faLayerGroup,
  faMessageDots,
  faPlug,
  faSitemap,
  faUserCircle,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Node, commonFields, isTemporaryHidden, navigationNodes } from '@trustyou/shared';

export const nodes = (parentPath: string): Node[] => [
  {
    id: navigationNodes.inbox.id,
    name: navigationNodes.inbox.defaultMessage,
    icon: faMessageDots,
    path: `${parentPath}/inbox`,
  },
  {
    id: navigationNodes.insights.id,
    name: navigationNodes.insights.defaultMessage,
    icon: faGaugeMax,
    isHiddenOnMobile: true,
    childNodes: [
      {
        id: navigationNodes.insightsReviews.id,
        name: navigationNodes.insightsReviews.defaultMessage,
        path: `${parentPath}/insights/reviews`,
      },
      {
        id: navigationNodes.insightsSentiment.id,
        name: navigationNodes.insightsSentiment.defaultMessage,
        path: `${parentPath}/insights/sentiment`,
      },
      {
        id: navigationNodes.insightsCompetitors.id,
        name: navigationNodes.insightsCompetitors.defaultMessage,
        path: `${parentPath}/insights/competitors`,
      },
      {
        id: navigationNodes.insightsBenchmarks.id,
        name: navigationNodes.insightsBenchmarks.defaultMessage,
        path: `${parentPath}/insights/benchmarks`,
      },
      {
        id: navigationNodes.insightsSurveyReviews.id,
        name: navigationNodes.insightsSurveyReviews.defaultMessage,
        path: `${parentPath}/insights/survey-reviews`,
      },
      {
        id: navigationNodes.insightsSurveyStatistics.id,
        name: navigationNodes.insightsSurveyStatistics.defaultMessage,
        path: `${parentPath}/insights/survey-statistics`,
      },
    ],
  },
  {
    id: navigationNodes.surveyManager.id,
    name: navigationNodes.surveyManager.defaultMessage,
    icon: faClipboardListCheck,
    path: `${parentPath}/survey-manager`,
  },
  {
    id: navigationNodes.reports.id,
    name: navigationNodes.reports.defaultMessage,
    icon: faFileArrowDown,
    path: `${parentPath}/reports`,
    isHidden: isTemporaryHidden('CXP-customers-reports'),
    isHiddenOnMobile: true,
  },
  {
    id: navigationNodes.widgets.id,
    name: navigationNodes.widgets.defaultMessage,
    icon: faGrid2Plus,
    isHidden: true,
  },
  {
    id: navigationNodes.organization.id,
    name: navigationNodes.organization.defaultMessage,
    icon: faSitemap,
    childNodes: [
      {
        id: navigationNodes.subscriptions.id,
        name: navigationNodes.subscriptions.defaultMessage,
        // icon: faMoneyCheckDollar,
        path: `${parentPath}/subscriptions`,
      },
      {
        id: navigationNodes.entities.id,
        name: navigationNodes.entities.defaultMessage,
        // icon: faBuildings,
        path: `${parentPath}/organization/entities`,
      },
      {
        id: navigationNodes.users.id,
        name: navigationNodes.users.defaultMessage,
        // icon: faUsers,
        path: `${parentPath}/organization/users`,
      },
      {
        id: navigationNodes.benchmarks.id,
        name: navigationNodes.benchmarks.defaultMessage,
        // icon: faChartBullet,
        path: `${parentPath}/benchmarks`,
      },
    ],
  },
];

export const footerNodes = (parentPath: string): Node[] => [
  {
    id: navigationNodes.support.id,
    name: navigationNodes.support.defaultMessage,
    isHidden: true,
    icon: faCircleQuestion,
  },
  {
    id: navigationNodes.appSettings.id,
    name: navigationNodes.appSettings.defaultMessage,
    icon: faGear,
    path: `${parentPath}/settings/application`,
  },
  {
    id: navigationNodes.userSettings.id,
    name: navigationNodes.userSettings.defaultMessage,
    icon: faUserCircle,
    path: `${parentPath}/settings/user`,
  },
];

export const appSettingsNodes = (parentPath: string): Node[] => [
  // {
  //   id: commonFields.dashboard.id,
  //   name: commonFields.dashboard.defaultMessage,
  //   icon: faGaugeMax,
  //   path: `${parentPath}/settings/application/dashboard`,
  // },
  // {
  //   id: commonFields.inbox.id,
  //   name: commonFields.inbox.defaultMessage,
  //   icon: faMessageDots,
  //   path: `${parentPath}/settings/application/inbox`,
  // },

  {
    id: navigationNodes.appGeneralSettings.id,
    name: navigationNodes.appGeneralSettings.defaultMessage,
    icon: faHouseBlank,
    isHidden: isTemporaryHidden('CXP-customers-organization-profile'),
    path: `${parentPath}/settings/application/general`,
  },
  {
    id: navigationNodes.appSubscriptionsSettings.id,
    name: navigationNodes.appSubscriptionsSettings.defaultMessage,
    icon: faFileInvoiceDollar,
    isHidden: isTemporaryHidden('CXP-customers-subscription'),
    path: `${parentPath}/settings/application/subscriptions`,
  },
  {
    id: navigationNodes.customAttributes.id,
    name: navigationNodes.customAttributes.defaultMessage,
    icon: faLayerGroup,
    path: `${parentPath}/settings/application/custom-attributes`,
  },
  {
    id: navigationNodes.visitData.id,
    name: navigationNodes.visitData.defaultMessage,
    icon: faCalendarLinesPen,
    path: `${parentPath}/settings/application/visit-data-points`,
  },
  {
    id: navigationNodes.appReviewProvidersSettings.id,
    name: navigationNodes.appReviewProvidersSettings.defaultMessage,
    icon: faPlug,
    path: `${parentPath}/settings/application/review-providers`,
  },
];

export const userSettingsNodes = (parentPath: string): Node[] => [
  {
    id: commonFields.general.id,
    name: commonFields.general.defaultMessage,
    icon: faHouseBlank,
    path: `${parentPath}/settings/user/general`,
  },
  {
    id: commonFields.notifications.id,
    name: commonFields.notifications.defaultMessage,
    icon: faBell,
    path: `${parentPath}/settings/user/notifications`,
  },
  {
    id: commonFields.inbox.id,
    name: commonFields.inbox.defaultMessage,
    icon: faMessageDots,
    path: `${parentPath}/settings/user/inbox`,
  },
  // {
  //   id: commonFields.dashboard.id,
  //   name: commonFields.dashboard.defaultMessage,
  //   icon: faGaugeMax,
  //   path: `${parentPath}/settings/user/dashboard`,
  // },
];

export const combinedNodes = (parentPath: string): Node[] => [
  ...nodes(parentPath),
  ...footerNodes(parentPath),
  ...appSettingsNodes(parentPath),
  ...userSettingsNodes(parentPath),
];
