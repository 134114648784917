import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPane: {
    height: '100%',
  },
  contentPaneBody: {
    height: 'calc(100% - 72px)',
  },
  tableContainer: { height: '100%' },
};

export default styles;
