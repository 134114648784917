import { useParams } from 'react-router-dom';

import { useLanguageStore } from '@trustyou/shared';

import type { SemaLanguage, SemaMatchesSchema } from '../../../client';
import {
  useFetchMetaCategory,
  useFetchSubcategories,
  useFetchSubcategoryMapping,
  useReview,
} from '../../../hooks/api-query';
import { useShortLocale } from '../../../hooks/use-short-locale';

const onlyUnique = (value: string, index: number, array: string[]) =>
  array.indexOf(value) === index;

const getSemanticCategoryItemsByTokens = (tokens: string[], data?: Record<string, string>) =>
  tokens.filter(onlyUnique).map((token) => data?.[token]) as string[];

export function useSemanticCategoryItems() {
  const { locale } = useLanguageStore();
  const shortLocale = useShortLocale();
  const { data } = useFetchMetaCategory(shortLocale);
  const { data: subcategories } = useFetchSubcategories(locale as SemaLanguage);
  const { data: subcategoryMap } = useFetchSubcategoryMapping();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { p, o, n, opinions } = (reviewRoot?.sema_matches || {}) as SemaMatchesSchema;

  const getSemanticCategoryItemsByOpinions = (sentiment: 'p' | 'n' | 'o'): string[] => {
    if (!opinions || !subcategoryMap) return [];

    //unique topic ids for given sentiment
    const topics = [
      ...new Set(
        opinions
          .filter((opinion) => opinion.sentiment === sentiment)
          .map((opinion) => opinion.topic_id)
      ),
    ];

    const subcategoryTopics = Object.keys(subcategoryMap).reduce(
      (result: string[], sector: string) => {
        // filter mapping sectors that do not match the sector of the current review
        if (reviewRoot?.meta.sector !== sector.toUpperCase()) return result;

        // all combined unique sector mappings
        return [
          ...new Set([
            ...result,
            // get array of category keys for given topics
            ...Object.keys(subcategoryMap[sector]).filter((subcategory) =>
              topics.some((topic) => subcategoryMap[sector][subcategory].includes(topic))
            ),
          ]),
        ];
      },
      []
    );

    // extract the subcategory labels
    return subcategoryTopics?.map((key: string) => subcategories?.[key]) ?? [];
  };

  const getCategoriesForText = (text: string): string => {
    if (!opinions || !subcategoryMap || !reviewRoot?.meta?.sector) {
      return '';
    }

    const sector = reviewRoot.meta.sector.toLowerCase();

    if (!subcategoryMap[sector]) {
      return '';
    }

    const opinionsForText = opinions.filter((opinion) => opinion.text === text);
    const topicIds = opinionsForText.map((opinion) => opinion.topic_id);
    const categories: string[] = [];

    topicIds.forEach((topicId) => {
      for (const subcategory in subcategoryMap[sector]) {
        if (subcategoryMap[sector][subcategory].includes(topicId)) {
          const category = subcategories?.[subcategory];
          if (category) {
            categories.push(category);
          }
          break;
        }
      }
    });

    const uniqueCategories = Array.from(new Set(categories));
    const categoryString = uniqueCategories.join(' / ');

    return categoryString;
  };

  const getSemanticCategoryItems = (sentiment: 'p' | 'n' | 'o') => {
    const tokens = sentiment === 'p' ? p : sentiment === 'n' ? n : o;
    return [
      ...new Set([
        ...getSemanticCategoryItemsByOpinions(sentiment),
        ...getSemanticCategoryItemsByTokens(tokens ?? [], data),
      ]),
    ];
  };

  return { getSemanticCategoryItems, getCategoriesForText };
}
