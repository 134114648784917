import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FormGroup, Stack } from '@trustyou/ui';

import { EnabledSwitch, KeywordsTextarea, ScoreSelect, SourceSelect } from './form-fields';
import { NestedFilters } from './nested-filters';

import type { EmailNotificationSettings_Input } from '../../../client';
import { toInput } from '../../../utils/keyword-search';

export function ReviewsSection() {
  const intl = useIntl();
  const { control, watch } = useFormContext<EmailNotificationSettings_Input>();
  const { fields } = useFieldArray({ control, name: 'reviews' });

  return (
    <FormGroup sx={{ gap: 2 }}>
      {fields.map((field, index) => (
        <Stack key={field.id}>
          <EnabledSwitch
            fieldName={`reviews.${index}.enabled`}
            label={intl.formatMessage({
              id: 'inbox.settings.notifications.daily.label',
              defaultMessage: 'Receive a daily email summary of new reviews',
            })}
          />
          <NestedFilters isVisible={watch(`reviews.${index}.enabled`)}>
            <ScoreSelect fieldName={`reviews.${index}.filters.score`} />
            <SourceSelect fieldName={`reviews.${index}.filters.source`} />
            <KeywordsTextarea
              fieldName={`reviews.${index}.filters.search_terms`}
              initialValue={toInput(field.filters?.search_terms ?? [])}
            />
          </NestedFilters>
        </Stack>
      ))}
    </FormGroup>
  );
}
