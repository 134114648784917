import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import type { SemaLanguage } from '../../../client';
import { useFetchTopLevelCategories, useFilters } from '../../../hooks';
import { useDialogState } from '../../../hooks/use-dialog-state';
import type { Category } from '../../../types';
import { FilterDialogButton } from '../../filter-dialog-button';
import { SelectorModal } from '../../selector-modal';

type CategoriesFilterProps = {
  isLoading?: boolean;
};

export function CategoriesFilter({ isLoading }: CategoriesFilterProps) {
  const intl = useIntl();
  const { currentView } = useFilters();
  const { locale } = useLanguageStore();
  const { setValue, getValues } = useFormContext();

  const { data, isPending, isSuccess } = useFetchTopLevelCategories(locale as SemaLanguage);
  const formattedData = useMemo(() => {
    return Object.entries(data ?? {}).reduce(
      (res: { id: string; title: string }[], [key, value]) => {
        res.push({ id: key, title: value });
        return res;
      },
      []
    );
  }, [data]);

  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const { isDialogOpen, openDialog, closeDialog } = useDialogState();

  useEffect(() => {
    setCategories(formattedData);
  }, [formattedData]);

  useEffect(() => {
    setSelectedCategories(
      formattedData.filter(({ id }) => currentView.filters?.categories?.includes(id)) ?? []
    );
  }, [getValues, formattedData, currentView.filters?.categories]);

  const saveCategories = () => {
    setValue(
      'categories',
      selectedCategories.map(({ id }) => id)
    );
    closeDialog();
  };

  const cancel = () => {
    setSelectedCategories(
      categories.filter(({ id }) => getValues('categories')?.includes(id)) || []
    );
    closeDialog();
  };

  const renderRowContent = (category: Category) => <ListItemText primary={category.title} />;

  const onSearch = (value: string) => {
    const filteredCategories = formattedData.filter(({ title }) =>
      title.toLowerCase().includes(value.toLowerCase())
    );
    setCategories(filteredCategories);
  };

  return (
    <>
      <FilterDialogButton
        title={<FormattedMessage id="inbox.filter.categories.name" defaultMessage="Categories" />}
        description={
          isSuccess &&
          `${selectedCategories.length > 0 ? selectedCategories.length : Object.keys(data).length} / ${Object.keys(data).length}`
        }
        onClick={openDialog}
      />
      <SelectorModal
        isOpen={isDialogOpen}
        items={categories}
        selectedItems={selectedCategories}
        setSelectedItems={setSelectedCategories}
        isLoading={isPending || isLoading}
        onClose={cancel}
        onSave={saveCategories}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.categories',
          defaultMessage: 'Search categories',
        })}
        title={
          <FormattedMessage id="inbox.filter.categories.modal.title" defaultMessage="Categories" />
        }
        allTab={
          <FormattedMessage
            id="inbox.filter.categories.modal.button.all"
            defaultMessage="All categories"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.categories.modal.button.select"
            defaultMessage="Select categories"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.categories.modal.selected-count"
            defaultMessage="Selected categories ({count})"
            values={{ count: selectedCategories.length }}
          />
        }
        alertDescription={
          <FormattedMessage
            id="inbox.filter.categories.modal.alert"
            defaultMessage="Please select at least one category"
          />
        }
        onSearch={onSearch}
      />
    </>
  );
}
