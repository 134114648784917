import { DragDropContext, type DropResult, Droppable } from 'react-beautiful-dnd';

import type { View } from '@trustyou/shared';
import { List, Stack } from '@trustyou/ui';

import { ViewsEmptyState } from './views-empty-state';

import { SortableViewItem } from './sortable-view-item';

export type ManageTabContentProps = {
  views: View[];
  onReorderViews: (result: DropResult) => void;
  onDeleteView: (viewNameToDelete: string) => void;
};

export const ManageTabContent = ({
  views,
  onReorderViews,
  onDeleteView,
}: ManageTabContentProps) => {
  if (!views.length) {
    return <ViewsEmptyState />;
  }

  return (
    <Stack sx={{ margin: -2 }}>
      <DragDropContext onDragEnd={onReorderViews}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {views.map((view, index) => (
                <SortableViewItem key={index} view={view} index={index} onDelete={onDeleteView} />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};
