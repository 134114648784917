import { fitImageToParent } from '@trustyou/shared';
import { Box, BrandedAvatar, Button, IconButton, Stack } from '@trustyou/ui';

type UserButtonProps = {
  logo?: string;
  name: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const LOGO_MAX_HEIGHT = 38;
const LOGO_MAX_WIDTH = 300;

export function UserButton({ logo, name, onClick }: UserButtonProps) {
  return logo ? (
    <Button
      data-testid="user-profile-button"
      variant="outlined"
      size="small"
      onClick={onClick}
      sx={{
        padding: 0.5,
        borderRadius: 2,
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          background: (theme) => theme.palette.background.paper,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Box
          component="img"
          src={logo}
          alt="Organization logo"
          onLoad={(e) => {
            fitImageToParent(e.target as HTMLImageElement, LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH);
          }}
          sx={{
            maxHeight: `${LOGO_MAX_HEIGHT}px`,
            maxWidth: `${LOGO_MAX_WIDTH}px`,
          }}
        />
        <BrandedAvatar name={name} />
      </Stack>
    </Button>
  ) : (
    <IconButton data-testid="user-profile-button" onClick={onClick} sx={{ padding: 0 }}>
      <BrandedAvatar name={name} />
    </IconButton>
  );
}
