import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyAccessGroupMembersStateProps = {
  sx?: StyleUnit;
};

export const EmptyAccessGroupMembersState = ({ sx }: EmptyAccessGroupMembersStateProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="organization.access-groups.no-access-group-users"
        defaultMessage="No users yet"
      />
    }
    description={
      <FormattedMessage
        id="organization.access-groups.no-access-group-users-description"
        defaultMessage="When you assign users to this access group, they will show up here."
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
