import { capitalizeFirstLetter } from '@trustyou/shared';

import type { InboxStatusEnum, ViewScoreEnum } from '../client';
import type { Group } from '../types';
import { flipObject } from '../utils/misc';

// timerange
export const TIME_RANGE_GROUPS = [
  {
    label: 'Days & Weeks',
    options: [
      {
        label: 'Yesterday',
        value: 'Yesterday',
      },
      {
        label: 'Last 7 days',
        value: 'Last 7 days',
      },
      {
        label: 'Last week',
        value: 'Last week',
      },
    ],
  },
  {
    label: 'Months',
    options: [
      {
        label: 'Month to date',
        value: 'Month to date',
      },
      {
        label: 'Last full month',
        value: 'Last full month',
      },
      {
        label: 'Last 24 months',
        value: 'Last 24 months',
      },
    ],
  },
  {
    label: 'Quarters',
    options: [
      {
        label: 'Quarter to Date',
        value: 'Quarter to Date',
      },
      {
        label: 'Last full quarter',
        value: 'Last full quarter',
      },
    ],
  },
  {
    label: 'Years',
    options: [
      {
        label: 'Year to date',
        value: 'Year to date',
      },
      {
        label: 'Year to last month',
        value: 'Year to last month',
      },
      {
        label: 'Last full year',
        value: 'Last full year',
      },
    ],
  },
];

// status
export const STATUSES: InboxStatusEnum[] = ['unread', 'read', 'responded', 'confirmed'];
export const STATUS_GROUPS: Group[] = [
  {
    label: 'Unresponded',
    options: [
      {
        label: 'Unread',
        value: 'unread',
      },
      {
        label: 'Read',
        value: 'read',
      },
    ],
  },
  {
    label: 'Responded',
    options: [
      {
        label: 'Marked as responded',
        value: 'responded',
      },
      {
        label: 'Response confirmed',
        value: 'confirmed',
      },
    ],
  },
  {
    label: 'Moderated',
    options: [
      {
        label: 'Marked as deleted',
        value: 'marked_as_deleted',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
      {
        label: 'Marked as inappropriate',
        value: 'marked_as_inappropriate',
      },
      {
        label: 'Inappropriate',
        value: 'inappropriate',
      },
    ],
  },
];

// score (or rating)
export const SCORE_RANGE_MAP: Record<ViewScoreEnum, number[] | boolean> = {
  no_score: true,
  low: [0, 5.9],
  medium: [6, 7.9],
  high: [8, 10],
};
export const SCORE_NAMES = Object.keys(SCORE_RANGE_MAP) as ViewScoreEnum[];
export const SCORE_OPTIONS = Object.entries(SCORE_RANGE_MAP).map(([key, value]) => ({
  label:
    typeof value === 'boolean'
      ? `${capitalizeFirstLetter(key)}`
      : `${capitalizeFirstLetter(key)} (${value.at(0)}-${value.at(1)})`,
  value: key,
}));

// survey type
export const SURVEY_TYPE_NAMES_MAP: Record<string, string> = {
  all: 'all',
  hotel_post_stay: 'Hotel Post-stay',
  hotel_on_site: 'Hotel On-site',
  restaurant: 'Food & Beverages',
  ess: 'MICE',
  attraction: 'Attraction',
  wellness_and_spa: 'Wellness & Spa',
  hotel_pre_stay: 'Hotel Pre-stay',
};

// sorting
export const SORTING_API_NAMES_MAP: Record<string, string> = {
  'Date: New to Old': 'review.date:desc',
  'Date: Old to New': 'review.date:asc',
  'Score: High to Low': 'review.score:desc',
  'Score: Low to High': 'review.score:asc',
};
export const SORTING_SEMANTIC_NAMES_MAP: Record<string, string> = flipObject(SORTING_API_NAMES_MAP);

// Entities limit for filtering to avoid performance issues on big scopes (clients with a lot of entities, like Accor).
// It must be in sync with the environment variable `MAX_ENTITIES` that backend defines:
// <https://stash.trustyou.com/projects/INFRA/repos/gitops-cxpi/browse/inbox-api/values.yaml?at=refs/heads/staging#26>
export const MAX_ENTITIES = Number(process.env.REACT_APP_INBOX_MAX_ENTITIES ?? '250');
