import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { GuestInformationTable } from './guest-information-table';

import type { GuestInfo } from '../../client';

export type GuestInformationProps = {
  data?: GuestInfo[] | null;
  isOpen: boolean;
  onClose: () => void;
};

export const GuestInformationDialog = ({ data, isOpen, onClose }: GuestInformationProps) => {
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="inbox.visit-data" defaultMessage="Visit data" />
        <IconButton
          aria-label="close visit data"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(2),
          }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data?.length ? (
          <GuestInformationTable rows={data} />
        ) : (
          <Stack sx={{ alignItems: 'center', paddingBlockStart: 2 }}>
            <Box sx={{ width: 225, textAlign: 'center' }}>
              <FormattedMessage
                id="inbox.visit-data.no-data"
                defaultMessage="No visit data for this review"
              ></FormattedMessage>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
