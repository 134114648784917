import { Controller, useFormContext } from 'react-hook-form';

import type { FormControlProps } from '@mui/material';
import type { FiltersField } from '@trustyou/shared';
import { Autocomplete, FormControl, ListItem, ListItemText, TextField } from '@trustyou/ui';

import { FONT_SIZE, MIN_FIELD_HEIGHT } from './constants';

import type { Option } from '../../types';

export type DropdownSingleAutocompleteProps = FormControlProps & {
  fieldName: FiltersField;
  label: string;
  options: Option[];
};

export function DropdownSingleAutocomplete({
  fieldName,
  label,
  options,
  ...props
}: DropdownSingleAutocompleteProps) {
  const { control } = useFormContext();

  const getValue = (value?: string) => {
    if (!value) return null;
    const option = options.find((option) => value === option.value);
    return option ?? null;
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange } }) => (
        <FormControl {...props}>
          <Autocomplete
            fullWidth
            openOnFocus
            autoHighlight
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            value={getValue(value)}
            onChange={(event, value) => onChange(value?.value ?? '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps, sx: { minHeight: MIN_FIELD_HEIGHT } }}
                inputProps={{ ...params.inputProps, sx: { fontSize: FONT_SIZE } }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.value} dense>
                <ListItemText id={option.value} primary={option.label} />
              </ListItem>
            )}
            sx={{
              '& .MuiAutocomplete-tag': {
                fontSize: FONT_SIZE,
                marginInline: 0.5,
              },
            }}
          />
        </FormControl>
      )}
    />
  );
}
