import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Entity,
  FETCH_ENTITY_DETAILS,
  useMaxCompetitors,
  useMembershipStore,
  useSetEntityCompetitors,
} from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, Stack, snackbar } from '@trustyou/ui';

import { CompetitorCard } from './CompetitorCard/CompetitorCard';
import { DeleteCompetitor } from './DeleteCompetitor/DeleteCompetitor';
import { EmptyState } from './EmptyState/EmptyState';
import { MaxCompetitorsInfo } from './MaxCompetitorsInfo/MaxCompetitorsInfo';

import { useManageCompetitors } from '../../hooks';
import styles from './styles';

type Props = {
  entity: Entity;
  onAddCompetitors?: () => void;
};

export const EntityCompetitorsView = ({ entity, onAddCompetitors }: Props) => {
  const competitors = entity.competitors || [];
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { membership } = useMembershipStore();
  const MAX_COMPETITORS_ALLOWED = useMaxCompetitors();
  const [deletableCompetitor, setDeletableCompetitor] = useState<Entity>();
  const setEntityCompetitors = useSetEntityCompetitors();
  const allowManageCompetitors = useManageCompetitors();

  const handleDelete = () => {
    const savingCompetitors = competitors
      .filter((row) => row.id !== deletableCompetitor?.id)
      .map((row) => row.id);
    setEntityCompetitors.mutate(
      {
        organization_id: membership?.organizationId as string,
        entity_id: entity.id,
        competitors: savingCompetitors,
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries({ queryKey: [FETCH_ENTITY_DETAILS] });
          snackbar.success(
            intl.formatMessage({
              id: 'tyDesign.competitorDeleted',
              defaultMessage: 'Competitor deleted',
            })
          );
        },
      }
    );
    setDeletableCompetitor(undefined);
  };

  const getFooterContent = () => {
    if (competitors.length >= MAX_COMPETITORS_ALLOWED) {
      return <MaxCompetitorsInfo />;
    } else if (allowManageCompetitors && onAddCompetitors) {
      return (
        <Button
          data-testid="entity-details-add-competitors-button"
          color="primary"
          variant="outlined"
          onClick={onAddCompetitors}
        >
          <Icon icon={faPlus} sx={styles.addCompetitorIcon} />
          <FormattedMessage id="tyDesign.addCompetitors" defaultMessage="Add competitors" />
        </Button>
      );
    }
    return undefined;
  };

  if (!competitors.length)
    return <EmptyState onAddCompetitors={allowManageCompetitors ? onAddCompetitors : undefined} />;

  return (
    <>
      <Stack
        data-testid="entity-competitors-list"
        sx={styles.container}
        direction="column"
        spacing={2}
      >
        {competitors.map((competitor) => (
          <CompetitorCard
            key={competitor.id}
            competitor={competitor}
            onDelete={allowManageCompetitors ? setDeletableCompetitor : undefined}
          />
        ))}
      </Stack>
      <Box sx={styles.footer}>{getFooterContent()}</Box>
      <DeleteCompetitor
        onClose={() => {
          setDeletableCompetitor(undefined);
        }}
        onDelete={handleDelete}
        competitor={deletableCompetitor}
      />
    </>
  );
};
