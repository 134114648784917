import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type SemaMatchesSchema,
  type SentimentEnum,
  SentimentIcon,
  useTranslatePermission,
  useTranslatedReview,
} from '@trustyou/inbox';
import { isDefined, stringToShortDate, useLanguageStore } from '@trustyou/shared';
import { Box, Button, Skeleton, Typography } from '@trustyou/ui';

import { HighlightedText } from './HighlightedText';

import styles from './styles';

type ReviewCardProps = {
  id: string;
  text: string;
  entity: string;
  date: string;
  title?: string;
  sentiments?: SentimentEnum[];
  score?: number | null;
  source?: string;
  semaMatches?: SemaMatchesSchema | null;
};
export const ReviewCard = ({
  id,
  date,
  score,
  title,
  text,
  entity,
  source,
  sentiments = [],
  semaMatches,
}: ReviewCardProps) => {
  const isTranslateAllowed = useTranslatePermission();
  const [shouldDisplayTranslation, setShouldDisplayTranslation] = useState(false);
  const intl = useIntl();
  const { locale } = useLanguageStore();

  const translatedReview = useTranslatedReview({ reviewId: id, shouldDisplayTranslation });
  const translatedText = translatedReview.data?.review.translations?.[locale]?.text || text;
  const translatedTitle = translatedReview.data?.review.translations?.[locale]?.title || title;

  const secondaryTexts = [stringToShortDate(date), entity];
  const tertiaryTexts: string[] = [];
  if (source) tertiaryTexts.push(source);
  if (isDefined(score)) {
    tertiaryTexts.push(
      intl.formatMessage(
        {
          id: 'analytics.reviews-modal.review-score-value',
          defaultMessage: '{score} review score',
        },
        { score: score.toFixed(2) }
      )
    );
  }

  const handleTranslate = () => {
    setShouldDisplayTranslation(!shouldDisplayTranslation);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatarContainer}>
        {sentiments.includes('positive') && <SentimentIcon type="positive" />}
        {sentiments.includes('neutral') && <SentimentIcon type="neutral" />}
        {sentiments.includes('negative') && <SentimentIcon type="negative" />}
      </Box>
      <Box sx={styles.body}>
        {title && translatedReview.isLoading && <Skeleton />}
        {title && !translatedReview.isLoading && (
          <Typography variant="body2">
            {shouldDisplayTranslation ? translatedTitle : title}
          </Typography>
        )}
        {text && translatedReview.isLoading && <Skeleton />}
        {text && !translatedReview.isLoading && (
          <Typography variant="body2" sx={styles.reviewText}>
            {shouldDisplayTranslation ? (
              translatedText
            ) : (
              <HighlightedText text={text} semaMatches={semaMatches} />
            )}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id="analytics.reviews-modal.review-id" defaultMessage="Review ID" />
          :&nbsp;{id}
        </Typography>
        {isTranslateAllowed && (
          <Button onClick={handleTranslate} type="button" sx={styles.translateButton}>
            {shouldDisplayTranslation ? (
              <FormattedMessage
                id="analytics.reviews-modal.show-original"
                defaultMessage="Show original"
              />
            ) : (
              <FormattedMessage id="analytics.reviews-modal.translate" defaultMessage="Translate" />
            )}
          </Button>
        )}
        <Box sx={styles.secondaryText}>
          <Typography variant="body2">{secondaryTexts.join(' | ')}</Typography>
          <Typography variant="body2">{tertiaryTexts.join(' | ')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
