import {
  $assApi,
  BENCHMARKS_BASE_PATH,
  type GroupAttributeValue,
  type PaginationRequest,
} from '@trustyou/shared';

import type {
  BasicEntity,
  BenchmarkEntitiesResponse,
  BenchmarksResponse,
  CreateBenchmarkRequest,
  CreateBenchmarkResponse,
  EditBenchmarkRequest,
} from '../../../../types/model';

export async function fetchOrganizationBenchmarks(
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<BenchmarksResponse> {
  const url = `${BENCHMARKS_BASE_PATH}/get_organization_benchmarks`;
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}

export async function fetchUserBenchmarks(
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<BenchmarksResponse> {
  const url = `${BENCHMARKS_BASE_PATH}/get_user_benchmarks`;
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}

export async function fetchBenchmarkOwnedEntities(
  benchmarkId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<BenchmarkEntitiesResponse> {
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_benchmark_owned_entities`, {
    params: {
      benchmark_id: benchmarkId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchBenchmarkCompetitorEntities(
  benchmarkId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<BenchmarkEntitiesResponse> {
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_benchmark_competitor_entities`, {
    params: {
      benchmark_id: benchmarkId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function isValidBenchmarkName(params: {
  name: string;
  set_name: string;
  ownership_type: string;
}): Promise<boolean> {
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/validate_name_and_set`, {
    params,
  });
  return data.valid;
}

export async function fetchOrganizationBenchmarkSets(): Promise<string[]> {
  const url = `${BENCHMARKS_BASE_PATH}/get_organization_set_names`;
  const { data } = await $assApi.get(url);
  return data?.set_names || [];
}

export async function fetchUserBenchmarkSets(): Promise<string[]> {
  const url = `${BENCHMARKS_BASE_PATH}/get_user_set_names`;
  const { data } = await $assApi.get(url);
  return data?.set_names || [];
}

export async function createOrganizationBenchmark(
  benchmark: CreateBenchmarkRequest
): Promise<CreateBenchmarkResponse> {
  const { data } = await $assApi.post(
    `${BENCHMARKS_BASE_PATH}/create_organization_benchmark`,
    benchmark
  );
  return data;
}

export async function createUserBenchmark(
  benchmark: CreateBenchmarkRequest
): Promise<CreateBenchmarkResponse> {
  const { data } = await $assApi.post(`${BENCHMARKS_BASE_PATH}/create_user_benchmark`, benchmark);
  return data;
}

export async function editOrganizationBenchmark(benchmark: EditBenchmarkRequest): Promise<void> {
  const { data } = await $assApi.post(
    `${BENCHMARKS_BASE_PATH}/edit_organization_benchmark`,
    benchmark
  );
  return data;
}

export async function editUserBenchmark(benchmark: EditBenchmarkRequest): Promise<void> {
  const { data } = await $assApi.post(`${BENCHMARKS_BASE_PATH}/edit_user_benchmark`, benchmark);
  return data;
}

export async function deleteOrganizationBenchmark(id: string): Promise<void> {
  const { data } = await $assApi.delete(`${BENCHMARKS_BASE_PATH}/delete_organization_benchmark`, {
    params: {
      id,
    },
  });
  return data;
}

export async function deleteUserBenchmark(id: string): Promise<void> {
  const { data } = await $assApi.delete(`${BENCHMARKS_BASE_PATH}/delete_user_benchmark`, {
    params: {
      id,
    },
  });
  return data;
}

export async function fetchBenchmarkAdditionalEntitiesByIds(
  entityIds: string[]
): Promise<BasicEntity[]> {
  const { data } = await $assApi.post(`${BENCHMARKS_BASE_PATH}/get_entities_by_id`, {
    entity_ids: entityIds,
  });
  return data.data;
}

export async function fetchCompetitorsAttributeValues(
  attribute?: string,
  searchValue?: string
): Promise<GroupAttributeValue[]> {
  if (!attribute) return [];
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_competitors_attribute_values`, {
    params: {
      attribute,
      q: searchValue,
      limit: 200,
    },
  });
  return data.data;
}

export async function fetchOrganizationAttributeValues(
  attribute?: string,
  searchValue?: string
): Promise<GroupAttributeValue[]> {
  if (!attribute) return [];
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_organization_attribute_values`, {
    params: {
      attribute,
      q: searchValue,
      limit: 200,
    },
  });
  return data.data;
}
