import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import type { SemaLanguage } from '../../../client';
import { useFetchSubcategories, useFilters } from '../../../hooks';
import { useDialogState } from '../../../hooks/use-dialog-state';
import type { Subcategory } from '../../../types';
import { FilterDialogButton } from '../../filter-dialog-button';
import { SelectorModal } from '../../selector-modal';

type SubcategoriesFilterProps = {
  isLoading?: boolean;
};

export function SubcategoriesFilter({ isLoading }: SubcategoriesFilterProps) {
  const intl = useIntl();
  const { currentView } = useFilters();
  const { locale } = useLanguageStore();
  const { setValue, getValues } = useFormContext();

  const { data, isPending, isSuccess } = useFetchSubcategories(locale as SemaLanguage);
  const formattedData = useMemo(() => {
    return Object.entries(data ?? {}).reduce(
      (res: { id: string; title: string }[], [key, value]) => {
        res.push({ id: key, title: value });
        return res;
      },
      []
    );
  }, [data]);

  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState<Subcategory[]>([]);

  const { isDialogOpen, openDialog, closeDialog } = useDialogState();

  useEffect(() => {
    setSubcategories(formattedData);
  }, [formattedData]);

  useEffect(() => {
    setSelectedSubcategories(
      formattedData.filter(({ id }) => currentView.filters?.subcategories?.includes(id))
    );
  }, [getValues, formattedData, currentView.filters?.subcategories]);

  const saveSubcategories = () => {
    setValue(
      'subcategories',
      selectedSubcategories.map(({ id }) => id)
    );
    closeDialog();
  };

  const cancel = () => {
    setSelectedSubcategories(
      subcategories.filter(({ id }) => getValues('subcategories')?.includes(id)) || []
    );
    closeDialog();
  };

  const renderRowContent = (subcategory: Subcategory) => (
    <ListItemText primary={subcategory.title} />
  );

  const onSearch = (value: string) => {
    const filteredSubCategories = formattedData.filter(({ title }) =>
      title.toLowerCase().includes(value.toLowerCase())
    );
    setSubcategories(filteredSubCategories);
  };

  return (
    <>
      <FilterDialogButton
        title={
          <FormattedMessage id="inbox.filter.subcategories.name" defaultMessage="Subcategories" />
        }
        description={
          isSuccess &&
          `${
            selectedSubcategories.length > 0
              ? selectedSubcategories.length
              : Object.keys(data).length
          } / ${Object.keys(data).length}`
        }
        onClick={openDialog}
      />
      <SelectorModal
        isOpen={isDialogOpen}
        items={subcategories}
        selectedItems={selectedSubcategories}
        isLoading={isPending || isLoading}
        setSelectedItems={setSelectedSubcategories}
        onClose={cancel}
        onSave={saveSubcategories}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.subcategories',
          defaultMessage: 'Search subcategories',
        })}
        title={<FormattedMessage id="inbox.filter.subcategories" defaultMessage="Subcategories" />}
        allTab={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.button.all"
            defaultMessage="All subcategories"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.button.select"
            defaultMessage="Select subcategories"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.selected-count"
            defaultMessage="Selected subcategories ({count})"
            values={{ count: selectedSubcategories.length }}
          />
        }
        alertDescription={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.alert"
            defaultMessage="Please select at least one subcategory"
          />
        }
        onSearch={onSearch}
      />
    </>
  );
}
