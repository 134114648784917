import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  entity: {
    id: 'survey.survey-detail.entity',
    defaultMessage: 'Entity',
  },
  entities: {
    id: 'survey.survey-detail.entities',
    defaultMessage: 'Entities',
  },
  entitiesAssigned: {
    id: 'survey.survey-detail.entities-assigned',
    defaultMessage: 'Entities assigned',
  },
  assigned: {
    id: 'survey.survey-detail.assigned',
    defaultMessage: 'Assigned',
  },
  notAssigned: {
    id: 'survey.survey-detail.not-assigned',
    defaultMessage: 'Not assigned',
  },
  created: {
    id: 'survey.survey-detail.created',
    defaultMessage: 'Created',
  },
  lastModified: {
    id: 'survey.survey-detail.last-modified',
    defaultMessage: 'Last modified',
  },
  questionnaire: {
    id: 'survey.survey-detail.questionnaire',
    defaultMessage: 'Questionnaire',
  },
  internalName: {
    id: 'survey.common.internal-name',
    defaultMessage: 'Internal name',
  },
  fileName: {
    id: 'survey.common.file-name',
    defaultMessage: 'File name',
  },
  distribution: {
    id: 'survey.survey-detail.distribution',
    defaultMessage: 'Distribution',
  },
  preview: {
    id: 'survey.common.preview',
    defaultMessage: 'Preview',
  },
  translations: {
    id: 'survey.common.translations',
    defaultMessage: 'Translations',
  },
  view: {
    id: 'survey.common.view',
    defaultMessage: 'View',
  },
  type: {
    id: 'survey.common.type',
    defaultMessage: 'Type',
  },
  banner: {
    id: 'common.banner',
    defaultMessage: 'Banner',
  },
  logo: {
    id: 'common.logo',
    defaultMessage: 'Logo',
  },
  size: {
    id: 'survey.common.size',
    defaultMessage: 'Size',
  },
  messageType: {
    id: 'survey.common.message-type',
    defaultMessage: 'Message type',
  },
  phoneCountryCodePlaceholder: {
    id: 'survey.question.text.phone-country-code.placeholder',
    defaultMessage: 'Choose a country',
  },
  surveyHeaderImageAlt: {
    id: 'survey.header.image.alt',
    defaultMessage: 'Survey header image',
  },
});
