import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  type CombinedUserAndInvitation,
  type Subscription,
  USER_ROLES,
  USER_SCOPES,
  USER_STATUSES,
  checkUserScopeIsAllowed,
  commonFields,
  detectUserScope,
  errorMessages,
  getDefaultScope,
  getParsedScopeRules,
  getSavingAdvancedSelectionScope,
  useBasicEntities,
  useChangelingStore,
  useInviteUser,
  useScopeStore,
  useUpdateInvitation,
  useUpdateUser,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DiscardChangesModal,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { CancelButton, SubmitButton } from './Buttons';
import { NavHeader } from './NavHeader';
import UserEmail from './UserEmail';

import { messages } from '../../../../../constants/messages/users';
import { useCheckEntityManager } from '../../../../../hooks';
import { RoleSelection } from '../RoleSelection';
import { ScopeSelection } from '../ScopeSelection';
import styles from './styles';

type InviteUpdateUserProps = {
  user?: CombinedUserAndInvitation | null;
  subscription?: Subscription;
  onCancel: () => void;
  isDialog?: boolean;
  shouldRefetchSubscriptionMembers?: boolean;
};

type Form = {
  email: string;
  sendInvitationEmail?: boolean;
  selectedRole?: string;
};

export function InviteUpdateUser({
  user,
  subscription,
  onCancel,
  isDialog,
  shouldRefetchSubscriptionMembers,
}: InviteUpdateUserProps) {
  const intl = useIntl();
  const {
    isDirty: isScopeDirty,
    selectedScope,
    selectedEntities,
    selectedGroups,
    selectedAccessGroup,
    advancedSelectionScope,
    setSelectedScope,
    setSelectedEntities,
    setSelectedGroups,
    setSelectedAccessGroup,
    setAdvancedSelectionScope,
    isValid: isValidScope,
    reset: resetScope,
  } = useScopeStore();
  const [validated, setValidated] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const isEntityManager = useCheckEntityManager();
  const { isChangeling } = useChangelingStore();

  const bodyRef = useRef<HTMLElement>(null);

  const inviteUser = useInviteUser({ shouldRefetchSubscriptionMembers });
  const updateUser = useUpdateUser({ shouldRefetchSubscriptionMembers });
  const updateInvitation = useUpdateInvitation({ shouldRefetchSubscriptionMembers });
  const isLoading = inviteUser.isPending || updateUser.isPending || updateInvitation.isPending;
  const scopeAdditionalEntityIds = user?.scope.mel?.additional_entities || [];
  const { data: scopeAdditionalEntities = [] } = useBasicEntities(
    scopeAdditionalEntityIds,
    !!scopeAdditionalEntityIds.length
  );

  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      sendInvitationEmail: isChangeling ? false : true,
    },
  });

  const {
    trigger,
    formState: { isDirty: isFormDirty },
    getValues,
    setValue,
    reset,
    watch,
  } = methods;

  const selectedRole = watch('selectedRole');

  useEffect(() => {
    setValidated(false);
    reset({
      email: user?.email || '',
      selectedRole: user?.role || USER_ROLES.ADMIN,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setShowCancelConfirmation(false);
    resetScope();
    if (!subscription) return;
    const defaultScope = getDefaultScope(subscription, isEntityManager);
    if (!user) {
      setSelectedScope(defaultScope);
      return;
    }
    const isScopeAllowed = checkUserScopeIsAllowed(user.scope, subscription);
    if (!isScopeAllowed) {
      console.info(
        `user scope is not allowed for given subscription, drop scope to ${defaultScope}`
      );
      setSelectedScope(defaultScope);
      return;
    }
    const detectedScope = detectUserScope(user.scope);
    setSelectedScope(detectedScope);
    if (detectedScope === USER_SCOPES.ACCESS_GROUP && 'reusable' in user.scope) {
      setSelectedAccessGroup(user.scope);
    } else if (user.scope.entities.length) {
      setSelectedEntities(user.scope.entities);
    } else if (user.scope.groups.length) {
      setSelectedGroups(user.scope.groups);
    } else if (user.scope.mel) {
      setAdvancedSelectionScope({
        rules: getParsedScopeRules(user.scope.mel.rule),
        additional_entities: user.scope.mel.additional_entities.map((id) => ({
          id,
          //These values will be filled with scopeAdditionalEntities data
          name: '',
          country_name: '',
          city: '',
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscription]);

  useEffect(() => {
    if (user?.scope.mel?.additional_entities.length && scopeAdditionalEntities.length) {
      setAdvancedSelectionScope({
        rules: getParsedScopeRules(user.scope.mel.rule),
        additional_entities: scopeAdditionalEntities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeAdditionalEntities]);

  const getScopeSubscriptionId = (scope: USER_SCOPES, id: string | null) => {
    if (!id) return null;
    if (scope !== USER_SCOPES.ALL_ENTITIES) return null;
    return id;
  };

  const getSubscriptionIdAssigned = () => {
    if (user?.subscription.is_org_subscription) {
      // edit user within org scope, assing all entities in org
      return null;
    }
    if (user) {
      // edit entity subscription user
      return user.subscription.id;
    }
    if (subscription?.is_org_subscription) {
      // invite user to org scope
      return null;
    }
    if (!subscription) {
      // default invite to org if needed
      return null;
    }
    return subscription?.id;
  };

  const cancelSave = () => {
    if (isFormDirty || isScopeDirty) {
      setShowCancelConfirmation(true);
    } else {
      onCancel();
    }
  };

  const validateForm = async () => {
    const isFormValid = !!user || (await trigger());
    if (!isFormValid) {
      bodyRef?.current?.firstElementChild?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    if (!selectedRole || !isValidScope()) {
      return false;
    }
    return true;
  };

  const onError = () => {
    snackbar.success(intl.formatMessage(errorMessages.genericError));
  };

  const onSave = async () => {
    const isFormValid = await validateForm();
    setValidated(true);
    if (!isFormValid) {
      return;
    }
    const isReusableScope = selectedScope === USER_SCOPES.ACCESS_GROUP && !!selectedAccessGroup;
    const subscriptionIdAssigned = getSubscriptionIdAssigned();
    const savingUser = {
      email: user?.email || getValues('email'),
      ...(!user && isChangeling && !getValues('sendInvitationEmail') && { silent: true }),
      role: selectedRole as string,
      subscription_id: subscriptionIdAssigned,
      user_scope: isReusableScope
        ? undefined
        : {
            reusable: false,
            all: subscriptionIdAssigned ? false : selectedScope === USER_SCOPES.ALL_ENTITIES,
            entity_ids:
              selectedScope === USER_SCOPES.BY_ENTITIES
                ? selectedEntities.map((entity) => entity.id)
                : [],
            group_ids:
              selectedScope === USER_SCOPES.BY_GROUPS
                ? selectedGroups.map((group) => group.id)
                : [],
            mel:
              selectedScope === USER_SCOPES.ADVANCED_SELECTION
                ? getSavingAdvancedSelectionScope(advancedSelectionScope)
                : null,
            subscription_id: getScopeSubscriptionId(selectedScope, subscriptionIdAssigned),
          },
      scope_id: isReusableScope ? selectedAccessGroup.id : undefined,
    };
    if (!user) {
      inviteUser.mutate(savingUser, {
        onSuccess: () => {
          onCancel();
          snackbar.success(
            intl.formatMessage(
              savingUser.silent ? messages.invitationCreated : messages.invitationSent,
              { email: getValues('email') }
            )
          );
        },
        onError,
      });
      return;
    }
    if (user.id && user.status === USER_STATUSES.ACTIVE) {
      updateUser.mutate(
        {
          ...savingUser,
          membership_id: user.membership_id,
        },
        {
          onSuccess: () => {
            onCancel();
            snackbar.success(intl.formatMessage(commonFields.changesSaved));
          },
          onError,
        }
      );
      return;
    }
    if (user.status === USER_STATUSES.INVITED) {
      updateInvitation.mutate(
        {
          ...savingUser,
          invitation_id: user.membership_id,
        },
        {
          onSuccess: () => {
            onCancel();
            snackbar.success(intl.formatMessage(commonFields.changesSaved));
          },
          onError,
        }
      );
      return;
    }
    console.error("User's data could have errors: ", user);
  };

  if (!subscription) {
    return <BackdropSpinner isLoading />;
  }

  const getContent = () => (
    <Box ref={bodyRef} sx={styles.body}>
      <FormProvider {...methods}>
        <UserEmail showEmailSendConfirmation={isChangeling && !user} />
        <RoleSelection
          role={selectedRole}
          setRole={(value) => setValue('selectedRole', value, { shouldDirty: true })}
          validated={validated}
        />
        <ScopeSelection subscription={subscription} validated={validated} />
      </FormProvider>
      <BackdropSpinner isLoading={isLoading} />
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={onCancel}
        open={showCancelConfirmation}
      />
    </Box>
  );

  if (isDialog) {
    return (
      <Dialog
        open={user !== null}
        onClose={cancelSave}
        sx={{ ...styles.dialog, ...(isChangeling ? styles.changelingMode : {}) }}
      >
        <DialogTitle variant="h6">
          <NavHeader user={user} />
        </DialogTitle>
        <DialogContent>{getContent()}</DialogContent>
        <DialogActions>
          <CancelButton cancelSave={cancelSave} />
          <SubmitButton user={user} onSave={onSave} />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ContentPane
      headers={[
        <Typography variant="h6">
          <NavHeader user={user} />
        </Typography>,
      ]}
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
      navigationHandle={cancelSave}
    >
      {getContent()}
      <Box sx={styles.footer}>
        <CancelButton cancelSave={cancelSave} />
        <SubmitButton user={user} onSave={onSave} />
      </Box>
    </ContentPane>
  );
}
