import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGE_SIZE,
  type Datapoint,
  useDataGridIntl,
  useLanguageStore,
  useVisitDatapointListQuery,
} from '@trustyou/shared';
import { ContentPane, DataGridPro, Typography } from '@trustyou/ui';

import { CustomDataPointDrawer } from './CustomDataPointDrawer/CustomDataPointDrawer';
import { CustomToolbar } from './custom-toolbar';
import { visitDataColumns } from './useColumns';

import { styles } from './styles';

export function VisitDataPoints() {
  const intl = useIntl();
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const { data, refetch } = useVisitDatapointListQuery();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDataPoint, setSelectedDataPoint] = useState<Datapoint | undefined>(undefined);

  return (
    <ContentPane
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
      headers={[
        <Typography variant="h6">
          <FormattedMessage
            id="app.settings.visit-data-points"
            defaultMessage="Visit data points"
          />
        </Typography>,
      ]}
    >
      <DataGridPro
        columns={[
          ...visitDataColumns({
            editButtonClick: () => {
              setOpenDrawer(true);
              setIsEdit(true);
            },
            intl,
          }),
        ]}
        rows={Array.isArray(data) ? data : []}
        getRowId={(row) => row._id}
        pageSizeOptions={[15, 50, 100]}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        ignoreDiacritics
        disableRowSelectionOnClick
        getRowClassName={(params) => (params.row.metadata.deleted_at ? 'disabled-row' : '')}
        slots={{
          toolbar: () =>
            CustomToolbar({
              newDataPointClick: () => {
                setOpenDrawer(true);
                setIsEdit(false);
                setSelectedDataPoint(undefined);
              },
            }),
        }}
        onRowClick={({ row }) => {
          setSelectedDataPoint(row);
        }}
        sx={{
          borderStyle: 'none',
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
          '& .MuiDataGrid-row.disabled-row': {
            color: 'text.disabled',
            pointerEvents: 'none',
            opacity: 0.6,
          },
        }}
        density="standard"
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      />
      <CustomDataPointDrawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          refetch();
        }}
        isEdit={isEdit}
        selectedDataPoint={selectedDataPoint}
      />
    </ContentPane>
  );
}
