import { useIntl } from 'react-intl';

import type { FormControlProps } from '@mui/material';
import type { FiltersField } from '@trustyou/shared';
import { SmallFormFieldSkeleton } from '@trustyou/ui';

import { DropdownMultipleAutocomplete } from './dropdown-multiple-autocomplete';

import { useFetchSources } from '../../hooks';
import { useFilters } from '../../hooks/use-filters';
import type { Option } from '../../types';

export type DropdownSourceProps = FormControlProps & {
  fieldName: FiltersField;
};

export function DropdownSource({ fieldName, ...props }: DropdownSourceProps) {
  const intl = useIntl();
  const { data, isPending } = useFetchSources();
  const { currentView } = useFilters();

  if (isPending) return <SmallFormFieldSkeleton />;

  // TODO: method `toSorted()` can be used instead of the in-place `sort()` when bumping Node to v20+
  const sortedSourceOptions: Option[] = Object.entries(data ?? {})
    .map(([name, uuid]) => ({ label: name, value: uuid }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DropdownMultipleAutocomplete
      fieldName={fieldName}
      label={intl.formatMessage({
        id: 'inbox.filter.source.label',
        defaultMessage: 'Source',
      })}
      options={sortedSourceOptions}
      initialValues={currentView.filters?.sources ?? []}
      {...props}
    />
  );
}
