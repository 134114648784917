import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPane: {
    height: '100%',
  },
  contentPaneBody: {
    paddingTop: 2,
    height: '100%',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabs: {
    minHeight: '42px',
    '.MuiButtonBase-root': {
      minHeight: '42px',
    },
  },
  body: {
    height: 'calc(100% - 42px)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 3,
  },
};

export default styles;
