import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetSignature } from '../../../../hooks';
import type { ResponseFormSchema, Template } from '../../../../types';

export function useAppendSignature({
  templatesByLanguage,
  templateName,
  language,
}: {
  templatesByLanguage: Template[];
  templateName: string;
  language: string;
}) {
  const { data: signature } = useGetSignature();
  const { setValue } = useFormContext<ResponseFormSchema>();

  const setResponseValue = (currentText: string) => {
    setValue('response', currentText, { shouldDirty: true });
  };

  useEffect(() => {
    const foundTemplate = templatesByLanguage.find((template) => template.name === templateName);
    if (!foundTemplate) {
      setResponseValue('');
      return;
    }
    if (!signature) {
      setResponseValue(foundTemplate.text);
      return;
    }
    setResponseValue(`${foundTemplate.text}\n${signature}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, templateName]);
}
