import { useIntl } from 'react-intl';

import type { Option } from '../../types';

export function useScoreOptions() {
  const intl = useIntl();

  const scoreOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.score.noScore',
        defaultMessage: 'No score',
      }),
      value: 'no_score',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.score.low',
        defaultMessage: 'Low (0 - 5.9)',
      }),
      value: 'low',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.score.medium',
        defaultMessage: 'Medium (6 - 7.9)',
      }),
      value: 'medium',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.score.high',
        defaultMessage: 'High (8 - 10)',
      }),
      value: 'high',
    },
  ];

  return scoreOptions;
}
