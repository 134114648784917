import { FormattedMessage } from 'react-intl';

import {
  ContentPane,
  EmptyStatePlaceholder,
  personAnalyzingChartsWithMagnifyingGlass,
} from '@trustyou/ui';

import styles from './styles';

export const EmptyState = () => (
  <ContentPane sx={styles.emptyStateContentPane} bodyStyles={styles.emptyStateBodyStyles}>
    <EmptyStatePlaceholder
      img={personAnalyzingChartsWithMagnifyingGlass}
      dataName="survey-insights"
      title={
        <FormattedMessage id="survey.dashboard.empty-state.title" defaultMessage="No surveys yet" />
      }
      description={
        <FormattedMessage
          id="survey.dashboard.empty-state.description"
          defaultMessage="Once set up, survey responses will show up here"
        />
      }
      emptyStateHeader={styles.emptyStateHeader}
    />
  </ContentPane>
);
