import type { GridColDef } from '@mui/x-data-grid-pro';
import { CustomCell } from '@trustyou/ui';

import type { SurveyByEntity } from '../../../../types/survey';

export function useColumns() {
  const columns: GridColDef<SurveyByEntity>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'entity',
      headerName: 'Entity',
      flex: 1,
      renderCell: ({ value }) => <CustomCell text={value} variant="emphasized" />,
    },
    {
      field: 'version',
      headerName: 'Theme version',
      flex: 3,
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
  ];

  return columns;
}
