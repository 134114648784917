import { type PropsWithChildren, useState } from 'react';
import type { UseControllerReturn } from 'react-hook-form';

import type { CheckboxProps } from '@mui/material';
import { faExclamationCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useNotApplicableStore } from '@trustyou/shared';
import {
  Asterisk,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export type QuestionProps = PropsWithChildren & {
  field?: UseControllerReturn['field'];
  errorMessage?: string;
  title?: string;
  description?: string;
  notApplicableCheckboxLabel?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLegalCheckbox?: boolean;
};

export function Question({
  field,
  errorMessage,
  title,
  description,
  notApplicableCheckboxLabel,
  isMandatory,
  isDisabled,
  isHidden,
  children,
}: QuestionProps) {
  const notApplicableMap = useNotApplicableStore((state) => state.notApplicableMap);
  const [notApplicableValue, setNotApplicableValue] = useState(
    notApplicableMap.get(field?.name ?? '') ?? false
  );

  const handleNotApplicableChange: CheckboxProps['onChange'] = (event, checked) => {
    if (!field) return;
    setNotApplicableValue(checked);
    if (checked) {
      notApplicableMap.set(field.name, checked);
      field.onChange(null);
    } else {
      notApplicableMap.delete(field.name);
    }
  };

  return (
    <Stack spacing={1} sx={{ ...(isHidden && { display: 'none' }) }}>
      {title && (
        <Box>
          {title && (
            <Typography component="h3" variant="subtitle1">
              {title}
              {isMandatory && <Asterisk />}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          )}
          {notApplicableCheckboxLabel && (
            <FormGroup sx={{ alignContent: 'start' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={notApplicableValue}
                    onChange={handleNotApplicableChange}
                  />
                }
                label={
                  <Typography variant="body2" color="text.secondary">
                    {notApplicableCheckboxLabel}
                  </Typography>
                }
              />
            </FormGroup>
          )}
        </Box>
      )}
      <Box
        sx={{
          ...((isDisabled || notApplicableValue) && {
            pointerEvents: 'none',
            opacity: 0.5,
          }),
        }}
      >
        {children}
      </Box>
      {errorMessage && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', color: 'error.main' }}>
          <StyledFontAwesomeIcon icon={faExclamationCircle} sx={{ height: 20, width: 20 }} />
          <Typography variant="caption" color="inherit">
            {errorMessage}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
