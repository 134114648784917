import { FormattedMessage } from 'react-intl';

import { Box, Stack, Typography } from '@trustyou/ui';

import { StyledSupportLink } from './StyledSupportLink';

import personInFrontOfAnErrorScreen from '../../assets/illustrations/person-in-front-of-an-error-screen.svg';
import styles from './styles';

export const SomethingWrongRoute = () => (
  <Stack sx={styles.wrapper}>
    <Box
      component="img"
      src={personInFrontOfAnErrorScreen}
      alt="Person in front of an error screen"
    />
    <Stack sx={styles.textWrapper}>
      <Typography variant="h6">
        <FormattedMessage
          id="tyDesign.somethingWentWrong"
          defaultMessage="Oops! Something went wrong"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.somethingWrongDescription"
          defaultMessage="This may be caused by a server error or a connection issue. Refresh the page and make sure that your internet connection is working. If the problem persists, {contact} for assistance."
          values={{ contact: <StyledSupportLink /> }}
        />
      </Typography>
    </Stack>
  </Stack>
);
