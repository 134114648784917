import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { saveAs } from 'file-saver';

const SCROLL_END_GAP = 5;

export const downloadFile = (fileName: string, name: string) => {
  const link = document.createElement('a');
  link.href = fileName;
  link.download = name;
  link.click();
};

export const downloadCSV = (name: string, content: string) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, name);
};

export const downloadExcel = (name: string, content: string) => {
  const blob = new Blob([content], {
    type: 'application/vnd.ms-excel;charset=utf-8',
  });
  saveAs(blob, `${name}.xls`);
};

export const getPaginationRequest = (paginationModel: GridPaginationModel) => ({
  limit: paginationModel.pageSize,
  offset: paginationModel.page * paginationModel.pageSize,
});

export const getSortRequest = (sortModel: GridSortModel) =>
  `${sortModel[0].sort === 'asc' ? '' : '-'}${sortModel[0].field}`;

// Avoid to use it, not secure. Use claims from token.
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const isScrollEnd = ({ scrollHeight, scrollTop, clientHeight }: HTMLElement) =>
  scrollHeight <= scrollTop + clientHeight + SCROLL_END_GAP;

export const getFakePromise = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const getLocalizedURL = (url: URL, locale: string) => {
  const decodedPathname = decodeURIComponent(url.pathname);
  const shortLocale = locale.substring(0, 2);
  url.pathname = decodedPathname.replace('{lang_code}', shortLocale);
  return url;
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export const isDefined = <T>(
  value: T | null | undefined
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

export const fitImageToParent = (
  img: HTMLImageElement,
  parentHeight: number,
  parentWidth: number
) => {
  // Get the natural size of the image
  const { naturalWidth, naturalHeight } = img;

  // Calculate scaling factor
  const heightScale = parentHeight / naturalHeight;
  const widthScale = parentWidth / naturalWidth;
  const scale = Math.min(heightScale, widthScale);

  // Apply calculated size
  img.style.height = `${naturalHeight * scale}px`;
  img.style.width = `${naturalWidth * scale}px`;
};
