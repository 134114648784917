import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  type Filters,
  filtersSchema,
  isRouteEndsWith,
  useEntityCount,
  useResponsive,
} from '@trustyou/shared';
import { Box, ContentPane, Stack } from '@trustyou/ui';

import { InboxEmptyState } from '../../components/inbox-empty-state';
import { InboxHeaderWrapper } from '../../components/inbox-header-wrapper';
import { LastUpdateTimestamp } from '../../components/last-update-timestamp';
import { ReviewsDataGrid } from '../../components/reviews-data-grid/reviews-data-grid';
import { useComponentHeight } from '../../hooks';
import { useFilters } from '../../hooks/use-filters';

export function Inbox() {
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const { data: entityCount } = useEntityCount();

  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight = useComponentHeight(headerRef);

  const { currentView, applyFilters, debugInvalidForm } = useFilters();

  const methods = useForm<Filters>({
    values: currentView.filters,
    resolver: zodResolver(filtersSchema),
  });

  const isParentRoute = isRouteEndsWith(location.pathname, 'inbox');
  const noEntitiesYet = entityCount === 0;

  const MANUAL_ADJUSTMENT_FOR_NEGATIVE_MARGIN = 16;
  const dataGridContainerHeight = `calc(100% - ${headerHeight}px + ${MANUAL_ADJUSTMENT_FOR_NEGATIVE_MARGIN}px)`;

  return (
    <ContentPane sx={{ height: '100%' }}>
      <Outlet />
      {noEntitiesYet ? (
        <InboxEmptyState />
      ) : (
        isParentRoute && (
          <FormProvider {...methods}>
            <Stack
              component="form"
              id="inbox-filters-form"
              onSubmit={methods.handleSubmit(applyFilters, debugInvalidForm)}
              sx={{ height: '100%', gap: 6 }}
            >
              <Stack ref={headerRef} sx={{ margin: { md: -1 }, gap: 2 }}>
                {isDesktop && <LastUpdateTimestamp />}
                <InboxHeaderWrapper />
              </Stack>
              <Box sx={{ height: dataGridContainerHeight, margin: { xs: -2, md: -3 } }}>
                <ReviewsDataGrid />
              </Box>
            </Stack>
          </FormProvider>
        )
      )}
    </ContentPane>
  );
}
