import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    width: '100%',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },
  body: {
    paddingTop: 3,
    height: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
  },
  contentPane: {
    height: '100%',
  },
};
export default styles;
