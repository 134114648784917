import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Skeleton, Stack, Typography } from '@trustyou/ui';

type NotificationDialogButtonProps = {
  title: ReactNode;
  description: ReactNode;
  onClick: () => void;
};

export const NotificationDialogButton = ({
  title,
  description,
  onClick,
}: NotificationDialogButtonProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack alignContent="center">
      <Typography variant="body2">{title}</Typography>
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="body1">
          {description ?? <Skeleton sx={{ minWidth: 120 }} />}
        </Typography>
        <Button variant="outlined" onClick={onClick}>
          <FormattedMessage id="inbox.action.edit" defaultMessage="Edit" />
        </Button>
      </Stack>
    </Stack>
  </Stack>
);
