import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  type Entity,
  FETCH_SUBSCRIPTION_ENTITIES,
  SEARCH_DEBOUNCE_TIME_MS,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useGridParams,
  useSubscriptionEntities,
  useSubscriptionEntitiesGridStore,
} from '@trustyou/shared';
import { SearchBar } from '@trustyou/ui';

import { AddCompetitors } from '../../../pages';
import { ManageNoEntities } from '../../NoData';
import EntitiesTable from '../../Organization/Entities/Common/EntitiesTable';
import { styles } from './styles';

export const SubscriptionEntities = () => {
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const { chosenSubscriptionId } = useParams();
  const intl = useIntl();
  const gridState = useSubscriptionEntitiesGridStore();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_SUBSCRIPTION_ENTITIES,
    gridState
  );

  const { data, isFetching } = useSubscriptionEntities(
    chosenSubscriptionId as string,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const onAddCompetitors = (entity: Entity) => setSelectedEntity(entity);
  const onCloseCompetitorsDialog = () => setSelectedEntity(undefined);

  if (data?.pagination?.total === 0 && !isFetching && !searchKey)
    return <ManageNoEntities subscriptionId={chosenSubscriptionId} />;

  return (
    <>
      <SearchBar
        onSearch={onSearch}
        debounceTime={SEARCH_DEBOUNCE_TIME_MS}
        id="subscription-entities"
        variant="standard"
        placeholder={intl.formatMessage(searchPlaceholders.searchEntities)}
        defaultValue={searchKey}
        sx={styles.fixedSearchBar}
      />
      <EntitiesTable
        data={data}
        isFetching={isFetching}
        paginationModel={paginationModel}
        sortModel={sortModel}
        searchKey={searchKey}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onAddCompetitors={onAddCompetitors}
        shouldRefetchSubscription
      />
      {selectedEntity?.id && (
        <AddCompetitors entityId={selectedEntity?.id} onClose={onCloseCompetitorsDialog} />
      )}
    </>
  );
};
