import type { Environment, FeatureFlag } from '@trustyou/shared';

/*
  Usage:
  - 'all': hide everywhere
  - 'none': show everywhere
  - 'local', 'integration', 'staging', 'production': hide only in the specified environment(s)
  
  If a feature flag in not needed anymore, it can be removed from this object and its usages across the codebase.
*/
export const temporaryHiddenEnvironments: Record<FeatureFlag, Environment[]> = {
  'CXP-customers-competitors': ['none'],
  'CXP-customers-import-new-entities-button': ['none'],
  'CXP-customers-survey-insights': ['none'],
  'CXP-customers-analytics-reports': ['none'],
  'CXP-customers-analytics-reports-list': ['none'],
  'CXP-customers-analytics-reports-sent': ['none'],
  'CXP-customers-review-providers-connect': ['none'],
  'CXP-customers-organization-profile': ['none'],
  'CXP-ops-manage-organizations': ['staging', 'production'],
  'CXP-poc-reviews-dialog': ['staging', 'production'],
  'CXP-customers-access-groups': ['none'],
  'CXP-customers-subscription': ['none'],
  'CXP-customers-add-entity': ['none'],
  'CXP-customers-benchmark': ['none'],
  'CXP-customers-reports': ['none'],
  'CXP-customers-inbox-reports': ['production'],
  'CXP-customers-protected-custom-attributes': ['none'],
  'CXP-customers-visit-data': ['production'],
  'CXP-customers-survey-management': ['production'],
  'CXP-customers-manage-benchmarks': ['none'],
};
