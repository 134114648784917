import { ALL, useInboxStore } from '@trustyou/shared';

import { usePredefinedViews } from './use-predefined-views';

export function useActiveFiltersCount() {
  const filters = useInboxStore((state) => state.currentView.filters ?? {});
  const { allReviewsView } = usePredefinedViews();

  const countingRules = [
    filters.timerange !== ALL,
    filters.statuses?.length !== allReviewsView.filters?.statuses?.length,
    filters.scores?.length,
    filters.sources?.length,
    filters.survey?.ids?.length,
    filters.languages?.length,
    filters.entities?.length,
    filters.categories?.length,
    filters.subcategories?.length,
    filters.answerable,
    // count each visit_data filter that has at least one value
    ...(filters.survey?.visit_data?.map((entry) => entry?.values?.some((value) => value)) ?? []),
  ];

  const activeFiltersCount = countingRules.reduce(
    (acc: number, shouldCount) => acc + (shouldCount ? 1 : 0),
    0
  );

  return activeFiltersCount;
}
