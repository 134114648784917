import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { UseAutocompleteProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Autocomplete, TextField } from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types';
import type { Option } from '../types';

export type DropdownMultipleProps = SelectQuestionProps;

export function DropdownMultiple({ field, options }: DropdownMultipleProps) {
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    field?.value ? options.filter((item) => field.value.includes(item.value)) : []
  );
  const placeholder =
    selectedOptions.length === 0
      ? intl.formatMessage({
          id: 'survey.question.dropdown.placeholder',
          defaultMessage: 'Select',
        })
      : '';

  const handleChange: UseAutocompleteProps<Option, true, false, false>['onChange'] = (
    event,
    value
  ) => {
    field?.onChange(value.map((option) => option.value));
    setSelectedOptions(value);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      fullWidth
      size="small"
      limitTags={isPhone ? 2 : 4}
      options={options}
      getOptionLabel={(option) => option.label as string}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      renderOption={(props, option) => (
        <li {...props} key={option.id ?? (option.label as string)}>
          {option.label}
        </li>
      )}
      value={selectedOptions}
      onChange={handleChange}
    />
  );
}
