import { defineMessages } from 'react-intl';

export const distributionSurveyMessages = defineMessages({
  publicLinkTitle: {
    id: 'survey.survey-detail.distribution.public-link.title',
    defaultMessage: 'Public link',
  },
  publicLinkDescription: {
    id: 'survey.survey-detail.distribution.public-link.description',
    defaultMessage: 'Copy and paste a public link to direct customers to your Survey',
  },
  publicLinkAutoReloadLabel: {
    id: 'survey.survey-detail.distribution.public-link.auto-reload.label',
    defaultMessage:
      'Reload the survey upon completion. Allows multiple respondents to respond from the same device. Useful for shared spaces such as lobbies or information points',
  },
  publicLinkButton: {
    id: 'survey.survey-detail.distribution.public-link.button',
    defaultMessage: 'Copy link',
  },
  qrCodeTitle: {
    id: 'survey.survey-detail.distribution.qr-code.title',
    defaultMessage: 'QR code',
  },
  qrCodeDescription: {
    id: 'survey.survey-detail.distribution.qr-code.description',
    defaultMessage: 'Use a QR code that can be scanned to access your Survey',
  },
  qrCodeButton: {
    id: 'survey.survey-detail.distribution.qr-code.button',
    defaultMessage: 'Download code',
  },
  mobileAppLinkTitle: {
    id: 'survey.survey-detail.distribution.mobile-app-link.title',
    defaultMessage: 'Mobile app link',
  },
  mobileAppLinkDescription: {
    id: 'survey.survey-detail.distribution.mobile-app-link.description',
    defaultMessage:
      'Copy and paste a public link to direct customers to your Survey in your mobile app',
  },
  mobileAppLinkButton: {
    id: 'survey.survey-detail.distribution.mobile-app-link.button',
    defaultMessage: 'Copy link',
  },
  wifiLinkTitle: {
    id: 'survey.survey-detail.distribution.wifi-link.title',
    defaultMessage: 'Wi-Fi link',
  },
  wifiLinkDescription: {
    id: 'survey.survey-detail.distribution.wifi-link.description',
    defaultMessage: 'Show your customers the Survey right after they connect to your Wi-Fi network',
  },
  wifiLinkButton: {
    id: 'survey.survey-detail.distribution.wifi-link.button',
    defaultMessage: 'Copy link for provider',
  },
  emailTitle: {
    id: 'survey.survey-detail.distribution.email.title',
    defaultMessage: 'Email',
  },
  emailDescription: {
    id: 'survey.survey-detail.distribution.email.description',
    defaultMessage: 'Send your customers the Survey via email',
  },
  successAlert: {
    id: 'survey.survey-detail.distribution.snackbar.success',
    defaultMessage: 'URL copied to clipboard',
  },
});
