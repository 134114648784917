import { useState } from 'react';
import { useIntl } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, Drawer, IconButton, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { DistributionChannel } from './distribution-channel';
import { useDistributionChannels } from './use-distribution-channels';

import { DISTRIBUTION_DRAWER_WIDTH_PX } from '../../../../constants/ui';
import type { SurveyByEntity } from '../../../../types/survey';
import { messages } from '../../../../utils/messages';

type DistributionProps = {
  survey: SurveyByEntity;
};

export function DistributionDrawer({ survey }: DistributionProps) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const distributionChannels = useDistributionChannels(survey);

  return (
    <>
      <Button onClick={() => setOpen(true)}>{intl.formatMessage(messages.distribution)}</Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: {
              xs: '100%',
              sm: DISTRIBUTION_DRAWER_WIDTH_PX,
            },
            padding: 3,
          },
        }}
      >
        <Stack spacing={6}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{survey.version}</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <StyledFontAwesomeIcon icon={faXmark} size="sm" />
            </IconButton>
          </Stack>
          <Stack spacing={6}>
            {distributionChannels.map((channel, index) => (
              <DistributionChannel
                key={index}
                icon={channel.icon}
                title={channel.title}
                description={channel.description}
                switchLabel={channel.switchLabel}
                isSwitchChecked={channel.isSwitchChecked}
                onSwitchChange={channel.onSwitchChange}
                buttonText={channel.buttonText}
                onButtonClick={channel.onButtonClick}
              />
            ))}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
