import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyMemberStateProps = {
  sx?: StyleUnit;
};

export const EmptyMemberState = ({ sx }: EmptyMemberStateProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={<FormattedMessage id="tyDesign.emptyState.noUsersYet" defaultMessage="No users yet" />}
    description={
      <FormattedMessage
        id="tyDesign.emptyState.noUsersDescription"
        defaultMessage="Add users by using the button on the top right, and they will show up here."
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
