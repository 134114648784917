import { type SyntheticEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Entity, commonFields, useEntity } from '@trustyou/shared';
import { Box, EntityInfoBanner, EntitySourcesView, Stack, Tab, Tabs } from '@trustyou/ui';

import { CustomAttributesValuesView } from '../CustomAttributesValuesView/CustomAttributesValuesView';
import { EntityCompetitorsView } from '../EntityCompetitorsView/EntityCompetitorsView';
import styles from './styles';

type EntityDetailViewProps = {
  entityId: string;
  presetEntity?: Entity;
  showCompetitors?: boolean;
  showEditCustomAttributes?: boolean;
  onAddCompetitors?: () => void;
};

const TABS = {
  details: <FormattedMessage {...commonFields.details} />,
  sources: <FormattedMessage {...commonFields.sources} />,
  competitors: <FormattedMessage {...commonFields.competitors} />,
};

export const EntityDetailView = ({
  entityId,
  presetEntity,
  showEditCustomAttributes = true,
  onAddCompetitors,
}: EntityDetailViewProps) => {
  const [value, setValue] = useState(0);
  const { data: actualEntity } = useEntity(entityId);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const entity = actualEntity || presetEntity;

  if (!entity) {
    // TODO: Display loader.
    return null;
  }

  return (
    <Box sx={styles.container}>
      <Tabs sx={styles.barContainer} value={value} onChange={handleChange}>
        {Object.entries(TABS).map(([id, message]) => (
          <Tab data-testid={`entity-detail-tab-${message}`} key={id} label={message} />
        ))}
      </Tabs>
      <Box sx={styles.body}>
        {value === 0 && (
          <Stack gap={3}>
            <EntityInfoBanner entity={entity} />
            <CustomAttributesValuesView entity={entity} allowEdit={showEditCustomAttributes} />
          </Stack>
        )}
        {value === 1 && <EntitySourcesView sourcesUrls={entity.sources_urls || []} />}
        {value === 2 && (
          <EntityCompetitorsView entity={entity} onAddCompetitors={onAddCompetitors} />
        )}
      </Box>
    </Box>
  );
};
