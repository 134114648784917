import { FormattedMessage, useIntl } from 'react-intl';

import { type Subscription, absentValues, commonFields, roleDefinitions } from '@trustyou/shared';
import { Stack } from '@trustyou/ui';

import ContentCard from './ContentCard';
import TextBlock from './TextBlock';

import { styles } from './styles';

type SubscriptionInfoPanel = { subscription: Subscription };

export const SubscriptionInfoPanel = ({ subscription }: SubscriptionInfoPanel) => {
  const intl = useIntl();

  return (
    <Stack sx={styles.subscriptionInfoPanel}>
      <ContentCard title={subscription.plan_name} status={subscription.status}>
        <TextBlock
          primary={
            subscription.owner
              ? `${subscription.owner.name} (${subscription.owner.email})`
              : intl.formatMessage(absentValues.notAvailable)
          }
          secondary={<FormattedMessage {...roleDefinitions.owner} />}
        />
      </ContentCard>
      <ContentCard
        title={
          <FormattedMessage id="settings.subscription.plan-usage" defaultMessage="Plan usage" />
        }
      >
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <TextBlock
            primary={`${subscription.number_of_consumed_entities}/${subscription.number_of_allowed_entities}`}
            secondary={<FormattedMessage {...commonFields.entity} />}
          />

          <TextBlock
            primary={`${subscription.number_of_consumed_members}/${subscription.number_of_allowed_members}`}
            secondary={<FormattedMessage {...commonFields.users} />}
          />
        </Stack>
      </ContentCard>
    </Stack>
  );
};
