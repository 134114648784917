import { useState } from 'react';

import { Asterisk, Checkbox, FormControlLabel, FormGroup, Typography } from '@trustyou/ui';
import DOMPurify from 'dompurify';

import type { BooleanQuestionProps } from '../question-types/boolean/boolean-question';
import { makeLinksOpenInNewTab } from '../utils';

export type CheckboxQuestionProps = BooleanQuestionProps;

export const CheckboxSingle = ({ field, options, isMandatory }: CheckboxQuestionProps) => {
  const [checked, setChecked] = useState(field?.value ?? false);
  const uniqueOption = options[0];
  const label = uniqueOption?.label;
  const sanitizedHtml = DOMPurify.sanitize(label as string | Node);
  const modifiedHtml = makeLinksOpenInNewTab(sanitizedHtml);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field?.onChange(event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <>
            <Typography
              variant="body2"
              component="span"
              dangerouslySetInnerHTML={{ __html: modifiedHtml }}
            />
            {isMandatory && <Asterisk />}
          </>
        }
      />
    </FormGroup>
  );
};
