import { FormattedMessage } from 'react-intl';

import { actionMessages } from '@trustyou/shared';
import { InfoDialog, Typography } from '@trustyou/ui';

import styles from './styles';

type Props = {
  onCancel: () => void;
  onSave: () => void;
};

export const ConfirmationDialog = ({ onCancel, onSave }: Props) => (
  <InfoDialog
    title={
      <FormattedMessage
        id="organization.segments.dashboard-segments.update-confirmation-dialog.title"
        defaultMessage="Change for everyone?"
      />
    }
    open
    primaryActionText={<FormattedMessage {...actionMessages.confirmUpdate} />}
    secondaryActionText={<FormattedMessage {...actionMessages.cancel} />}
    onPrimaryAction={onSave}
    onSecondaryAction={onCancel}
    dataName="dashboard-segments-update-confirmation"
    maxWidth="xs"
  >
    <Typography variant="body2" sx={styles.confirmationDialog}>
      <FormattedMessage
        id="organization.segments.dashboard-segments.update-confirmation-dialog.content"
        defaultMessage="You are about to update the dashboard segments for everyone in your organization.  Do you want to proceed?"
      />
    </Typography>
  </InfoDialog>
);
