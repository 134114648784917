import { FormattedMessage } from 'react-intl';

import type { GridInitialState } from '@mui/x-data-grid-pro';
import type { DataGridPropsWithDefaultValues } from '@mui/x-data-grid-pro/internals';
import { z } from 'zod';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const ALL = 'all';

type TimeUnit = 'y' | 'Q' | 'M' | 'w' | 'd' | 'h' | 'm' | 's';
export type RelativeTimerangeValue = `${number}${TimeUnit}`;
export type RelativeTimerange = { relative_timerange: `${number}${TimeUnit}` };
type PresetTimeRule =
  `now${'' | `-${RelativeTimerangeValue}` | `/${TimeUnit}` | `-${RelativeTimerangeValue}/${TimeUnit}`}`;
export type PresetTimerange = { preset_timerange: { start: PresetTimeRule; end: PresetTimeRule } };
export type CustomTimerange = { timerange: { start: number; end: number } };
export type FormattedCustomTimeRange = `${string} - ${string}`;
export const timeranges = [
  ALL,
  'yesterday',
  'last-7-days',
  'last-week',
  'month-to-date',
  'last-full-month',
  'last-24-months',
  'quarter-to-date',
  'last-full-quarter',
  'year-to-date',
  'year-to-last-month',
  'last-full-year',
] as const;
export type TimerangeKey = (typeof timeranges)[number];
export type TimerangeValue = undefined | PresetTimerange | RelativeTimerange | CustomTimerange;
// TODO: Suggest to backend unifying the logic for the cumbersome time range filter: `timerange` vs `relative_timerange` vs `preset_timerange`: https://app-api.cxp-integration.trustyou.com/api/inbox/docs#/Review/filter_reviews_api_inbox__organization_id__reviews_filter_post
export const TIMERANGE_MAPPING: Record<TimerangeKey, TimerangeValue> = {
  [ALL]: undefined,
  yesterday: { preset_timerange: { start: 'now-1d/d', end: 'now/d' } },
  'last-7-days': { relative_timerange: '7d' },
  'last-week': { preset_timerange: { start: 'now-1w/w', end: 'now/w' } },
  'month-to-date': { preset_timerange: { start: 'now/M', end: 'now' } },
  'last-full-month': { preset_timerange: { start: 'now-1M/M', end: 'now/M' } },
  'last-24-months': { relative_timerange: '24M' },
  'quarter-to-date': { preset_timerange: { start: 'now/Q', end: 'now' } },
  'last-full-quarter': { preset_timerange: { start: 'now-1Q/Q', end: 'now/Q' } },
  'year-to-date': { preset_timerange: { start: 'now/y', end: 'now' } },
  'year-to-last-month': { preset_timerange: { start: 'now/y', end: 'now/M' } },
  'last-full-year': { preset_timerange: { start: 'now-1y/y', end: 'now/y' } },
  // '01.01.2024 - 31.12.2024': { timerange: { start: 1704063600, end: 1735685999 } },
};

export const statuses = [
  'read',
  'unread',
  'confirmed',
  'responded',
  'deleted',
  'marked_as_deleted',
  'inappropriate',
  'marked_as_inappropriate',
] as const;

export const scores = ['low', 'medium', 'high', 'no_score'] as const;

export const filtersSchema = z.object({
  viewname: z.string().optional(),
  keywords: z.array(z.string()).optional(),
  timerange: z.string().optional(),
  statuses: z.array(z.enum(statuses)).optional(),
  scores: z.array(z.enum(scores)).optional(),
  sources: z.array(z.string()).optional(),
  // surveys is parent of visit and ids
  survey: z
    .object({
      visit_data: z
        .array(
          z
            .object({
              key: z.string(),
              type: z.string(),
              values: z.array(z.string().optional()),
            })
            .optional()
        )
        .optional(),
      ids: z.array(z.string()).optional(),
    })
    .optional(),
  languages: z.array(z.string()).optional(),
  answerable: z.boolean().optional(),
  entities: z.array(z.string()).optional(),
  categories: z.array(z.string()).optional(),
  subcategories: z.array(z.string()).optional(),
});

export type Filters = z.infer<typeof filtersSchema>;
export type FiltersField = keyof Filters;

export type View = {
  label: string;
  filters?: Filters;
};

export type InboxState = {
  dataGrid: GridInitialState;
  setDataGrid: (state: GridInitialState) => void;

  currentView: View;
  setCurrentView: (view: View) => void;
};

export const ALL_REVIEWS_FILTERS: Filters = {
  timerange: 'all',
  statuses: [
    'unread',
    'read',
    'responded',
    'confirmed',
    'deleted',
    'marked_as_deleted',
    'inappropriate',
    'marked_as_inappropriate',
  ],
};

export const LAST_SEVEN_DAYS_FILTERS: Filters = {
  timerange: 'last-7-days',
  statuses: ['unread', 'read', 'responded', 'confirmed'],
};

export const EXAMPLE_FILTERS: Filters = {
  timerange: 'last-7-days',
  statuses: ['unread', 'read'],
};

export const DEFAULT_INBOX_PAGE_SIZE_OPTIONS: DataGridPropsWithDefaultValues['pageSizeOptions'] = [
  25, 50, 100,
];

export const INBOX_DATA_GRID_INITIAL_STATE: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
  pagination: {
    paginationModel: {
      pageSize: 50,
      page: 0,
    },
  },
  sorting: {
    sortModel: [
      {
        field: 'date',
        sort: 'desc',
      },
    ],
  },
};

export const useInboxStore = create<InboxState>()(
  persist(
    (set, get) => ({
      dataGrid: INBOX_DATA_GRID_INITIAL_STATE,
      setDataGrid: (dataGrid) => set({ dataGrid }),

      currentView: {
        // @ts-expect-error Type 'Element' is not assignable to type 'string'.
        label: <FormattedMessage id="inbox.view.last-seven-days" defaultMessage="Last 7 days" />,
        filters: LAST_SEVEN_DAYS_FILTERS,
      },
      setCurrentView: (view) => set({ currentView: view }),
    }),
    {
      name: 'inbox-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      // prevent storing the setter functions
      partialize: (state) => ({
        dataGrid: {
          columns: state.dataGrid.columns,
          pinnedColumns: state.dataGrid.pinnedColumns,
          pagination: state.dataGrid.pagination,
          sorting: state.dataGrid.sorting,
        },
        currentView: state.currentView,
      }),
    }
  )
);
