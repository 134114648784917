import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  card: {
    width: '100%',
    minHeight: '124px',
  },
  cardTitle: { fontWeight: 500 },
  cardBody: { paddingTop: 0 },
  textBlock: { width: '100%' },
  nameField: {
    minWidth: '396px',
  },
  searchBar: {
    marginLeft: 'auto',
    marginTop: 2,
    marginBottom: 1,
    '& .MuiFormControl-root': {
      width: '216px',
    },
  },
  fixedSearchBar: {
    marginLeft: 'auto',
    marginTop: 2,
    height: 0,
    '& .MuiFormControl-root': {
      width: '216px',
      zIndex: 1,
    },
  },
  subscriptionInfoPanel: {
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: 3,
  },
  tableContainerStyle: {
    flex: 1,
    overflowY: 'auto',
  },
};
