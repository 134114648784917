import { useState } from 'react';

import { ContentPane } from '@trustyou/ui';

import { CustomAttributesTable } from './CustomAttributesTable';
import { Header } from './Header';

import { CustomAttributeManageDrawer } from '../../../components';
import { styles } from './styles';

export const CustomAttributes = () => {
  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>();

  return (
    <ContentPane
      sx={styles.pageContainer}
      bodyStyles={styles.pageContainerBody}
      headers={[<Header />]}
    >
      <CustomAttributesTable
        onRowSelect={setSelected}
        onCreate={() => setIsCreateDrawerOpen(true)}
      />
      {(isCreateDrawerOpen || selected) && (
        <CustomAttributeManageDrawer
          id={selected}
          onClose={() => {
            setIsCreateDrawerOpen(false);
            setSelected(undefined);
          }}
        />
      )}
    </ContentPane>
  );
};
