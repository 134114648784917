import { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGE_SIZE,
  getPaginationRequest,
  useDataGridIntl,
  useLanguageStore,
} from '@trustyou/shared';
import { DataGridPro } from '@trustyou/ui';

import { useColumns } from './use-columns';

import type { DistributionChannelsEnum } from '../../../../client';
import { useQuestionnairesByEntity } from '../../../../service/hooks/use-questionnaires';
import { CustomToolbarWithSearch } from '../../../shared/custom-toolbar-with-search';
import styles from '../../../shared/styles';

export function DataGrid({ channels = [] }: { channels: DistributionChannelsEnum[] }) {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const { surveyId = '' } = useParams();

  const [searchTerms, setSearchTerms] = useState('');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data, isLoading } = useQuestionnairesByEntity({
    surveyId,
    pagination: getPaginationRequest(paginationModel),
    searchTerms,
  });

  const columns = useColumns();
  const rows = data?.data ? data?.data.map((data) => ({ ...data, channels })) : [];

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (!isLoading) {
      setPaginationModel(newModel);
    }
  };

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      rowCount={data?.count ?? 0}
      autoHeight
      loading={isLoading}
      pagination
      paginationMode="server"
      pageSizeOptions={[15, 50, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      ignoreDiacritics
      disableRowSelectionOnClick
      slots={{
        toolbar: () => CustomToolbarWithSearch({ searchTerms, setSearchTerms }),
      }}
      sx={styles.disableCellOutline}
      density="standard"
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
