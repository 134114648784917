import { FormattedMessage } from 'react-intl';

import { useZendeskLinks } from '@trustyou/shared';
import { Link, Typography } from '@trustyou/ui';

export function StyledSupportLink() {
  const { getNewRequestLink } = useZendeskLinks();

  return (
    <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
      <Link href={getNewRequestLink()} target="_blank" underline="hover">
        <FormattedMessage id="tyDesign.contactSupport" defaultMessage="contact support" />
      </Link>
    </Typography>
  );
}
