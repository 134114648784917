import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  faEnvelope,
  faLink,
  faMobileScreen,
  faMobileSignal,
  faQrcode,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SEARCH_PARAMS } from '@trustyou/shared';
import { snackbar } from '@trustyou/ui';
import QRCode from 'qrcode';

import type { DistributionChannelProps } from './distribution-channel';
import { distributionSurveyMessages } from './messages';

import type { DistributionChannelsEnum } from '../../../../client';
import type { SurveyByEntity } from '../../../../types/survey';

export function useDistributionChannels(survey: SurveyByEntity) {
  const intl = useIntl();
  const channelOrder: DistributionChannelsEnum[] = ['link', 'qr_code', 'app', 'wifi', 'email'];

  const [isAutoReloadEnabled, setIsAutoReloadEnabled] = useState(false);

  const getUrlWithSource = (source: DistributionChannelsEnum) => {
    const url = new URL(survey.url);
    url.searchParams.set(SEARCH_PARAMS.source, source);
    return url.toString();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    snackbar.success(intl.formatMessage(distributionSurveyMessages.successAlert));
  };

  const generateAndDownloadQRCode = async (url: string) => {
    try {
      const dataUrl = await QRCode.toDataURL(url, { width: 256 });
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = `Survey QR - ${survey.entity}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
    }
  };

  const channels: Record<DistributionChannelsEnum, DistributionChannelProps> = {
    link: {
      icon: faLink,
      title: intl.formatMessage(distributionSurveyMessages.publicLinkTitle),
      description: intl.formatMessage(distributionSurveyMessages.publicLinkDescription),
      switchLabel: intl.formatMessage(distributionSurveyMessages.publicLinkAutoReloadLabel),
      isSwitchChecked: isAutoReloadEnabled,
      onSwitchChange: () => setIsAutoReloadEnabled(!isAutoReloadEnabled),
      buttonText: intl.formatMessage(distributionSurveyMessages.publicLinkButton),
      onButtonClick: () => {
        const url = new URL(survey.url);
        if (isAutoReloadEnabled) {
          url.searchParams.set(SEARCH_PARAMS.reload, 'true');
        }
        copyToClipboard(url.toString());
      },
    },
    qr_code: {
      icon: faQrcode,
      title: intl.formatMessage(distributionSurveyMessages.qrCodeTitle),
      description: intl.formatMessage(distributionSurveyMessages.qrCodeDescription),
      buttonText: intl.formatMessage(distributionSurveyMessages.qrCodeButton),
      onButtonClick: () => generateAndDownloadQRCode(getUrlWithSource('qr_code')),
    },
    app: {
      icon: faMobileScreen,
      title: intl.formatMessage(distributionSurveyMessages.mobileAppLinkTitle),
      description: intl.formatMessage(distributionSurveyMessages.mobileAppLinkDescription),
      buttonText: intl.formatMessage(distributionSurveyMessages.mobileAppLinkButton),
      onButtonClick: () => copyToClipboard(getUrlWithSource('app')),
    },
    wifi: {
      icon: faMobileSignal,
      title: intl.formatMessage(distributionSurveyMessages.wifiLinkTitle),
      description: intl.formatMessage(distributionSurveyMessages.wifiLinkDescription),
      buttonText: intl.formatMessage(distributionSurveyMessages.wifiLinkButton),
      onButtonClick: () => copyToClipboard(getUrlWithSource('wifi')),
    },
    email: {
      icon: faEnvelope,
      title: intl.formatMessage(distributionSurveyMessages.emailTitle),
      description: intl.formatMessage(distributionSurveyMessages.emailDescription),
    },
  };

  const sortedChannels = (survey.channels || [])
    .sort((a, b) => channelOrder.indexOf(a) - channelOrder.indexOf(b))
    .map((channel) => channels[channel]);

  return sortedChannels;
}
