import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  pageContainer: {
    flex: 1,
  },
  pageContainerBody: {
    height: 'calc(100% - 105px)',
  },
  emptyStateContainer: { marginBottom: 2 },
  emptyStateHeader: { marginTop: 3 },
  emptyStateAction: { marginTop: 3 },
  createButton: { marginLeft: 'auto' },
  tableContainerStyle: { height: 'calc(100% - 36px)' },
};
