import { useIntl } from 'react-intl';

import type { GridCellParams } from '@mui/x-data-grid-pro';
import { useCustomAttributeDefinitions } from '@trustyou/shared';
import { Button, CustomCell, DataGrid } from '@trustyou/ui';

import { EmptyState } from './EmptyState';

import { CustomAttributeTypeLabel } from '../../../components';
import { common, manageDrawer } from '../../../constants/messages/customAttributes';
import { styles } from './styles';

type Props = {
  onRowSelect: (id: string) => void;
  onCreate: () => void;
};

export const CustomAttributesTable = ({ onRowSelect, onCreate }: Props) => {
  const intl = useIntl();

  const columns = [
    {
      field: 'name',
      headerName: intl.formatMessage(common.name),
      flex: 1,
    },
    {
      field: 'type',
      headerName: intl.formatMessage(common.type),
      renderCell: ({ row }: GridCellParams) => <CustomAttributeTypeLabel type={row.type} />,
      flex: 1,
    },
    {
      field: 'description',
      headerName: intl.formatMessage(common.description),
      renderCell: ({ row }: GridCellParams) => (
        <CustomCell text={row.description} variant="de-emphasized" />
      ),
      flex: 1,
    },
  ];

  const { data, isFetching } = useCustomAttributeDefinitions();

  if (data && data?.length === 0) return <EmptyState onCreate={onCreate} />;

  return (
    <>
      <Button
        data-testid="create-new-custom-attribute-definition"
        variant="outlined"
        sx={styles.createButton}
        onClick={onCreate}
      >
        {intl.formatMessage(manageDrawer.createButton)}
      </Button>
      <DataGrid
        autoHeight={false}
        sortingMode="client"
        paginationMode="client"
        columns={columns}
        rows={data || []}
        loading={isFetching}
        disableColumnMenu={false}
        enableToolbarColumnsButton
        onRowClick={(args) => {
          onRowSelect(args.row.id);
        }}
        containerStyle={styles.tableContainerStyle}
      />
    </>
  );
};
