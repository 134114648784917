import { BaseDashboard } from '@trustyou/analytics';
import { useDashboardId, useSurveys } from '@trustyou/shared';

import { EmptyState } from './EmptyState';

export const SurveyStatsDashboard = () => {
  const { data: dashboardIdResponse } = useDashboardId();
  const { data, isFetched: isSurveysLoaded } = useSurveys({ offset: 0, limit: 1 });
  if (isSurveysLoaded && !data?.count) return <EmptyState />;
  if (!dashboardIdResponse?.survey_stats_id) return null;
  return <BaseDashboard dashboardId={dashboardIdResponse?.survey_stats_id} />;
};
