import { faCircleExclamation } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { StyledFontAwesomeIcon, Tooltip, Typography } from '@trustyou/ui';

type EntitiesCompetitorsCountCellProps = {
  count: number;
  message?: string;
};

export const EntitiesCompetitorsCountCell = ({
  count,
  message,
}: EntitiesCompetitorsCountCellProps) => {
  if (count) {
    return <Typography variant="body2">{count}</Typography>;
  }
  return (
    <Tooltip placement="top" arrow title={message}>
      <StyledFontAwesomeIcon
        fontSize="20px"
        sx={{ color: 'error.main' }}
        icon={faCircleExclamation}
      />
    </Tooltip>
  );
};
