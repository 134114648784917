import { useEffect } from 'react';

import {
  SISENSE_SERVER_PATH,
  useManageBenchmarksPermission,
  useRouteUtils,
} from '@trustyou/shared';

const ADD_REQUEST_KEY = 'add-benchmarks';
const CHECK_PERMISSION_REQUEST_KEY = 'check-benchmark-add-permission';
const CHECK_PERMISSION_RESPOND_KEY = 'has-benchmark-add-permission';

export const useBenchmarkAddButton = (iFrameRef: React.RefObject<HTMLIFrameElement>) => {
  const isManageBenchmarksAllowed = useManageBenchmarksPermission();
  const { navigateWithBase } = useRouteUtils();

  const messageEventHandler = (event: MessageEvent) => {
    if (event.origin !== SISENSE_SERVER_PATH || typeof event.data !== 'string') return;
    if (event.data.includes(CHECK_PERMISSION_REQUEST_KEY) && isManageBenchmarksAllowed) {
      iFrameRef.current?.contentWindow?.postMessage(
        CHECK_PERMISSION_RESPOND_KEY,
        SISENSE_SERVER_PATH as string
      );
    }
    if (event.data.includes(ADD_REQUEST_KEY)) {
      navigateWithBase('benchmarks', {
        state: { createBenchmark: true },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
