import { useMutation } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { CreateDatapointSchema, Datapoint } from '../../client';
import {
  CREATE_VISIT_DATAPOINT,
  DELETE_VISIT_DATAPOINT,
  UPDATE_VISIT_DATAPOINT,
} from '../constants/query-keys';

export async function createVisitDatapoint(body: CreateDatapointSchema): Promise<Datapoint> {
  const { data } = await $assApi.post<Datapoint>(`${SURVEY_BASE_PATH}/datapoint/create`, body);
  return data;
}

export async function updateVisitDatapoint(
  body: CreateDatapointSchema,
  datapoint_id?: string
): Promise<Datapoint> {
  const { data } = await $assApi.patch<Datapoint>(
    `${SURVEY_BASE_PATH}/datapoint/update/${datapoint_id}`,
    body
  );
  return data;
}

export async function deleteVisitDatapoint(datapoint_id: string): Promise<Datapoint> {
  const { data } = await $assApi.delete<Datapoint>(
    `${SURVEY_BASE_PATH}/datapoint/delete/${datapoint_id}`
  );
  return data;
}

export const useCreateVisitDatapoint = () => {
  return useMutation({
    mutationFn: (form: CreateDatapointSchema) => createVisitDatapoint(form),
    mutationKey: [CREATE_VISIT_DATAPOINT],
  });
};

export const useUpdateVisitDatapoint = () => {
  return useMutation({
    mutationFn: ({ form, datapoint_id }: { form: CreateDatapointSchema; datapoint_id?: string }) =>
      updateVisitDatapoint(form, datapoint_id),
    mutationKey: [UPDATE_VISIT_DATAPOINT],
  });
};

export const useDeleteVisitDatapoint = () => {
  return useMutation({
    mutationFn: (datapoint_id: string) => deleteVisitDatapoint(datapoint_id),
    mutationKey: [DELETE_VISIT_DATAPOINT],
  });
};
