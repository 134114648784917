import { useQuery } from '@tanstack/react-query';

import {
  fetchMetaCategory,
  fetchSemaLanguages,
  fetchSubCategories as fetchSubcategories,
  fetchSubcategoryMapping,
  fetchTopLevelCategories,
  fetchTopLevelCategoryMapping,
} from './fetchers';

import type { SemaLanguage } from '../../../client';
import {
  METACATEGORY,
  SEMA_LANGUAGES,
  SUBCATEGORIES,
  SUBCATEGORY_MAPPING,
  TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORY_MAPPING,
} from '../../../constants/query-keys';

export function useFetchMetaCategory(shortLocale: string) {
  return useQuery({
    queryKey: [METACATEGORY, { shortLocale }],
    queryFn: () => fetchMetaCategory(shortLocale),
    refetchOnWindowFocus: false,
  });
}

export function useFetchTopLevelCategoryMapping() {
  return useQuery({
    queryKey: [TOP_LEVEL_CATEGORY_MAPPING],
    queryFn: fetchTopLevelCategoryMapping,
    refetchOnWindowFocus: false,
  });
}

export function useFetchSubcategoryMapping() {
  return useQuery({
    queryKey: [SUBCATEGORY_MAPPING],
    queryFn: fetchSubcategoryMapping,
    refetchOnWindowFocus: false,
  });
}

export function useFetchTopLevelCategories(semaLanguage: SemaLanguage) {
  return useQuery({
    queryKey: [TOP_LEVEL_CATEGORIES, semaLanguage],
    queryFn: () => fetchTopLevelCategories(semaLanguage),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useFetchSubcategories(semaLanguage: SemaLanguage) {
  return useQuery({
    queryKey: [SUBCATEGORIES, semaLanguage],
    queryFn: () => fetchSubcategories(semaLanguage),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useFetchSemaLanguages(semaLanguage: SemaLanguage) {
  return useQuery({
    queryKey: [SEMA_LANGUAGES, semaLanguage],
    queryFn: () => fetchSemaLanguages(semaLanguage),
    refetchOnWindowFocus: false,
    retry: false,
  });
}
