import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FiltersField, type SurveyWithCount, useInfiniteSurveys } from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';
import { uniqBy } from 'lodash';

import { useDialogState } from '../../../hooks/use-dialog-state';
import { SelectorModal } from '../../selector-modal';
import { SurveyDialogOpenButton } from './survey-dialog-open-button';

type SurveyListItem = Pick<SurveyWithCount, 'id' | 'name'>;

interface SurveyFilterProps {
  fieldName: FiltersField;
  isNotificationButton?: boolean;
}

export function SurveyFilter({ fieldName, isNotificationButton }: SurveyFilterProps) {
  const intl = useIntl();
  const { infiniteSurveysQuery, setSearchKey, surveys, total } = useInfiniteSurveys();
  const { setValue, getValues } = useFormContext();

  const [selectedSurveys, setSelectedSurveys] = useState<SurveyListItem[]>([]);
  const { isDialogOpen, openDialog, closeDialog } = useDialogState();

  useEffect(() => {
    if (!surveys || surveys.length === 0) return;

    setSelectedSurveys((prev) => {
      const selectedSurveyIds = getValues(fieldName) || [];
      const newSurveys = surveys.filter(({ id }) => selectedSurveyIds.includes(id));
      // get unique surveys by id and merge the previous and new surveys
      // otherwise searching leads to same selected survey being added multiple times
      return Array.from(uniqBy([...prev, ...newSurveys], 'id'));
    });
  }, [getValues, surveys, fieldName]);

  const saveSurveys = () => {
    setValue(
      fieldName,
      selectedSurveys?.map(({ id }) => id),
      { shouldDirty: true }
    );
    onCloseDialog();
  };

  const cancel = () => {
    setSelectedSurveys(surveys.filter(({ id }) => getValues(fieldName)?.includes(id)) || []);
    onCloseDialog();
  };

  const onCloseDialog = () => {
    closeDialog();
    setSearchKey('');
  };

  const renderRowContent = (survey: SurveyListItem) => <ListItemText primary={survey.name} />;

  const onSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleFetchNextPage = () => {
    if (infiniteSurveysQuery.hasNextPage) {
      infiniteSurveysQuery.fetchNextPage();
    }
  };

  return (
    <>
      <SurveyDialogOpenButton
        isNotificationButton={isNotificationButton}
        total={total}
        selected={selectedSurveys.length}
        openDialog={openDialog}
      />

      <SelectorModal
        isOpen={isDialogOpen}
        items={surveys}
        selectedItems={selectedSurveys}
        setSelectedItems={setSelectedSurveys}
        onFetchNextPage={handleFetchNextPage}
        isLoading={
          infiniteSurveysQuery.isFetching ||
          infiniteSurveysQuery.isFetchingNextPage ||
          infiniteSurveysQuery.isLoading
        }
        onClose={cancel}
        onSave={saveSurveys}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.surveys',
          defaultMessage: 'Search surveys',
        })}
        onSearch={onSearch}
        title={<FormattedMessage id="inbox.filter.surveys.modal.title" defaultMessage="Surveys" />}
        allTab={
          <FormattedMessage
            id="inbox.filter.surveys.modal.button.all"
            defaultMessage="All surveys"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.surveys.modal.button.select"
            defaultMessage="Select surveys"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.surveys.modal.selected-count"
            defaultMessage="Selected surveys ({count})"
            values={{ count: selectedSurveys.length }}
          />
        }
        alertDescription={
          <FormattedMessage
            id="inbox.filter.surveys.modal.alert"
            defaultMessage="Please select at least one survey"
          />
        }
      />
    </>
  );
}
