import { useEffect } from 'react';

import type { QuestionProps } from './question';

export function useResetState({
  field,
  onReset,
}: {
  field: QuestionProps['field'];
  onReset: () => void;
}) {
  useEffect(() => {
    if (field?.value === null) {
      onReset();
    }
  }, [field?.value, onReset]);
}
