import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ALL, type FiltersField } from '@trustyou/shared';
import { Divider, FormControl, InputLabel, ListItemText, Radio } from '@trustyou/ui';

import type { Group } from '../../types';
import { formatFieldSlug } from '../../utils/mappers';
import { StyledListSubheader } from './styled-list-subheader';
import { StyledMenuItem } from './styled-menu-item';
import { StyledOutlinedInput } from './styled-outlined-input';
import { StyledSelect } from './styled-select';

export type DropdownRadioProps = {
  fieldName: FiltersField;
  label: string;
  groups: Group[];
  initialValue: string;
  customButton?: ReactNode;
};

export function DropdownRadio({
  fieldName,
  label,
  groups,
  initialValue,
  customButton,
}: DropdownRadioProps) {
  const { control, setValue } = useFormContext();

  const labelId = `dropdown-radio-label-${formatFieldSlug(fieldName)}`;
  const options = groups.flatMap((group) => group.options);
  const optionsValues = options.map((option) => option.value);
  const allOptionValue = optionsValues.find((value) => value === ALL) as string;

  const clearSelection = () => {
    setValue(fieldName, allOptionValue);
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange } }) => (
        <FormControl>
          <InputLabel id={labelId} shrink>
            {label}
          </InputLabel>
          <StyledSelect
            labelId={labelId}
            value={value}
            onChange={onChange}
            renderValue={(selected) => {
              const selectedOption = options.find((option) => option.value === selected);
              if (!selectedOption) {
                return selected;
              }
              return selectedOption?.label;
            }}
            input={
              <StyledOutlinedInput
                label={label}
                isAllSelected={value === allOptionValue}
                onClear={clearSelection}
              />
            }
          >
            {groups.map((group) => [
              group.label ? (
                <StyledListSubheader key={`subheader-${group.label}`}>
                  {group.label}
                </StyledListSubheader>
              ) : null,
              group.options.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  <Radio checked={option.value === value} size="small" disableRipple />
                  <ListItemText
                    primary={option.label}
                    sx={{ marginInlineStart: 0.5, whiteSpace: 'break-spaces' }}
                  />
                </StyledMenuItem>
              )),
            ])}
            {customButton && <Divider />}
            {customButton}
          </StyledSelect>
        </FormControl>
      )}
    />
  );
}
