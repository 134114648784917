import { faLocationPin, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { Entity } from '@trustyou/shared';
import {
  Box,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  SectorSpotIcon,
  Typography,
} from '@trustyou/ui';

import styles from './styles';

export type CompetitorCardProps = {
  competitor: Entity;
  onDelete?: (competitor: Entity) => void;
};

export const CompetitorCard = ({ competitor, onDelete }: CompetitorCardProps) => (
  <Box data-testid="entity-competitor-card" key={competitor.id} sx={styles.container}>
    <SectorSpotIcon sector={competitor.sector} sx={styles.spotIcon} />
    <Box sx={styles.textColumn}>
      <Typography variant="subtitle2">{competitor.name}</Typography>
      <Typography variant="caption" sx={styles.addressRow}>
        <Icon size="lg" icon={faLocationPin} />
        {competitor.city}, {competitor.country_name}
      </Typography>
    </Box>
    {onDelete && (
      <IconButton
        data-testid="delete-competitor"
        size="small"
        sx={styles.deleteButton}
        onClick={() => onDelete(competitor)}
      >
        <Icon icon={faTrash} />
      </IconButton>
    )}
  </Box>
);
