import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  invitationSent: {
    id: 'organization.users.invitationSentToEmail',
    defaultMessage: 'Invitation sent to {email}',
  },
  invitationCreated: {
    id: 'organization.users.invitation-created',
    defaultMessage: 'Invitation created for {email}. Invitation email was not sent',
  },
});

export const advancedScopeMessages = defineMessages({
  rulesTitle: {
    id: 'users.scopes.advanced-selection.rules.title',
    defaultMessage: 'Rules',
  },
  rulesSubtitle: {
    id: 'users.scopes.advanced-selection.rules.subtitle',
    defaultMessage: 'All conditions must be met for an entity to be selected',
  },
  rulesSelectPlaceholder: {
    id: 'users.scopes.advanced-selection.rules.select.placeholder',
    defaultMessage: 'Select',
  },
  rulesSelectLoading: {
    id: 'users.scopes.advanced-selection.rules.select.loading',
    defaultMessage: 'Loading...',
  },
  rulesSelectNoValues: {
    id: 'users.scopes.advanced-selection.rules.select.no-values',
    defaultMessage: 'No Values',
  },
  rulesIs: {
    id: 'users.scopes.advanced-selection.rules.is',
    defaultMessage: 'is one of',
  },
  rulesAdd: {
    id: 'users.scopes.advanced-selection.rules.add',
    defaultMessage: 'Add condition',
  },
  additionalEntitiesSelectButton: {
    id: 'users.scopes.advanced-selection.additional-entities.select-button',
    defaultMessage: 'Select entities',
  },
  additionalEntitiesHeader: {
    id: 'users.scopes.advanced-selection.additional-entities.header',
    defaultMessage: 'Additional entities',
  },
  additionalEntitiesCount: {
    id: 'users.scopes.advanced-selection.additional-entities.count',
    defaultMessage: '{count} Added',
  },

  additionalEntitiesModalHeader: {
    id: 'users.scopes.advanced-selection.additional-entities.modal.header',
    defaultMessage: 'Manually select entities',
  },
  additionalEntitiesModalSubHeader: {
    id: 'users.scopes.advanced-selection.additional-entities.modal.sub-header',
    defaultMessage: 'Choose up to {count} additional entities',
  },
  additionalEntitiesModalSelectedCount: {
    id: 'users.scopes.advanced-selection.additional-entities.modal.selected-count',
    defaultMessage: 'Selected entities ({count})',
  },
  error: {
    id: 'users.scopes.advanced-selection.error',
    defaultMessage: 'Please define at least one condition.',
  },
  errorWithSelectedEntities: {
    id: 'users.scopes.advanced-selection.error-with-selected-entities',
    defaultMessage:
      "Please define at least one condition. If you'd like to select specific entities manually, you can do so using the “by entities” option above.",
  },
});
