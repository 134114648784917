import type { CSSSelectorObjectOrCssVariables, Theme } from '@mui/system';
import '@tanstack/react-query';
import type { AxiosError } from 'axios';

// https://tanstack.com/query/v5/docs/framework/react/typescript#registering-a-global-error
declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError;
  }
}
export type StyleUnit = CSSSelectorObjectOrCssVariables<Theme>;

export interface IStyles {
  [key: string]: StyleUnit;
}

export type PaginationOptions = {
  page: number;
  rowsPerPage: number;
  total: number;
};

export enum DOMAIN {
  ORG_CENTER = 'org_center',
  ANALYTICS = 'analytics',
  INBOX = 'inbox',
  SURVEY = 'survey',
}

export type Permissions = { [key in DOMAIN]?: string[] };

export type RoutePermissions = {
  [key: string]: string[];
};

export interface RedirectOptions {
  silent?: boolean;
  isNewTab?: boolean;
}

export interface IFrameMessagePayload {
  [key: string]: string;
}

export interface IFrameMessage {
  type: string;
  path?: string;
  payload: IFrameMessagePayload;
}

export interface FormValidationError {
  code?: string;
  field?: string;
  message?: string;
}

export interface FormError {
  code?: string;
  message?: string;
  validation_errors?: FormValidationError[];
}

export interface FormErrorResponseData {
  error: FormError;
}

export type Environment =
  | 'local'
  | 'integration'
  | 'staging'
  | 'production'
  | 'none'
  | 'all'
  | 'undefined';

export type FeatureFlag =
  | 'CXP-customers-competitors'
  | 'CXP-customers-import-new-entities-button'
  | 'CXP-customers-survey-insights'
  | 'CXP-customers-analytics-reports'
  | 'CXP-customers-analytics-reports-list'
  | 'CXP-customers-analytics-reports-sent'
  | 'CXP-customers-review-providers-connect'
  | 'CXP-customers-organization-profile'
  | 'CXP-ops-manage-organizations'
  | 'CXP-poc-reviews-dialog'
  | 'CXP-customers-access-groups'
  | 'CXP-customers-subscription'
  | 'CXP-customers-add-entity'
  | 'CXP-customers-benchmark'
  | 'CXP-customers-reports'
  | 'CXP-customers-inbox-reports'
  | 'CXP-customers-protected-custom-attributes'
  | 'CXP-customers-visit-data'
  | 'CXP-customers-manage-benchmarks'
  | 'CXP-customers-survey-management';
