import { useIntl } from 'react-intl';

import { Divider, ListItemButton, Paper, type PaperProps, lighten } from '@mui/material';

import { actionMessages } from '../../constants/messages';

export function CustomPaperComponent(props: PaperProps) {
  const intl = useIntl();

  return (
    <Paper {...props} elevation={8}>
      {props.children}
      <Divider />
      <ListItemButton
        sx={{
          display: 'flex',
          justifyContent: 'end',
          paddingBlock: 1.5,
          paddingInline: 2.5,
          color: 'primary.main',
          fontSize: 14,
          fontWeight: 500,
          '&:hover': {
            backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.96),
          },
        }}
      >
        {intl.formatMessage(actionMessages.apply)}
      </ListItemButton>
    </Paper>
  );
}
