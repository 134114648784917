import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { UseAutocompleteProps } from '@mui/material';
import { Autocomplete, TextField } from '@trustyou/ui';

import { submissionMessages } from '../../../i18n-submission-ui-subset/messages';
import type { SelectQuestionProps } from '../question-types';
import type { Option } from '../types';

export type DropdownSingleProps = SelectQuestionProps;

export function DropdownSingle({ field, options }: DropdownSingleProps) {
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = useState<Option | null | undefined>(
    field?.value ? options.find((item) => item.value === field.value[0]) : null
  );

  const handleChange: UseAutocompleteProps<Option, false, false, false>['onChange'] = (
    event,
    value
  ) => {
    if (!value) {
      field?.onChange(['']);
      setSelectedOption(null);
      return;
    }
    field?.onChange([value.value]);
    setSelectedOption(value);
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      getOptionLabel={(option) => option.label as string}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={intl.formatMessage(submissionMessages.questionDropdownPlaceholder)}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id ?? (option.label as string)}>
          {option.label}
        </li>
      )}
      value={selectedOption}
      onChange={handleChange}
    />
  );
}
