import { type ReactNode, createContext, useContext, useState } from 'react';

import type { BoxProps, DividerProps, DrawerProps } from '@mui/material';
import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  Divider,
  IconButton,
  Drawer as MuiDrawer,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export function useDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);
  const toggle = () => setIsOpen(!openDrawer);

  return { isOpen, openDrawer, closeDrawer, toggle };
}

export function ComposableDrawerWithStickyFooter({ children, ...props }: DrawerProps) {
  return <MuiDrawer {...props}>{children}</MuiDrawer>;
}

const DrawerHeaderContext = createContext<{
  title?: ReactNode;
  description?: ReactNode;
  otherContent?: ReactNode;
}>({
  title: '',
  description: '',
  otherContent: undefined,
});

function DrawerHeader({
  title,
  description,
  otherContent,
  onClose,
  ...props
}: BoxProps & {
  title?: ReactNode;
  description?: ReactNode;
  otherContent?: ReactNode;
  onClose?: () => void;
}) {
  return (
    <DrawerHeaderContext.Provider value={{ title, description, otherContent }}>
      <Box
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, padding: 2, ...props.sx }}
      >
        {(title || description) && <HeaderText />}
        {otherContent}
        {onClose && <CloseButton onClose={onClose} />}
      </Box>
    </DrawerHeaderContext.Provider>
  );
}

ComposableDrawerWithStickyFooter.Header = DrawerHeader;

function HeaderText() {
  const { title, description } = useContext(DrawerHeaderContext);
  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Typography variant="h6">{title}</Typography>
      {description && (
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      )}
    </Box>
  );
}

export function CloseButton({ onClose }: { onClose: () => void }) {
  return (
    <IconButton onClick={onClose} sx={{ alignSelf: 'start' }}>
      <StyledFontAwesomeIcon icon={faXmark} />
    </IconButton>
  );
}

function DrawerFooter({
  primaryButton,
  secondaryButton,
  children,
  ...props
}: BoxProps & {
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
}) {
  return (
    <Box
      {...props}
      sx={{ display: 'flex', justifyContent: 'end', gap: 1, padding: 1, ...props.sx }}
    >
      {secondaryButton}
      {primaryButton}
      {children}
    </Box>
  );
}

ComposableDrawerWithStickyFooter.Footer = DrawerFooter;

function DrawerContent({ children, ...props }: BoxProps) {
  return (
    <Box sx={{ flexGrow: 1, paddingBlock: 1, paddingInline: 3, overflow: 'auto', ...props.sx }}>
      {children}
    </Box>
  );
}

ComposableDrawerWithStickyFooter.Content = DrawerContent;

function DrawerDivider({ sx, ...props }: DividerProps) {
  return <Divider sx={{ marginInline: -2, ...sx }} {...props} />;
}

ComposableDrawerWithStickyFooter.Divider = DrawerDivider;
