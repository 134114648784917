import { MEMBERSHIP_BASE_PATH } from './routes';

import { DOMAIN, type RoutePermissions } from '../types';

const orgCenter = DOMAIN.ORG_CENTER;
const analytics = DOMAIN.ANALYTICS;
const inbox = DOMAIN.INBOX;
const survey = DOMAIN.SURVEY;

export const GET_ENTITIES_PERMISSION = `${orgCenter}/organizations/get_owned_entities`;
export const UPLOAD_ENTITIES_PERMISSION = `${orgCenter}/organizations/upload_owned_entities`;
export const DELETE_ENTITY_PERMISSION = `${orgCenter}/organizations/delete_owned_entity`;
export const ADD_COMPETITORS_PERMISSION = `${orgCenter}/organizations/set_owned_entity_competitors`;
export const EXPORT_OWNED_ENTITIES = `${orgCenter}/organizations/export_owned_entities`;
export const GET_SEGMENTS_PERMISSION = `${orgCenter}/segments/get_segments`;
export const UPDATE_SEGMENT_PERMISSION = `${orgCenter}/segments/update_segment`;
export const UPDATE_GROUP_PERMISSION = `${orgCenter}/segments/update_group`;
export const DELETE_SEGMENT_PERMISSION = `${orgCenter}/segments/delete_segment`;
export const DELETE_GROUP_PERMISSION = `${orgCenter}/segments/delete_group`;
export const UPDATE_ORGANIZATION_PERMISSION = `${orgCenter}/organizations/update_organization`;
export const GET_USERS_PERMISSION = `${orgCenter}/users/get_combined_users_and_invitations`;
export const GET_ENTITY_SUBSCRIPTIONS_PERMISSION = `${orgCenter}/subscriptions/get_entity_subscriptions`;
export const GET_CURRENT_SUBSCRIPTIONS_PERMISSION = `${orgCenter}/subscriptions/get_current_subscription`;
export const GET_SUBSCRIPTION_PERMISSION = `${orgCenter}/subscriptions/get_subscription`;
export const SEARCH_COMPETITORS_PERMISSION = `${orgCenter}/organizations/search_competitors`;
export const SEARCH_ADDABLE_ENTITIES_PERMISSION = `${orgCenter}/organizations/search_addable_entities`;
export const ADD_OWNED_ENTITIES_PERMISSION = `${orgCenter}/organizations/add_owned_entities`;
export const ENTITY_MANAGER_PERMISSION = `${orgCenter}/manage_entity:users`;

export const DASHBOARD_PAGE_PERMISSION = `${analytics}/sisense/get_sso_token`;

export const REPORT_SCHEDULE_PERMISSION = `${analytics}/report/register`;
export const REPORT_LIST_PERMISSION = `${analytics}/report/list`;

export const DASHBOARD_IMPACT_SCORE_CALCULATE_PERMISSION = `${analytics}/impact_score/calculate`;

export const GET_REVIEW_PROVIDERS = `${orgCenter}/organizations/get_review_providers`;
export const GET_REVIEW_PROVIDER_CONNECTED_ENTITIES = `${orgCenter}/connection/get_connected_entities`;
export const GET_ENTITY_REVIEW_PROVIDERS = `${orgCenter}/entity_connections/get_review_providers`;

export const EDIT_USER_PERMISSION = `${orgCenter}/users/edit_user`;
export const DELETE_USER_PERMISSION = `${orgCenter}/users/delete_user`;
export const INVITE_USER_PERMISSION = `${orgCenter}/users/invite_user`;
export const UPDATE_INVITATION_PERMISSION = `${orgCenter}/users/update_invitation`;
export const RESEND_INVITATION_PERMISSION = `${orgCenter}/users/resend_invitation`;
export const DELETE_INVITATION_PERMISSION = `${orgCenter}/users/delete_invitation`;

export const GET_REUSABLE_SCOPES_PERMISSION = `${orgCenter}/scopes/get_reusable_scopes`;
export const UPDATE_ENTITY_CUSTOM_ATTRIBUTES_PERMISSION = `${orgCenter}/organizations/set_entity_custom_attributes`;
export const CREATE_CUSTOM_ATTRIBUTE_DEFINITION_PERMISSION = `${orgCenter}/custom_attribute_definitions/add`;
export const UPDATE_ENTITY_PROTECTED_CUSTOM_ATTRIBUTES_PERMISSION = `${orgCenter}/entities:set_protected_attributes`;

export const INBOX_PAGE_PERMISSION = `${inbox}/reviews/filter`;
export const INBOX_SET_STATUS_READ_PERMISSION = `${inbox}/reviews/set-status-read-unread`;
export const INBOX_SET_STATUS_RESPONDED_PERMISSION = `${inbox}/reviews/set-status-responded-unresponded`;
export const INBOX_SAVE_RESPONSE_PERMISSION = `${inbox}/reviews/save-response`;
export const INBOX_RESPONSE_AI_PERMISSION = `${inbox}/response-ai/generate-response`;
export const INBOX_TRANSLATE_PERMISSION = `${inbox}/reviews/translate`;
export const INBOX_DELETION_PERMISSION = `${inbox}/reviews/mark-as-deleted`;
export const INBOX_MODERATION_APPROVE_PERMISSION = `${inbox}/reviews/mark-as-inappropriate/approve`;
export const INBOX_MODERATION_REJECT_PERMISSION = `${inbox}/reviews/mark-as-inappropriate/reject`;
export const INBOX_GET_EMAIL_NOTIFICATION_SETTINGS_PERMISSION = `${inbox}/user-settings/get-email-notification`;
export const INBOX_SET_EMAIL_NOTIFICATION_SETTINGS_PERMISSION = `${inbox}/user-settings/set-email-notification`;

export const SURVEY_LIST_VISIT_DATA_PERMISSION = `${survey}/datapoint/list`;
export const SURVEY_VISIT_DATA_MANAGEMENT = `${survey}/datapoint/create`;
export const SURVEY_MANAGER_PERMISSION = `${survey}/survey_manager:view`;
export const SURVEY_INSIGHTS_PERMISSION = `${analytics}/survey_insights:view`;

export const SURVEY_LIST_PERMISSION = `${survey}/survey/list`;
export const QUESTION_LIST_PERMISSION = `${survey}/question/list`;
export const CONTENT_LIBRARY_LIST_PERMISSION = `${survey}/content/list`;
export const THEME_LIST_PERMISSION = `${survey}/theme/list`;

export const SURVEY_CREATE_PERMISSION = `${survey}/survey/create`;
export const SURVEY_QUESTIONNAIRE_CREATE_PERMISSION = `${survey}/questionnaire/create`;
export const QUESTION_CREATE_PERMISSION = `${survey}/question/create`;
export const QUESTION_UPDATE_PERMISSION = `${survey}/question/update`;
export const THEME_CREATE_PERMISSION = `${survey}/theme/create`;

export const GET_ORGANIZATION_BENCHMARKS_PERMISSION = `${orgCenter}/benchmarks/get_organization_benchmarks`;
export const GET_USER_BENCHMARKS_PERMISSION = `${orgCenter}/benchmarks/get_user_benchmarks`;
export const VIEW_BENCHMARKS_PERMISSION = `${orgCenter}/benchmarks:view`;
export const CREATE_ORGANIZATION_BENCHMARKS_PERMISSION = `${orgCenter}/benchmarks/create_organization_benchmark`;
export const CREATE_USER_BENCHMARKS_PERMISSION = `${orgCenter}/benchmarks/create_user_benchmark`;

export const DASHBOARDS_SURVEY_STATISTICS_PERMISSION = `${analytics}/dashboards:survey_statistics`;
export const DASHBOARDS_BENCHMARKS_PERMISSION = `${analytics}/dashboards:benchmarks`;
export const DASHBOARDS_COMPETITORS_PERMISSION = `${analytics}/dashboards:competitors`;

export const routePermissions: RoutePermissions = {
  [`${MEMBERSHIP_BASE_PATH}/welcome`]: [UPLOAD_ENTITIES_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/load-data`]: [UPLOAD_ENTITIES_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/organization/entities`]: [GET_ENTITIES_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/organization/users`]: [GET_USERS_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/analytics`]: [DASHBOARD_PAGE_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/settings/application/subscriptions`]: [
    GET_CURRENT_SUBSCRIPTIONS_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/settings/application/review-providers`]: [GET_REVIEW_PROVIDERS],
  [`${MEMBERSHIP_BASE_PATH}/settings/application/review-providers/:reviewProviderId`]: [
    GET_REVIEW_PROVIDER_CONNECTED_ENTITIES,
  ],
  [`${MEMBERSHIP_BASE_PATH}/settings/application/custom-attributes`]: [
    CREATE_CUSTOM_ATTRIBUTE_DEFINITION_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/settings/application/visit-data-points`]: [
    SURVEY_VISIT_DATA_MANAGEMENT,
  ],
  [`${MEMBERSHIP_BASE_PATH}/settings/user/notifications`]: [
    INBOX_GET_EMAIL_NOTIFICATION_SETTINGS_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/inbox`]: [INBOX_PAGE_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/survey-manager`]: [SURVEY_MANAGER_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/subscriptions`]: [GET_ENTITY_SUBSCRIPTIONS_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/benchmarks`]: [VIEW_BENCHMARKS_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/upload-subscription-entities/:chosenSubscriptionId`]: [
    GET_ENTITY_SUBSCRIPTIONS_PERMISSION,
    UPLOAD_ENTITIES_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/reports`]: [REPORT_LIST_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/insights`]: [DASHBOARD_PAGE_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/insights/reviews`]: [DASHBOARD_PAGE_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/insights/sentiment`]: [DASHBOARD_PAGE_PERMISSION],
  [`${MEMBERSHIP_BASE_PATH}/insights/competitors`]: [
    DASHBOARD_PAGE_PERMISSION,
    DASHBOARDS_COMPETITORS_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/insights/benchmarks`]: [
    DASHBOARD_PAGE_PERMISSION,
    VIEW_BENCHMARKS_PERMISSION,
    DASHBOARDS_BENCHMARKS_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/insights/survey-reviews`]: [
    DASHBOARD_PAGE_PERMISSION,
    SURVEY_INSIGHTS_PERMISSION,
  ],
  [`${MEMBERSHIP_BASE_PATH}/insights/survey-statistics`]: [
    DASHBOARD_PAGE_PERMISSION,
    SURVEY_INSIGHTS_PERMISSION,
    DASHBOARDS_SURVEY_STATISTICS_PERMISSION,
  ],
};
