import { type BasicEntity, type Entity, getFontFamily } from '@trustyou/shared';
import { darken, lighten, theme } from '@trustyou/ui';

import type { Theme } from '../client';
import type { Content } from '../types/content';
import type { SurveyQuestionnaireEditorContentOutput } from '../types/survey';

type FilterChosenContentsProps = {
  allItems: Content[];
  chosenItems?: SurveyQuestionnaireEditorContentOutput[];
};

export function filterChosenContents({ allItems, chosenItems }: FilterChosenContentsProps) {
  const chosenQuestionIds = chosenItems?.map((item) => item.id) || [];
  const chosenSectionQuestionIds = (chosenItems ?? []).flatMap((item) =>
    item.questions
      ? item.questions.map((question: SurveyQuestionnaireEditorContentOutput) => question.id)
      : []
  );
  const allChosenContentIds = [...chosenQuestionIds, ...chosenSectionQuestionIds];
  return allItems.filter((item) => !allChosenContentIds.includes(item.id));
}

// export function paginateSurvey(survey?: SurveyQuestionnaireEditorContentOutput[]) {
//   const pages = [];
//   let currentPage: SurveyQuestionnaireEditorContentOutput[] = [];

//   survey?.forEach((item: SurveyQuestionnaireEditorContentOutput) => {
//     if (item.type === 'page-break') {
//       pages.push(currentPage);
//       currentPage = [];
//     } else {
//       currentPage.push(item);
//     }
//   });

//   if (currentPage.length > 0) {
//     pages.push(currentPage);
//   }

//   return pages;
// }

export const findOverallScoreQuestion = (
  surveyContent: SurveyQuestionnaireEditorContentOutput[]
): SurveyQuestionnaireEditorContentOutput | undefined => {
  if (surveyContent?.length > 0) {
    for (const item of surveyContent) {
      if (item.handling_type === 'overall_score') {
        return item;
      }
      if (item.type === 'section' && item.questions) {
        const result = findOverallScoreQuestion(item.questions);
        if (result) {
          return result;
        }
      }
    }
  }

  return undefined;
};

export const flattenQuestionsArray = (questionsArray: SurveyQuestionnaireEditorContentOutput[]) => {
  const flattenedArray = [] as SurveyQuestionnaireEditorContentOutput[];

  const flatten = (
    items: SurveyQuestionnaireEditorContentOutput[],
    parentSectionId: string | null = null
  ) => {
    items.forEach((item: SurveyQuestionnaireEditorContentOutput) => {
      if (item.type === 'question') {
        flattenedArray.push({ ...item, parentSectionId }); // Add parentSectionId to question
      } else if (item.type === 'section' && item.questions) {
        flattenedArray.push({ ...item, questions: [] }); // Add the section without questions
        flatten(item.questions, item.id); // Flatten the questions inside the section and pass the section id
      }
    });
  };

  flatten(questionsArray);
  return flattenedArray;
};

export const unflattenQuestionsArray = (
  flattenedArray: SurveyQuestionnaireEditorContentOutput[]
) => {
  const resultArray = [] as SurveyQuestionnaireEditorContentOutput[];
  const sectionMap = {} as { [key: string]: SurveyQuestionnaireEditorContentOutput };

  flattenedArray.forEach((item: SurveyQuestionnaireEditorContentOutput) => {
    if (item.type === 'section') {
      const section = { ...item, questions: [] };
      sectionMap[item.id] = section;
      resultArray.push(section);
    } else if (item.type === 'question') {
      const parentSectionId = item.parentSectionId;
      if (parentSectionId && sectionMap[parentSectionId]) {
        sectionMap[parentSectionId].questions.push(item);
      } else {
        resultArray.push(item);
      }
    }
  });

  return resultArray;
};

export function convertSurveyData({
  surveyId,
  data,
  selectedEntities,
}: {
  surveyId: string;
  data: any;
  selectedEntities: Entity[] | BasicEntity[];
}) {
  return {
    survey_id: surveyId,
    internal_name: data.surveyQuestionnaireEditorInternalName,
    default_language: data.surveyEditorDefaultLanguage ?? 'en',
    questions_per_page:
      data.surveyQuestionnaireEditorPaginationOption === 'multiplePage'
        ? Number(data?.surveyQuestionnaireEditorPaginationMultiplePageNumber)
        : null,
    welcome_message:
      data.surveyQuestionnaireEditorWelcomeMessage === undefined ||
      data.surveyQuestionnaireEditorWelcomeMessage?.id === 'no-welcome-message'
        ? null
        : {
            id: data.surveyQuestionnaireEditorWelcomeMessage?.id,
            type: data.surveyQuestionnaireEditorWelcomeMessage?.type,
          },
    thankyou_message: {
      id: data.surveyQuestionnaireEditorThankyouMessage?.id,
      type: data.surveyQuestionnaireEditorThankyouMessage?.type,
    },
    submit_section: {
      id: data.submitSection[0]?.id,
      type: data.submitSection[0]?.type,
      content: data.submitSection[0]?.questions.map(
        (question: SurveyQuestionnaireEditorContentOutput) => ({
          id: question.id,
          type: question.type,
          mandatory: question.mandatory || false,
          public: false,
          use_not_applicable: question.not_applicable != null,
          condition:
            question.conditions && question.conditions.length > 0
              ? {
                  cond: 'and',
                  expressions: question.conditions?.map((condition: any) => ({
                    type: 'pre_evaluated',
                    id: condition.questionId,
                    operator: condition.operator === 1 ? 'in' : 'not_in',
                    values: condition.value,
                  })),
                }
              : null,
        })
      ),
      position: 'end',
    },
    content: data.surveyContent?.map((question: SurveyQuestionnaireEditorContentOutput) => ({
      id: question.id,
      type: question.type,
      mandatory: question.mandatory || false,
      public: false,
      use_not_applicable: question.not_applicable != null,
      condition:
        question.conditions && question.conditions.length > 0
          ? {
              cond: 'and',
              expressions: question.conditions?.map((condition: any) => ({
                type: 'pre_evaluated',
                id: condition.questionId,
                operator: condition.operator === 1 ? 'in' : 'not_in',
                values: condition.answer,
              })),
            }
          : null,
      content: question?.questions?.map((subQuestion: SurveyQuestionnaireEditorContentOutput) => ({
        id: subQuestion.id,
        type: subQuestion.type,
        mandatory: subQuestion.mandatory || false,
        public: false,
        use_not_applicable: subQuestion.not_applicable != null,
        condition:
          subQuestion.conditions && subQuestion.conditions.length > 0
            ? {
                cond: 'and',
                expressions: subQuestion?.conditions?.map((subCondition: any) => ({
                  type: 'pre_evaluated',
                  id: subCondition.questionId,
                  operator: subCondition.operator === 1 ? 'in' : 'not_in',
                  values: subCondition.answer,
                })),
              }
            : null,
      })),
    })),
    theme: data.surveyQuestionnaireEditorTheme?.id,
    email_invitation: {
      id: data.surveyQuestionnaireEditorEmailInvitation?.id,
      type: data.surveyQuestionnaireEditorEmailInvitation?.type,
    },
    show_email_overall_score_question:
      data.surveyQuestionnaireEditorEmailInvitation?.embedOverallScore || false,
    scopes: selectedEntities.map((entity: any) => ({
      entity_id: entity.id,
      is_enabled: true,
    })),
  };
}

export function generateCustomTheme(customTheme: Theme) {
  return {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: customTheme.colors.primary,
        light: lighten(customTheme.colors.primary ?? '', 0.1),
        dark: darken(customTheme.colors.primary ?? '', 0.1),
        contrastText: customTheme.colors.button_text,
      },
      text: {
        ...theme.palette.text,
        primary: customTheme.colors.primary_text,
        secondary: customTheme.colors.secondary_text,
      },
      background: {
        ...theme.palette.background,
        default: customTheme.colors.background,
      },
    },
    components: {
      ...theme.components,
      MuiTypography: {
        ...theme.components?.MuiTypography,
        styleOverrides: {
          root: {
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },
    typography: {
      ...theme.typography,
      fontFamily: getFontFamily(customTheme.fonts.body_text),
    },
  };
}
