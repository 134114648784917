import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { TextFieldProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Asterisk, Stack, TextField, Typography } from '@trustyou/ui';
import parsePhoneNumber, { type CountryCode } from 'libphonenumber-js';

import { useTextQuestionHelpers } from './use-text-question-helpers';

import type { CountryType } from '../../components/countries';
import { CountrySelect } from '../../components/country-select';
import { Question, type QuestionProps } from '../../components/question';

export type TextQuestionVariant = 'short_text' | 'long_text' | 'email' | 'phone';

export type TextQuestionProps = QuestionProps & {
  variant?: TextQuestionVariant;
  minCharacters?: number;
  maxCharacters?: number;
  isContactInfo?: boolean;
};

export function TextQuestion({
  field,
  variant = 'short_text',
  minCharacters = 0,
  maxCharacters = 255,
  isContactInfo = false,
  ...props
}: TextQuestionProps) {
  const intl = useIntl();
  const { isPhone: isPhoneViewport } = useResponsive();

  const initialValue = field?.value ?? '';
  const phone = parsePhoneNumber(initialValue.toString());

  const [countryCode, setCountryCode] = useState(phone?.country);
  const [value, setValue] = useState(phone?.formatNational() ?? initialValue);

  const {
    isText,
    isPhone: isPhoneQuestion,
    getHelperText,
    getPlaceholder,
  } = useTextQuestionHelpers({ variant, remainingCharacterCount: maxCharacters - value?.length });

  const handleChange: TextFieldProps['onChange'] = (event) => {
    if (isPhoneQuestion) {
      const phoneNumber = parsePhoneNumber(event.target.value, countryCode);
      field?.onChange(phoneNumber?.number);
    } else {
      field?.onChange(event.target.value);
    }
    setValue(event.target.value);
  };

  const handleCountryCodeChange = (country: CountryType) => {
    setCountryCode(country.code as CountryCode);
    const phoneNumber = parsePhoneNumber(value, country.code as CountryCode);
    field?.onChange(phoneNumber?.number);
    setValue(phoneNumber?.formatNational());
  };

  return (
    <Question field={field} {...props}>
      <Stack direction={isPhoneViewport ? 'column' : 'row'} spacing={2}>
        {isPhoneQuestion && (
          <Stack spacing={1}>
            {/* TODO: `title` and `description` for Phone questions inside the Submit section should be defined by user (and avoid hardcoding it manually here). */}
            {!props.title && (
              <Typography component="h3" variant="subtitle1">
                {intl.formatMessage({
                  id: 'survey.question.text.phone.label',
                  defaultMessage: 'Phone',
                })}
                {props.isMandatory && <Asterisk />}
              </Typography>
            )}
            <CountrySelect initialCountryCode={countryCode} onChange={handleCountryCodeChange} />
          </Stack>
        )}
        <TextField
          {...field}
          fullWidth
          multiline={isText}
          minRows={variant === 'long_text' ? 3 : 1}
          value={value}
          onChange={handleChange}
          placeholder={getPlaceholder()}
          helperText={getHelperText()}
          FormHelperTextProps={{ sx: { marginInline: 0 } }}
          sx={{
            ...(!isPhoneViewport && isPhoneQuestion && !props.title && { paddingBlockStart: 4.5 }),
          }}
        />
      </Stack>
    </Question>
  );
}
