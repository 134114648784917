import { type ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { commonFields, fetchEntity, useEntity, useRouteUtils } from '@trustyou/shared';
import {
  Box,
  ContentPane,
  EntitySourcesView,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  SectorAvatarIcon,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@trustyou/ui';

import { EntityReviewProviderDetails } from './Connections/EntityReviewProviderDetails/EntityReviewProviderDetails';
import { EntityReviewProviders } from './Connections/EntityReviewProviders';
import { MiniEntityTable } from './MiniEntityTable';
import { OverviewTab } from './OverviewTab';

import { EntityCompetitorsView } from '../../../../components';
import { useEntityReviewProvidersPermission } from '../../../../hooks';
import styles from './styles';

const TABS: Record<string, ReactNode> = {
  overview: <FormattedMessage {...commonFields.details} />,
  competitors: <FormattedMessage {...commonFields.competitors} />,
  connections: <FormattedMessage {...commonFields.connections} />,
  sources: <FormattedMessage {...commonFields.sources} />,
};

export const EntityDetails = () => {
  const { navigateWithBase } = useRouteUtils();
  const navigate = useNavigate();
  const { entityId = '' } = useParams();
  const location = useLocation();
  const currentTabPath = location.pathname.split('/').filter(Boolean).pop();
  const { data: entity } = useEntity(entityId);
  const showConnections = useEntityReviewProvidersPermission();
  const displayTabs = currentTabPath && TABS[currentTabPath];

  const onAddCompetitors = () => {
    navigate('add-competitors');
  };

  const onClose = () => {
    navigateWithBase('organization/entities');
  };

  useEffect(() => {
    // Logic to bo back to entities table if a invalid entity id used.
    // Cannot refer useEntity error because it takes time to return the error response due to retries
    fetchEntity(entityId).catch((error) => {
      if (error.code === 'ERR_BAD_REQUEST') {
        onClose();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPane sx={styles.contentPane} bodyStyles={styles.contentPaneBodyStyles}>
      <MiniEntityTable />
      {entity && (
        <Stack sx={styles.detailsContainer}>
          <Stack sx={styles.detailsHeader}>
            <SectorAvatarIcon sector={entity.sector} />
            <Typography variant="h6" flex="1">
              {entity.name}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon icon={faXmark} />
            </IconButton>
          </Stack>
          <Stack sx={styles.detailsBody}>
            {displayTabs && (
              <Tabs
                sx={styles.detailsTabs}
                value={currentTabPath}
                onChange={(_, value) => {
                  navigate(value);
                }}
              >
                {Object.entries(TABS)
                  .filter(([id]) => id !== 'connections' || showConnections)
                  .map(([id, message]) => (
                    <Tab key={id} value={id} label={message} />
                  ))}
              </Tabs>
            )}

            <Box sx={displayTabs ? styles.tabsBody : styles.body}>
              <Routes>
                <Route index element={<Navigate to="overview" replace />} />
                <Route path="overview" element={<OverviewTab entity={entity} />} />
                <Route
                  path="sources"
                  element={<EntitySourcesView sourcesUrls={entity.sources_urls || []} />}
                />
                {showConnections && (
                  <>
                    <Route path="connections" element={<EntityReviewProviders />} />
                    <Route
                      path="connections/:reviewProviderId"
                      element={<EntityReviewProviderDetails />}
                    />
                  </>
                )}
                <Route
                  path="competitors"
                  element={
                    <EntityCompetitorsView entity={entity} onAddCompetitors={onAddCompetitors} />
                  }
                />
                <Route path="*" element={<Navigate to="overview" replace />} />
              </Routes>
              <Outlet />
            </Box>
          </Stack>
        </Stack>
      )}
    </ContentPane>
  );
};
