import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { faEdit } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { actionMessages } from '@trustyou/shared';
import { IconButtonWithTooltip, Skeleton, Stack, Typography } from '@trustyou/ui';

type FilterDialogButtonProps = {
  title: ReactNode;
  description: ReactNode;
  onClick: () => void;
};

export function FilterDialogButton({ title, description, onClick }: FilterDialogButtonProps) {
  const intl = useIntl();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack alignContent="center">
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {description ?? <Skeleton />}
        </Typography>
      </Stack>
      <IconButtonWithTooltip
        icon={faEdit}
        IconProps={{ size: 'sm' }}
        tooltip={intl.formatMessage(actionMessages.edit)}
        color="primary"
        onClick={onClick}
      />
    </Stack>
  );
}
