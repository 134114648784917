import type { Profile_Input } from '../client';
import type {
  GetResponseTemplatesResponse,
  ResponseAISettingsFormData,
  Template,
} from '../types/settings';

export const getFormattedProfileData = (formData: ResponseAISettingsFormData): Profile_Input => ({
  guides: (formData.guides ?? [])
    .map((guideEntry) => ({
      ...guideEntry,
      guides: guideEntry.guides.filter(
        // remove empty guides
        (guide) => ![guide.problem, guide.answer].includes('')
      ),
    }))
    // remove empty guides entries
    .filter((guideEntry) => guideEntry.guides.length > 0),
  reply_length: formData.default_reply_length,
  tone_of_voice: formData.default_tone_of_voice,
  translation_language: formData.default_translation_language,
});

export const getResponseTemplatesByLanguage = (
  responseTemplatesObject?: GetResponseTemplatesResponse,
  language = 'en'
): Template[] => {
  if (!responseTemplatesObject) return [];
  const languageCode = language.toUpperCase();

  // some languages might not be present in default and/or user templates
  const defaultTemplatesForLanguage = responseTemplatesObject.default_templates[languageCode] ?? [];
  const userTemplatesForLanguage = responseTemplatesObject.user_templates[languageCode] ?? [];

  const templatesForLanguage = [...defaultTemplatesForLanguage, ...userTemplatesForLanguage];
  return templatesForLanguage;
};

export const deleteIdKeyFromAIGuide = (aiGuide: {
  id?: string;
  problem: string;
  answer: string;
}) => {
  delete aiGuide.id;
  return aiGuide;
};
