import { useQuery } from '@tanstack/react-query';
import { $assApi, type PaginationRequest, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { Questionnaire_Output, Survey } from '../../client';
import type { QuestionnaireByEntity } from '../../client/models';
import type { SurveyByEntity } from '../../types/survey';
import { QUESTIONNAIRE_LIST, QUESTIONNAIRE_LIST_BY_ENTITY } from '../constants/query-keys';

export async function fetchQuestionnaires({
  surveyId,
  pagination,
  searchTerms,
}: {
  surveyId: Survey['id'];
  pagination?: PaginationRequest;
  searchTerms?: string;
}): Promise<{ data: Questionnaire_Output[]; count: number }> {
  const { data } = await $assApi.get(
    `${SURVEY_BASE_PATH}/survey/list-questionnaires/${surveyId}?filters[search_terms]=${searchTerms}`,
    {
      params: pagination,
    }
  );
  return {
    data: data.data,
    count: data.count,
  };
}

export async function fetchQuestionnairesByEntity({
  surveyId,
  pagination,
  searchTerms,
}: {
  surveyId: Survey['id'];
  pagination?: PaginationRequest;
  searchTerms?: string;
}): Promise<{ data: SurveyByEntity[]; count: number }> {
  const { data } = await $assApi.get(
    `${SURVEY_BASE_PATH}/survey/list-questionnaires/${surveyId}?by_entity=true&filters[search_terms]=${searchTerms}`,
    {
      params: pagination,
    }
  );

  const formattedData = (data.data as QuestionnaireByEntity[]).map((item) => ({
    id: item.entity.id,
    // Some questionnaires could have a null entity name, so we display the entity id in those cases.
    entity: item.entity.name ?? item.entity.id,
    version: item.questionnaire.internal_name,
    url: item.submit_url,
  }));
  return {
    data: formattedData,
    count: data.count,
  };
}

export function useQuestionnaires({
  surveyId,
  pagination,
  searchTerms,
}: {
  surveyId: Survey['id'];
  pagination?: PaginationRequest;
  searchTerms?: string;
}) {
  return useQuery({
    queryKey: [QUESTIONNAIRE_LIST, { surveyId, pagination, searchTerms }],
    queryFn: () => fetchQuestionnaires({ surveyId, pagination, searchTerms }),
  });
}

export function useQuestionnairesByEntity({
  surveyId,
  pagination,
  searchTerms,
}: {
  surveyId: Survey['id'];
  pagination?: PaginationRequest;
  searchTerms?: string;
}) {
  return useQuery({
    queryKey: [QUESTIONNAIRE_LIST_BY_ENTITY, { surveyId, pagination, searchTerms }],
    queryFn: () => fetchQuestionnairesByEntity({ surveyId, pagination, searchTerms }),
  });
}
