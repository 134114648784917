import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { ProviderAccount } from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { AccountView } from './AccountView';

import styles from './styles';

type ReviewProviderAccountsViewProps = {
  providerName: string;
  accounts: ProviderAccount[];
  onDelete: (id: string) => void;
  onAdd: () => void;
};

export function ReviewProviderAccountsView({
  providerName,
  accounts,
  onAdd,
  onDelete,
}: ReviewProviderAccountsViewProps) {
  return (
    <Box sx={styles.providerAccountSection}>
      <Typography variant="subtitle1">
        <FormattedMessage
          id="review-providers.details.accounts.title"
          defaultMessage="{accountsCount, plural,
          one {Connected Account}
          other {Connected Accounts}
        }"
          values={{ accountsCount: accounts?.length }}
        />
      </Typography>
      <Box sx={styles.providerAccountList}>
        {accounts.map((account) => (
          <AccountView
            key={account.provider_account_id}
            account={account}
            sx={{
              width: {
                xs: '100%',
                lg: accounts.length > 1 ? 'calc(50% - 8px)' : '60%',
              },
            }}
            onDelete={onDelete}
          />
        ))}
        <Button
          variant="text"
          startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
          color="primary"
          size="large"
          data-testid="add-new-provider-account"
          data-gtm-id={`add-new-provider-account-for-${providerName}`}
          onClick={onAdd}
        >
          <FormattedMessage
            id="review-providers.add-account-button.text"
            defaultMessage="Add account"
          />
        </Button>
      </Box>
    </Box>
  );
}
