import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { SelectChangeEvent } from '@mui/material';
import type { FiltersField } from '@trustyou/shared';
import { Checkbox, FormControl, InputLabel, ListItemText } from '@trustyou/ui';

import type { Group } from '../../types';
import { formatFieldSlug } from '../../utils/mappers';
import { StyledListSubheader } from './styled-list-subheader';
import { StyledMenuItem } from './styled-menu-item';
import { StyledOutlinedInput } from './styled-outlined-input';
import { StyledSelect } from './styled-select';

export type DropdownMultipleProps = {
  fieldName: FiltersField;
  label: string;
  groups: Group[];
  initialValues: string[];
  labelForAllOption: string;
};

export function DropdownMultiple({
  fieldName,
  label,
  groups,
  initialValues,
  labelForAllOption,
}: DropdownMultipleProps) {
  const { control, setValue } = useFormContext();
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const labelId = `dropdown-multiple-label-${formatFieldSlug(fieldName)}`;
  const options = groups.flatMap((group) => group.options);
  const optionsValues = options.map((option) => option.value);
  const isAllSelected = selectedValues.length === optionsValues.length;

  const clearSelection = () => {
    setSelectedValues(optionsValues);
  };

  const toggleAll = () => {
    if (isAllSelected) {
      setSelectedValues([]);
    } else {
      setSelectedValues(optionsValues);
    }
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    // Guard clause for undefined value of the "fake" first option 'All'.
    if (typeof value !== 'string') {
      return;
    }
    setSelectedValues(value.split(','));
  };

  const handleOptionClick = (optionValue: string) => {
    let newValues: string[] = [];
    if (selectedValues.includes(optionValue)) {
      newValues = selectedValues.filter((value: string) => value !== optionValue);
    } else {
      newValues = [...selectedValues, optionValue];
    }
    setSelectedValues(newValues);
    setValue(fieldName, newValues, { shouldDirty: true });
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={() => (
        <FormControl>
          <InputLabel id={labelId} shrink>
            {label}
          </InputLabel>
          <StyledSelect
            multiple
            labelId={labelId}
            value={selectedValues}
            onChange={handleChange}
            renderValue={(selected: string[]) => {
              if (isAllSelected) {
                return labelForAllOption;
              }
              return selected
                .map((s) => options.find((option) => option.value === s)?.label)
                .join(', ');
            }}
            input={
              <StyledOutlinedInput
                label={label}
                isAllSelected={isAllSelected}
                onClear={clearSelection}
              />
            }
          >
            <StyledMenuItem onClick={toggleAll}>
              <Checkbox checked={isAllSelected} size="small" disableRipple />
              <ListItemText
                primary={labelForAllOption}
                sx={{ marginInlineStart: 0.5, whiteSpace: 'break-spaces' }}
              />
            </StyledMenuItem>
            {groups.map((group: Group) => [
              group.label ? (
                <StyledListSubheader key={`subheader-${group.label}`}>
                  {group.label}
                </StyledListSubheader>
              ) : null,
              group.options.map((option) => (
                <StyledMenuItem
                  key={option.value}
                  value={option.value}
                  selected={selectedValues.includes(option.value)}
                  onClick={() => handleOptionClick(option.value)}
                >
                  <Checkbox
                    checked={selectedValues.includes(option.value)}
                    size="small"
                    disableRipple
                  />
                  <ListItemText
                    primary={option.label}
                    sx={{ marginInlineStart: 0.5, whiteSpace: 'break-spaces' }}
                  />
                </StyledMenuItem>
              )),
            ])}
          </StyledSelect>
        </FormControl>
      )}
    />
  );
}
