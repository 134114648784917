import { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  SEARCH_DEBOUNCE_TIME_MS,
  useIsValidBenchmarkName,
  useManageBenchmarksPermission,
} from '@trustyou/shared';
import { FormControl, FormFieldLabel, TextField } from '@trustyou/ui';
import { debounce } from 'lodash';

import { manageDrawer } from '../../../constants/messages/benchmarks';

const NAME_MAX_LENGTH = 100;

export const NameController = () => {
  const { control, watch, clearErrors } = useFormContext();
  const intl = useIntl();
  const manageBenchmarksPermission = useManageBenchmarksPermission();
  const isValidBenchmarkName = useIsValidBenchmarkName();
  const [benchmarkName, benchmarkSetName] = watch(['name', 'set_name']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheck = useCallback(
    debounce(async (name: string, setName: string) => {
      if (
        !name ||
        (name.trim() === control._defaultValues.name &&
          setName.trim() === control._defaultValues.set_name)
      ) {
        return;
      }
      const payload = {
        name,
        set_name: setName,
        ownership_type: manageBenchmarksPermission || 'user',
      };
      const response = await isValidBenchmarkName.mutateAsync(payload);
      if (response) return;
      control.setError('name', {
        type: 'custom',
        message: intl.formatMessage(manageDrawer.nameExist),
      });
    }, SEARCH_DEBOUNCE_TIME_MS),
    []
  );

  useEffect(() => {
    if (!benchmarkName) return;
    clearErrors('name');
    debounceCheck(benchmarkName, benchmarkSetName);
    return () => {
      debounceCheck.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmarkName, benchmarkSetName]);

  return (
    <FormControl>
      <FormFieldLabel required>{intl.formatMessage(manageDrawer.nameTitle)}</FormFieldLabel>
      <Controller
        name="name"
        control={control}
        rules={{
          required: intl.formatMessage(manageDrawer.nameRequired),
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            placeholder={intl.formatMessage(manageDrawer.namePlaceholder)}
            id="benchmark-name"
            error={!!fieldState.error}
            helperText={fieldState.error?.message || intl.formatMessage(manageDrawer.nameHelper)}
            inputProps={{
              maxLength: NAME_MAX_LENGTH,
            }}
          />
        )}
      />
    </FormControl>
  );
};
