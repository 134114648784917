import { useIntl } from 'react-intl';

import type { TextQuestionProps } from './text-question';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';

export function useTextQuestionHelpers({
  variant,
  remainingCharacterCount,
}: Pick<TextQuestionProps, 'maxCharacters' | 'variant'> & {
  remainingCharacterCount: number;
}) {
  const intl = useIntl();

  const isText = variant === 'short_text' || variant === 'long_text';
  const isEmail = variant === 'email';
  const isPhone = variant === 'phone';

  const getPlaceholder = () => {
    if (isEmail) {
      return 'name@example.com';
    }
    if (isPhone) {
      return '123456798';
    }
    return undefined;
  };

  const getHelperText = () => {
    if (isText) {
      return intl.formatMessage(submissionMessages.shortOrLongHelperText, {
        remainingCharacterCount,
      });
    }
  };

  return {
    isText,
    isPhone,
    getPlaceholder,
    getHelperText,
  };
}
