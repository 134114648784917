import { useState } from 'react';

import type { ButtonGroupProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Button, ButtonGroup, Stack, Typography } from '@trustyou/ui';

import { useResetState } from './use-reset-state';

import type { ScaleQuestionProps } from '../question-types';
import type { Option } from '../types';
import { getLabels } from '../utils';

const BUTTON_MIN_WIDTH_DESKTOP = '64px';
const BUTTON_MIN_WIDTH_MOBILE = '8px';

type NumericButtonGroupProps = ScaleQuestionProps;

export function NumericButtonGroup({ field, options }: NumericButtonGroupProps) {
  const { isPhone } = useResponsive();

  const [hover, setHover] = useState<number | null>(null);
  const [value, setValue] = useState<number | null>(field?.value ?? null);
  useResetState({ field, onReset: () => setValue(null) });

  const isHovered = hover !== null;
  const isSelected = value !== null;

  const labels = getLabels(options);
  const firstLabel = Object.values(labels).at(0);
  const lastLabel = Object.values(labels).at(-1);

  const hoveredOption = options.find((option) => option?.value === hover);
  const selectedOption = options.find((option) => option?.value === value);

  const hasLessThanTenButtons = options.length < 10;
  const shouldHaveLimitedWidth = hasLessThanTenButtons && !isPhone;

  const getLabel = () => {
    if (isHovered) return labels[hover];
    if (isSelected) return labels[value];
    return null;
  };

  const getColor = () => {
    if (isHovered) return hoveredOption?.color as ButtonGroupProps['color'];
    if (isSelected) return selectedOption?.color as ButtonGroupProps['color'];
    return 'inherit';
  };

  const isLessOrEqual = (optionValue: number) => {
    if (isHovered) return optionValue <= (hover as number);
    if (isSelected) return optionValue <= (value as number);
    return false;
  };

  const handleClick = (selectedValue: number) => {
    if (selectedValue === value) {
      field?.onChange(null);
      setValue(null);
      return;
    }
    field?.onChange(selectedValue);
    setValue(selectedValue);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        color: isHovered || isSelected ? `${getColor()}.main` : 'text.secondary',
        ...(shouldHaveLimitedWidth && {
          width: `calc(${BUTTON_MIN_WIDTH_DESKTOP} * ${options.length})`,
        }),
      }}
    >
      <ButtonGroup
        disableElevation
        fullWidth
        color={isHovered || isSelected ? getColor() : 'inherit'}
        sx={{
          ...(isPhone && {
            '& .MuiButtonGroup-grouped': {
              minWidth: BUTTON_MIN_WIDTH_MOBILE,
            },
          }),
        }}
      >
        {options.map((option) => {
          return (
            <CustomNumericButton
              key={option.value.toString()}
              option={option}
              setHover={setHover}
              handleClick={handleClick}
              isLessOrEqual={isLessOrEqual}
              getColor={getColor}
            />
          );
        })}
      </ButtonGroup>
      {isHovered || isSelected ? (
        <Typography
          variant="caption"
          sx={{
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            color: 'text.primary',
          }}
        >
          {getLabel()}
        </Typography>
      ) : (
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
          <Typography variant="caption" sx={{ fontSize: 14 }}>
            {firstLabel}
          </Typography>
          <Typography variant="caption" sx={{ fontSize: 14, textAlign: 'end' }}>
            {lastLabel}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

type CustomNumericButtonProps = {
  option: Option;
  setHover: (value: number | null) => void;
  handleClick: (value: number) => void;
  isLessOrEqual: (value: number) => boolean;
  getColor: () => string | undefined | null;
};

function CustomNumericButton({
  option,
  setHover,
  handleClick,
  isLessOrEqual,
  getColor,
}: CustomNumericButtonProps) {
  const numericValue = Number(option.value);
  const contrastedColor = 'common.white';

  return (
    <Button
      onMouseEnter={() => setHover(numericValue)}
      onMouseLeave={() => setHover(null)}
      onClick={() => handleClick(numericValue)}
      sx={{
        paddingInline: 0,
        color: isLessOrEqual(numericValue) ? contrastedColor : 'text.secondary',
        backgroundColor: isLessOrEqual(numericValue) ? `${getColor()}.main` : 'inherit',
        borderColor: isLessOrEqual(numericValue) ? 'transparent' : 'text.secondary',
        '&:hover': {
          color: contrastedColor,
          backgroundColor: `${getColor()}.dark`,
        },
      }}
    >
      {option.value}
    </Button>
  );
}
