import { useIntl } from 'react-intl';

import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SEARCH_PARAMS, openInNewTab } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon } from '@trustyou/ui';

import type { SurveyByEntity } from '../../../../types/survey';
import { messages } from '../../../../utils/messages';

type PreviewButtonProps = {
  survey: SurveyByEntity;
};

export function PreviewButton({ survey }: PreviewButtonProps) {
  const intl = useIntl();

  const handleOpenPreview = () => {
    const url = new URL(survey.url);
    url.searchParams.set(SEARCH_PARAMS.preview, 'true');
    openInNewTab(url.toString());
  };

  return (
    <Button
      endIcon={<StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} />}
      onClick={handleOpenPreview}
    >
      {intl.formatMessage(messages.preview)}
    </Button>
  );
}
