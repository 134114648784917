import type { ReactNode } from 'react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { SwitchProps } from '@mui/material';
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  StyledFontAwesomeIcon,
  Switch,
  Typography,
} from '@trustyou/ui';

export type DistributionChannelProps = {
  icon: IconProp;
  title: ReactNode;
  description: ReactNode;
  buttonText?: ReactNode;
  switchLabel?: ReactNode;
  isSwitchChecked?: boolean;
  onSwitchChange?: SwitchProps['onChange'];
  onButtonClick?: () => void;
};

export function DistributionChannel({
  icon,
  title,
  description,
  switchLabel,
  isSwitchChecked,
  onSwitchChange,
  buttonText,
  onButtonClick,
}: DistributionChannelProps) {
  return (
    <Stack direction="row" spacing={2}>
      <StyledFontAwesomeIcon icon={icon} sx={{ color: 'primary.main', height: 44, width: 44 }} />
      <Box>
        <Typography>{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        {switchLabel && (
          <FormControlLabel
            control={<Switch checked={isSwitchChecked} onChange={onSwitchChange} />}
            label={switchLabel}
            labelPlacement="start"
            sx={{
              marginBlockStart: 2,
              marginInline: 0,
              color: 'text.secondary',
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
              },
            }}
          />
        )}
        {buttonText && onButtonClick && (
          <Button onClick={onButtonClick} variant="outlined" sx={{ marginTop: 2 }}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Stack>
  );
}
