import { useIntl } from 'react-intl';

import type { FormControlProps } from '@mui/material';
import { type FiltersField, useLanguageStore } from '@trustyou/shared';
import { SmallFormFieldSkeleton } from '@trustyou/ui';

import { DropdownMultipleAutocomplete } from './dropdown-multiple-autocomplete';

import type { SemaLanguage } from '../../client';
import { useFetchSemaLanguages } from '../../hooks';
import { useFilters } from '../../hooks/use-filters';

export type DropdownLanguageProps = FormControlProps & {
  fieldName: FiltersField;
};

export function DropdownLanguage({ fieldName, ...props }: DropdownLanguageProps) {
  const intl = useIntl();
  const { locale } = useLanguageStore();
  const { data: languages = {}, isPending } = useFetchSemaLanguages(locale as SemaLanguage);
  const { currentView } = useFilters();

  if (isPending) return <SmallFormFieldSkeleton />;

  return (
    <DropdownMultipleAutocomplete
      fieldName={fieldName}
      label={intl.formatMessage({
        id: 'inbox.filter.language.label',
        defaultMessage: 'Language',
      })}
      options={Object.entries(languages)
        .map(([langKey, label]) => ({
          label,
          value: langKey,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
        // TODO: Ask Alvaro why do we have this option 'No language' and what is the use case for it
        .concat([
          {
            label: intl.formatMessage({
              id: 'inbox.filter.language.noLanguage',
              defaultMessage: 'No language',
            }),
            value: 'no_language',
          },
        ])}
      initialValues={currentView.filters?.languages ?? []}
      {...props}
    />
  );
}
