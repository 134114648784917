import { FaceRating } from '../../components/face-rating';
import { NumericButtonGroup } from '../../components/numeric-button-group';
import { Question, type QuestionProps } from '../../components/question';
import { StarRating } from '../../components/star-rating';
import type { Option } from '../../types';

export type ScaleQuestionProps = QuestionProps & {
  options: Option[];
  variant?: 'numbers' | 'faces' | 'stars';
};

export function ScaleQuestion({ variant = 'numbers', ...props }: ScaleQuestionProps) {
  return (
    <Question {...props}>
      <>
        {variant === 'numbers' && <NumericButtonGroup {...props} />}
        {variant === 'faces' && <FaceRating {...props} />}
        {variant === 'stars' && <StarRating {...props} />}
      </>
    </Question>
  );
}
