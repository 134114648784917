import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { iconMap } from '@trustyou/shared';
import { Box, Button, Stack, Typography } from '@trustyou/ui';

import type { EmailInvitation, NPSPrimitive_Input, ScalePrimitive_Input } from '../../../client';
import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NPSQuestion,
  NumberQuestion,
  OneToFiveOptions,
  OneToTenOptions,
  ScaleQuestion,
  SelectQuestion,
  TextQuestion,
  type TextQuestionVariant,
  ZeroToTenOptions,
} from '../../../components/feedback/question-types';
import {
  SurveyMessageContent,
  SurveySection,
  SurveySectionTitle,
} from '../../../components/feedback/static-elements';
import type { SurveyQuestionnaireEditorContentOutput } from '../../../types/survey';
import { generateCustomTheme } from '../../../utils/survey-questionnaire-editor';

type RenderEmailInvitationProps = {
  overallScoreQuestion?: SurveyQuestionnaireEditorContentOutput;
  emailInvite: EmailInvitation & { embedOverallScore?: boolean };
};

export function useQuestion() {
  const intl = useIntl();
  const { control, watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');

  const renderQuestion = (question: SurveyQuestionnaireEditorContentOutput) => {
    const questionLang = question.default_language ?? 'en';
    const name = question.id ?? '';
    const title = question?.title?.[defaultSurveyLanguage] ?? question?.title?.[questionLang] ?? '';
    const description =
      question?.description?.[defaultSurveyLanguage] ?? question?.description?.[questionLang] ?? '';
    const variant = question.primitive.repr;
    const isMandatory = question.mandatory;
    const notApplicable = question.not_applicable;
    const notApplicableLabel =
      question.not_applicable_label?.[defaultSurveyLanguage] ??
      intl.formatMessage({
        id: 'survey.question.checkbox.not-applicable.label',
        defaultMessage: 'Not applicable',
      });

    switch (question.primitive.type) {
      case 'text': {
        return (
          <TextQuestion
            variant={variant as TextQuestionVariant}
            title={title}
            description={description}
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'select': {
        return (
          <SelectQuestion
            title={title}
            description={description}
            variant={'dropdown_single'}
            options={question.primitive.options.map((option) => ({
              value: option.value,
              label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
              icon: iconMap[option.icon ?? ''],
              color: option.color,
            }))}
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'scale': {
        return (
          <ScaleQuestion
            title={title}
            description={description}
            options={
              question.primitive.options?.map((option) => ({
                value: option.value,
                label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
                icon: iconMap[option.icon ?? ''],
                color:
                  (question.primitive as ScalePrimitive_Input | NPSPrimitive_Input).options
                    ?.length === 5
                    ? OneToFiveOptions.find((o) => o.value === option.value)?.color
                    : (question.primitive as ScalePrimitive_Input | NPSPrimitive_Input).options
                          ?.length === 10
                      ? OneToTenOptions.find((o) => o.value === option.value)?.color
                      : ZeroToTenOptions.find((o) => o.value === option.value)?.color,
              })) ?? []
            }
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'nps': {
        return (
          <NPSQuestion
            title={title}
            description={description}
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'number': {
        return (
          <NumberQuestion
            title={title}
            description={description}
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'boolean': {
        if (variant === 'checkbox') {
          const isLegalOrPushToCheckbox = [
            'data_privacy_information',
            'terms_and_conditions',
            'push_to_google',
          ].includes(question.handling_type);
          if (isLegalOrPushToCheckbox) {
            return (
              <BooleanQuestion
                variant={variant}
                isLegalCheckbox={isLegalOrPushToCheckbox}
                options={[
                  {
                    value: 'accept',
                    label: `${question.title[defaultSurveyLanguage] ?? question.title[questionLang]}${isMandatory ? ' *' : ''}`,
                  },
                ]}
              />
            );
          }
          return (
            <BooleanQuestion
              title={title}
              description={description}
              notApplicableCheckboxLabel={notApplicableLabel}
              variant={variant}
              options={[
                {
                  label: question.title[defaultSurveyLanguage] ?? question.title[questionLang],
                  value: question.title[defaultSurveyLanguage] ?? question.title[questionLang],
                },
              ]}
              isMandatory={isMandatory}
            />
          );
        }
        // Default `boolean` render. It includes the variant `button_group` for the Yes/No question use case.
        return (
          <BooleanQuestion
            title={title}
            description={description}
            variant={variant as BooleanQuestionVariant}
            options={
              question.primitive.options?.map((option) => ({
                value: option.value,
                label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
                icon: iconMap[option.icon ?? ''],
                color: option.color,
              })) ?? []
            }
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      case 'date': {
        const variantMap: Record<string, DateQuestionVariant> = {
          datepicker: 'day-month-year',
          year_month_picker: 'month-year',
        };
        return (
          <DateQuestion
            title={title}
            description={description}
            variant={variantMap[variant as DateQuestionVariant] ?? variant}
            isMandatory={isMandatory}
            notApplicableCheckboxLabel={notApplicableLabel}
          />
        );
      }
      default:
        return null;
    }
  };

  return { renderQuestion };
}

export const RenderWelcomeMessageAndContent = () => {
  const { watch } = useFormContext();

  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const editorWelcomeMessage = watch('surveyQuestionnaireEditorWelcomeMessage');
  const surveyContent = watch('surveyContent') as SurveyQuestionnaireEditorContentOutput[];
  const surveyTheme = watch('surveyQuestionnaireEditorTheme');
  const generatedTheme = surveyTheme && generateCustomTheme(surveyTheme);

  const { renderQuestion } = useQuestion();

  return (
    <Box>
      {editorWelcomeMessage && editorWelcomeMessage.id !== 'no-welcome-message' && (
        <SurveyMessageContent
          title={
            editorWelcomeMessage?.title?.[defaultSurveyLanguage] ??
            editorWelcomeMessage?.title?.[editorWelcomeMessage.default_language ?? 'en'] ??
            ''
          }
          description={
            editorWelcomeMessage?.description?.[defaultSurveyLanguage] ??
            editorWelcomeMessage?.description?.[editorWelcomeMessage.default_language ?? 'en'] ??
            ''
          }
        />
      )}
      {surveyContent && surveyContent.length > 0 && (
        <Stack spacing={2}>
          {surveyContent.map((content, index) => {
            const contentLang = content.default_language ?? 'en';
            if (content.type === 'section') {
              return (
                <Stack key={content.id}>
                  <SurveySectionTitle>
                    {content.title[defaultSurveyLanguage] ?? content.title[contentLang]}
                  </SurveySectionTitle>
                  {content.questions?.map(
                    (question: SurveyQuestionnaireEditorContentOutput, index: number) => (
                      <Fragment key={index}>{renderQuestion(question)}</Fragment>
                    )
                  )}
                </Stack>
              );
            }
            return <Fragment key={index}>{renderQuestion(content)}</Fragment>;
          })}
        </Stack>
      )}
    </Box>
  );
};

export const RenderSubmitSection = () => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const submitSection = watch('submitSection') as SurveyQuestionnaireEditorContentOutput[];
  const title = submitSection[0]?.title?.[defaultSurveyLanguage] ?? '';
  const { renderQuestion } = useQuestion();

  return (
    <Box>
      {submitSection && submitSection.length > 0 && (
        <SurveySection>
          <SurveySectionTitle>{title}</SurveySectionTitle>
          <Stack spacing={3}>
            {submitSection[0]?.questions?.map((question, index) => (
              <Fragment key={index}>{renderQuestion(question)}</Fragment>
            ))}
          </Stack>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ width: '100%', maxWidth: 384, alignSelf: 'center' }}
          >
            <FormattedMessage id="survey.submit.button" defaultMessage="Submit" />
          </Button>
        </SurveySection>
      )}
    </Box>
  );
};

export const RenderThankyouPage = () => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const editorThankYouMessage = watch('surveyQuestionnaireEditorThankyouMessage');
  return (
    <Box>
      {editorThankYouMessage && (
        <SurveyMessageContent
          title={
            editorThankYouMessage?.title?.[defaultSurveyLanguage] ??
            editorThankYouMessage?.title?.[editorThankYouMessage.default_language ?? 'en'] ??
            ''
          }
          description={
            editorThankYouMessage?.description?.[defaultSurveyLanguage] ??
            editorThankYouMessage?.description?.[editorThankYouMessage.default_language ?? 'en'] ??
            ''
          }
        />
      )}
    </Box>
  );
};

export const RenderEmailInvitation = ({
  emailInvite,
  overallScoreQuestion,
}: RenderEmailInvitationProps) => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const { renderQuestion } = useQuestion();

  return (
    <Stack spacing={3} sx={{ width: 531, backgroundColor: 'white', padding: 5, borderRadius: 1 }}>
      <Typography variant="body1">
        {emailInvite?.invitation_email?.body?.[defaultSurveyLanguage] ??
          emailInvite?.invitation_email?.body?.[emailInvite.default_language ?? 'en'] ??
          ''}
      </Typography>
      <Box>
        {emailInvite?.embedOverallScore ? (
          renderQuestion(overallScoreQuestion as SurveyQuestionnaireEditorContentOutput)
        ) : (
          <Button variant="contained" size="large" sx={{ width: 150 }}>
            <FormattedMessage
              id="survey.survey-editor.email-invite.write-review-button"
              defaultMessage="Write a review"
            />
          </Button>
        )}
      </Box>
      <Typography variant="body1">
        {emailInvite?.invitation_email?.footer?.[defaultSurveyLanguage] ??
          emailInvite?.invitation_email?.footer?.[emailInvite.default_language ?? 'en'] ??
          ''}
      </Typography>
    </Stack>
  );
};
