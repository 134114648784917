import { type SyntheticEvent, useState } from 'react';

import { useDrawer } from '@trustyou/ui';

import { FiltersTabContent } from './filters-tab-content';
import { InboxDrawer } from './inbox-drawer';
import { ManageTabContent } from './manage-tab-content';
import { ViewsDropdown } from './views-dropdown';

import { useReviewsInfo } from '../../hooks';
import { useActiveFiltersCount } from '../../hooks/use-active-filters-count';
import { useFilters } from '../../hooks/use-filters';
import type { InboxDrawerTab } from '../../types';
import { FiltersButton } from '../filters-button/filters-button';

export function InboxHeader() {
  const { hasReviews } = useReviewsInfo();
  const { isOpen, openDrawer, closeDrawer } = useDrawer();
  const { views, clearFilters, reorderViews, deleteView } = useFilters();

  const activeFiltersCount = useActiveFiltersCount();

  const [currentTab, setCurrentTab] = useState<InboxDrawerTab>('manage');

  const handleOpenDrawer = (tab: InboxDrawerTab) => {
    setCurrentTab(tab);
    openDrawer();
  };

  const handleTabChange = (event: SyntheticEvent<Element, Event>, value: InboxDrawerTab) => {
    setCurrentTab(value);
  };

  return (
    <>
      <ViewsDropdown onManageViews={() => handleOpenDrawer('manage')} />
      <FiltersButton
        count={activeFiltersCount}
        isDisabled={!hasReviews}
        onClick={() => handleOpenDrawer('filters')}
        onClear={clearFilters}
      />
      <InboxDrawer
        isOpen={isOpen}
        onClose={closeDrawer}
        currentTab={currentTab}
        onChange={handleTabChange}
        ManageTabContent={
          <ManageTabContent views={views} onReorderViews={reorderViews} onDeleteView={deleteView} />
        }
        FiltersTabContent={<FiltersTabContent onSave={closeDrawer} />}
      />
    </>
  );
}
