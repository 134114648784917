import { FormattedMessage } from 'react-intl';

import type { CombinedUserAndInvitation } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

export type UserDeleteModalProps = {
  user?: CombinedUserAndInvitation;
  onClose: () => void;
  onDelete: () => void;
};

const UserDeleteModal = ({ user, onClose, onDelete }: UserDeleteModalProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="organization.users.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="organization.users.remove-user-content"
        defaultMessage="If you confirm, this user will no longer have access to your organization’s data."
        values={{ user: user?.name || user?.email }}
      />
    }
    dataName="user-delete"
    deleteText={
      <FormattedMessage id="organization.users.remove-user" defaultMessage="Remove user" />
    }
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage
        id="organization.users.removeMemberName"
        defaultMessage="Remove {user}?"
        values={{ user: user?.name || user?.email }}
      />
    }
    open={!!user}
  />
);

export default UserDeleteModal;
