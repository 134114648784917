import { type ChangeEvent, type SyntheticEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type ReviewEnvelopeDetailSchema,
  type SentimentEnum,
  useFetchPaginatedDetailedReviews,
  useFetchSubcategoryMapping,
  useFetchTopLevelCategoryMapping,
} from '@trustyou/inbox';
import { openInNewTab, useBasicEntities, useZendeskLinks } from '@trustyou/shared';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  EmptyReviewMatches,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  Typography,
} from '@trustyou/ui';

import { ReviewCard } from './ReviewCard/ReviewCard';
import { EMPTY_REVIEWS_DESCRIPTION, TABS } from './constants';
import { getEntityNamesMap, getSentimentByImpactScore, processData } from './utils';

import styles from './styles';

type ReviewsModalProps = {
  reviewIds: string[];
  categoryName: string;
  categoryType: string;
  categoryId: string;
  impactScore?: string;
  onClose: () => void;
};

type ReviewsTab = SentimentEnum | 'all';

export const ReviewsModal = ({
  reviewIds = [],
  categoryName,
  categoryType,
  categoryId,
  impactScore,
  onClose,
}: ReviewsModalProps) => {
  const { getReportSentimentIssueLink } = useZendeskLinks();
  const [selectedTab, setSelectedTab] = useState<ReviewsTab>(
    getSentimentByImpactScore(impactScore)
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: categoryMap = {} } = useFetchTopLevelCategoryMapping();
  const { data: subCategoryMap = {} } = useFetchSubcategoryMapping();
  const intl = useIntl();

  const { data: detailedReviewList, isPending: isLoading } = useFetchPaginatedDetailedReviews({
    page: 0,
    pageSize: 100,
    sort: {
      field: 'date',
      order: 'desc',
    },
    filters: {
      ids: reviewIds,
    },
  });

  const { paginatedData, entityIds, total } = processData(
    (detailedReviewList?.data || []) as ReviewEnvelopeDetailSchema[],
    categoryId,
    categoryType,
    categoryMap,
    subCategoryMap,
    selectedTab,
    page,
    rowsPerPage
  );

  const { data: entityData } = useBasicEntities(entityIds, !!detailedReviewList);

  const entityDataMap = getEntityNamesMap(entityData || []);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onTabChange = (_: SyntheticEvent, value: ReviewsTab) => {
    setSelectedTab(value);
    setPage(0);
  };

  const isEmpty = !isLoading && !paginatedData.length;

  return (
    <Dialog open={reviewIds.length > 0} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitle}>
        <Box sx={styles.titleContent}>
          <Typography variant="h5">
            <FormattedMessage
              id="analytics.reviews-modal.header"
              defaultMessage="Latest 100 reviews related to {category}, per sentiment"
              values={{
                category: categoryName,
              }}
            />
          </Typography>
          <IconButton data-testid="review-modal-close-button" onClick={onClose}>
            <Icon icon={faXmark} />
          </IconButton>
        </Box>
        <Tabs sx={styles.tabs} value={selectedTab} onChange={onTabChange}>
          {TABS.map((tab) => (
            <Tab value={tab.value} key={tab.value} label={intl.formatMessage(tab.label)} />
          ))}
        </Tabs>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {isLoading && (
          <Box sx={styles.loader}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          paginatedData.map((row) => (
            <ReviewCard
              id={row.id}
              key={row.id}
              text={row.review.text || ''}
              title={row.review.title || ''}
              entity={entityDataMap[row.meta.entity_id]}
              score={row.review.score}
              date={row.review.date}
              source={row.review.domain_name ?? ''}
              sentiments={row.sentiments as SentimentEnum[]}
              semaMatches={row.sema_matches}
            />
          ))}
        {isEmpty && (
          <EmptyReviewMatches
            title={
              <FormattedMessage
                id="analytics.reviews-modal.empty-state.title"
                defaultMessage="No matches"
              />
            }
            description={<FormattedMessage {...EMPTY_REVIEWS_DESCRIPTION[selectedTab]} />}
          />
        )}
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button
          endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          onClick={() => openInNewTab(getReportSentimentIssueLink())}
        >
          {intl.formatMessage({
            id: 'analytics.reviews-modal.suggest-improvement',
            defaultMessage: 'Suggest improvement',
          })}
        </Button>
        <Stack>
          <TablePagination
            hidden={isEmpty}
            component="div"
            count={total}
            rowsPerPageOptions={[10, 25, 50, 100]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Button sx={styles.closeButton} onClick={onClose} type="button" variant="contained">
            <FormattedMessage id="analytics.action.close" defaultMessage="Close" />
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
