import { useState } from 'react';
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from '@mui/system';
import {
  AdvancedFiltersSection,
  AnswerableReviewsOnly,
  DropdownFilters,
  type QueryOptions,
  ViewsDropdown,
  mapFiltersToViewFilters,
  useActiveFiltersCount,
  useFilters,
  useReviewsData,
} from '@trustyou/inbox';
import { type Filters, filtersSchema, useChangelingStore, useInboxStore } from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  ComposableDrawerWithStickyFooter,
  Divider,
  Stack,
  Typography,
} from '@trustyou/ui';

import { commonMessages, inboxFiltersStepMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `${theme.spacing(-3)} !important`,
}));

export function InboxFilterStep() {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();
  const { prevStep, nextStep } = useNewReportStore();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<Filters>({ resolver: zodResolver(filtersSchema) });

  // Fetch data sources and other necessary data
  const { dataGrid } = useInboxStore();
  const activeFiltersCount = useActiveFiltersCount();
  const { currentView, applyFilters, debugInvalidForm } = useFilters();

  // Fetching users selected entities
  const queryOptions: QueryOptions = {
    sortModel: dataGrid.sorting?.sortModel,
    page: dataGrid.pagination?.paginationModel?.page,
    pageSize: dataGrid.pagination?.paginationModel?.pageSize,
  };
  // Reason to call this is to get the data of user's selected entities
  //TODO: fix this api call in future because it currently fetches all the data related to reviews
  useReviewsData(queryOptions, mapFiltersToViewFilters(currentView.filters ?? {}));

  const onValid: SubmitHandler<Filters> = (form: Filters) => {
    applyFilters(form);
    setIsOpen(false);
  };

  const onInvalid: SubmitErrorHandler<Filters> = (event) => {
    debugInvalidForm(event);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onValid, onInvalid)}
        sx={styles.stepBody}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack spacing={5} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: 510 }}>
              <Typography variant="h6">
                {intl.formatMessage(inboxFiltersStepMessages.defineFiltersTitle)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage(inboxFiltersStepMessages.defineFiltersDescription)}
              </Typography>
            </Box>
            <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'flex-start', width: 510 }}>
              <ViewsDropdown isReports />
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                <FormattedMessage {...commonMessages.filters} />
                {activeFiltersCount > 0 && `(${activeFiltersCount})`}
              </Button>
            </Stack>
          </Stack>
          <ComposableDrawerWithStickyFooter
            anchor="right"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: {
                width: '320px',
                paddingTop: isChangeling ? 8 : 0,
              },
            }}
          >
            <ComposableDrawerWithStickyFooter.Content>
              <Stack spacing={2}>
                <DropdownFilters />
                <AnswerableReviewsOnly />
                <StyledDivider />
                <AdvancedFiltersSection />
                <StyledDivider />
              </Stack>
            </ComposableDrawerWithStickyFooter.Content>
            <ComposableDrawerWithStickyFooter.Footer
              primaryButton={
                <Button type="submit" variant="contained">
                  <FormattedMessage id="inbox.action.apply" defaultMessage="Apply" />
                </Button>
              }
              secondaryButton={
                <Button onClick={() => setIsOpen(false)} type="button" color="inherit">
                  <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
                </Button>
              }
              sx={{ boxShadow: 5 }}
            />
          </ComposableDrawerWithStickyFooter>
        </Stack>
      </Box>
      <Footer onClickBack={prevStep} onClickNext={nextStep} />
      <BackdropSpinner isLoading={false} />
    </FormProvider>
  );
}
