import { useState } from 'react';

import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { BENCHMARKS_DEFAULT_SORT_MODEL } from '@trustyou/shared';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_MODEL,
  DRAWER_PAGINATION_MODEL,
} from '../constants/organization';

// if any grid is bound to specific id (for example, organizationId) we need to keep it in store
// to avoid using models if another id is chosen

export type GridState = {
  gridId: string | undefined;
  paginationModel: GridPaginationModel;
  columnVisibilityModel: GridColumnVisibilityModel;
  sortModel: GridSortModel;
  searchKey: string | undefined;
  setGridId: (id: string | undefined) => void;
  setPaginationModel: (model: GridPaginationModel) => void;
  setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
  setSortModel: (model: GridSortModel) => void;
  setSearchKey: (key: string | undefined) => void;
  resetGrid: () => void;
};

export const gridPersist = (
  gridName: string,
  defaultPaginationModel: GridPaginationModel,
  defaultSortModel: GridSortModel
) =>
  persist<GridState>(
    (set) => ({
      gridId: undefined,
      paginationModel: defaultPaginationModel,
      columnVisibilityModel: {},
      sortModel: defaultSortModel,
      searchKey: undefined,
      setGridId: (id) => set({ gridId: id }),
      setPaginationModel: (model) => set({ paginationModel: model }),
      setColumnVisibilityModel: (model) => set({ columnVisibilityModel: model }),
      setSortModel: (model) => set({ sortModel: model }),
      setSearchKey: (key) => set({ searchKey: key }),
      resetGrid: () =>
        set({
          paginationModel: defaultPaginationModel,
          columnVisibilityModel: {},
          sortModel: defaultSortModel,
          searchKey: undefined,
        }),
    }),
    {
      name: `${gridName}_gridState`, // Specify the name for sessionStorage
      storage: createJSONStorage(() => sessionStorage), // Use sessionStorage
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      partialize: (state) => ({
        // This is to ignore saving search key in the session
        gridId: state.gridId,
        columnVisibilityModel: state.columnVisibilityModel,
        sortModel: state.sortModel,
      }),
    }
  );

const usePaginationStateWrapper = (gridStore: GridState) => {
  const [paginationModel, setPaginationModel] =
    useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  return { ...gridStore, paginationModel, setPaginationModel };
};

const useAdminEntitiesGridStoreCore = create<GridState>()(
  gridPersist('admin_entities', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useAdminEntitiesGridStore = () =>
  usePaginationStateWrapper(useAdminEntitiesGridStoreCore());

const useAdminOrganizationsGridStoreCore = create<GridState>()(
  gridPersist('admin_organizations', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useAdminOrganizationsGridStore = () =>
  usePaginationStateWrapper(useAdminOrganizationsGridStoreCore());

const useGlobalEntitiesGridStoreCore = create<GridState>()(
  gridPersist('global_entities', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useGlobalEntitiesGridStore = () =>
  usePaginationStateWrapper(useGlobalEntitiesGridStoreCore());

const useGlobalOrganizationUsersGridStoreCore = create<GridState>()(
  gridPersist('global_organization_users', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useGlobalOrganizationUsersGridStore = () =>
  usePaginationStateWrapper(useGlobalOrganizationUsersGridStoreCore());

const useGlobalServiceUsersGridStoreCore = create<GridState>()(
  gridPersist('global_service_users', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useGlobalServiceUsersGridStore = () =>
  usePaginationStateWrapper(useGlobalServiceUsersGridStoreCore());

const useCombinedUsersGridStoreCore = create<GridState>()(
  gridPersist('combined_organization_users', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useCombinedUsersGridStore = () =>
  usePaginationStateWrapper(useCombinedUsersGridStoreCore());

const useEntitiesGridStoreCore = create<GridState>()(
  gridPersist('entities', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useEntitiesGridStore = () => usePaginationStateWrapper(useEntitiesGridStoreCore());

const useGroupEntitiesGridStoreCore = create<GridState>()(
  gridPersist('group_entities', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useGroupEntitiesGridStore = () =>
  usePaginationStateWrapper(useGroupEntitiesGridStoreCore());

const useReviewProviderDetailsGridStoreCore = create<GridState>()(
  gridPersist('review_provider_details', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useReviewProviderDetailsGridStore = () =>
  usePaginationStateWrapper(useReviewProviderDetailsGridStoreCore());

const useAccessGroupsGridStoreCore = create<GridState>()(
  gridPersist('access_groups', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useAccessGroupsGridStore = () =>
  usePaginationStateWrapper(useAccessGroupsGridStoreCore());

const useEntitySubscriptionsGridStoreCore = create<GridState>()(
  gridPersist('entity_subscriptions', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useEntitySubscriptionsGridStore = () =>
  usePaginationStateWrapper(useEntitySubscriptionsGridStoreCore());

const useSubscriptionEntitiesGridStoreCore = create<GridState>()(
  gridPersist('subscription_entities', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useSubscriptionEntitiesGridStore = () =>
  usePaginationStateWrapper(useSubscriptionEntitiesGridStoreCore());

const useSubscriptionMembersGridStoreCore = create<GridState>()(
  gridPersist('subscription_members', DEFAULT_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useSubscriptionMembersGridStore = () =>
  usePaginationStateWrapper(useSubscriptionMembersGridStoreCore());

const useBenchmarksGridStoreCore = create<GridState>()(
  gridPersist('benchmarks', DEFAULT_PAGINATION_MODEL, BENCHMARKS_DEFAULT_SORT_MODEL)
);
export const useBenchmarksGridStore = () => usePaginationStateWrapper(useBenchmarksGridStoreCore());

const useBenchmarkOwnedEntitiesGridStoreCore = create<GridState>()(
  gridPersist('benchmark_own_entities', DRAWER_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useBenchmarkOwnedEntitiesGridStore = () =>
  usePaginationStateWrapper(useBenchmarkOwnedEntitiesGridStoreCore());

const useBenchmarkCompetitorEntitiesGridStoreCore = create<GridState>()(
  gridPersist('benchmark_competitor_entities', DRAWER_PAGINATION_MODEL, DEFAULT_SORT_MODEL)
);
export const useBenchmarkCompetitorEntitiesGridStore = () =>
  usePaginationStateWrapper(useBenchmarkCompetitorEntitiesGridStoreCore());
