import { Box } from '@mui/material';

type CustomCellProps = {
  text: string;
  variant: 'emphasized' | 'de-emphasized';
};

export function CustomCell({ text, variant }: CustomCellProps) {
  return (
    <Box
      component="span"
      sx={{
        ...(variant === 'emphasized' && { fontWeight: 500 }),
        ...(variant === 'de-emphasized' && { color: 'text.secondary' }),
      }}
    >
      {text}
    </Box>
  );
}
