import { FormattedMessage } from 'react-intl';

import { useManageBenchmarksPermission, useRouteUtils } from '@trustyou/shared';
import {
  ContentPane,
  EmptyStatePlaceholder,
  personAnalyzingChartsWithMagnifyingGlass,
} from '@trustyou/ui';

import styles from './styles';

export const BenchmarksEmptyState = () => {
  const isManageBenchmarksAllowed = useManageBenchmarksPermission();
  const { navigateWithBase } = useRouteUtils();

  return (
    <ContentPane sx={styles.emptyStateContentPane}>
      <EmptyStatePlaceholder
        dataName="benchmarks-dashboard"
        img={personAnalyzingChartsWithMagnifyingGlass}
        title={
          <FormattedMessage
            id="analytics.benchmarks-dashboard.empty-state.title"
            defaultMessage="No benchmarks set up yet"
          />
        }
        description={
          <FormattedMessage
            id="analytics.benchmarks-dashboard.empty-state.description"
            defaultMessage="Once set up, they will show up here"
          />
        }
        emptyStateHeader={styles.emptyStateHeader}
        emptyStateContainer={styles.emptyStateContainer}
        action={
          isManageBenchmarksAllowed
            ? {
                testId: 'benchmarks-dashboard-empty-state-button',
                message: (
                  <FormattedMessage
                    id="analytics.benchmarks-dashboard.empty-state.action"
                    defaultMessage="Add benchmarks"
                  />
                ),
                handler: () => {
                  navigateWithBase('benchmarks', {
                    state: { createBenchmark: true },
                  });
                },
                styles: styles.emptyStateAction,
              }
            : undefined
        }
      />
    </ContentPane>
  );
};
