import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCurrentSubscriptionPermission } from '@trustyou/shared';

import type { PaginationRequest } from '../../../../types/model/shared';
import {
  EDIT_SUBSCRIPTION,
  FETCH_ASSIGNABLE_ENTITIES_COUNT,
  FETCH_CURRENT_SUBSCRIPTION,
  FETCH_ENTITY_SUBSCRIPTIONS,
  FETCH_INFINITE_ASSIGNABLE_ENTITIES,
  FETCH_INFINITE_SUBSCRIPTION_ENTITIES,
  FETCH_MEMBERSHIPS_AND_INVITATIONS,
  FETCH_SPECIFIC_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_ENTITIES,
  FETCH_SUBSCRIPTION_MEMBERS,
} from '../../../constants/queryKeys';
import {
  editSubscription,
  fetchAssignableEntities,
  fetchAssignableEntitiesCount,
  fetchCurrentSubscription,
  fetchEntitySubscriptions,
  fetchSubscription,
  fetchSubscriptionEntities,
  fetchSubscriptionMembers,
} from '../../../queries';

export function useCurrentSubscription() {
  const hasCurrentSubscriptionPermission = useCurrentSubscriptionPermission();
  return useQuery({
    queryKey: [FETCH_CURRENT_SUBSCRIPTION],
    queryFn: fetchCurrentSubscription,
    refetchOnWindowFocus: false,
    enabled: hasCurrentSubscriptionPermission,
  });
}

export function useSubscription(subscriptionId?: string) {
  return useQuery({
    queryKey: [FETCH_SPECIFIC_SUBSCRIPTION, { subscriptionId }],
    queryFn: () => fetchSubscription(subscriptionId),
    refetchOnWindowFocus: false,
    enabled: !!subscriptionId,
  });
}

export function useEntitySubscriptions(pagination: PaginationRequest, searchKey?: string) {
  return useQuery({
    queryKey: [FETCH_ENTITY_SUBSCRIPTIONS, { pagination, searchKey }],
    queryFn: () => fetchEntitySubscriptions(pagination, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useSubscriptionMembers(
  subscriptionId: string,
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_SUBSCRIPTION_MEMBERS, { subscriptionId, pagination, sortKey, searchKey }],
    queryFn: () => fetchSubscriptionMembers(subscriptionId, pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useSubscriptionEntities(
  subscriptionId: string,
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_SUBSCRIPTION_ENTITIES, { subscriptionId, pagination, sortKey, searchKey }],
    queryFn: () => fetchSubscriptionEntities(subscriptionId, pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useSingleEntity(subscriptionId: string) {
  return useQuery({
    queryKey: [FETCH_SUBSCRIPTION_ENTITIES, { subscriptionId }],
    queryFn: () => fetchAssignableEntities(subscriptionId, { limit: 1, offset: 0 }, 'name'),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useAssignableEntityCount(subscriptionId?: string) {
  return useQuery({
    queryKey: [FETCH_ASSIGNABLE_ENTITIES_COUNT, { subscriptionId }],
    queryFn: () => fetchAssignableEntitiesCount(subscriptionId),
    refetchOnWindowFocus: false,
    enabled: !!subscriptionId,
  });
}

export function useEditSubscription() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editSubscription,
    mutationKey: [EDIT_SUBSCRIPTION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_CURRENT_SUBSCRIPTION] });
      queryClient.refetchQueries({ queryKey: [FETCH_SPECIFIC_SUBSCRIPTION] });
      queryClient.refetchQueries({ queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS] });
    },
  });
}

export function useInfiniteSubscriptionEntities(
  subscriptionId: string,
  pageSize: number,
  sortKey: string,
  searchKey?: string
) {
  return useInfiniteQuery({
    queryKey: [
      FETCH_INFINITE_SUBSCRIPTION_ENTITIES,
      { subscriptionId, pageSize, sortKey, searchKey },
    ],
    queryFn: ({ pageParam }) =>
      fetchSubscriptionEntities(
        subscriptionId,
        { offset: pageParam, limit: pageSize },
        sortKey,
        searchKey
      ),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
    placeholderData: keepPreviousData,
  });
}

export function useInfiniteAssignableEntities(
  subscriptionId: string,
  pageSize: number,
  sortKey: string,
  searchKey?: string
) {
  return useInfiniteQuery({
    queryKey: [
      FETCH_INFINITE_ASSIGNABLE_ENTITIES,
      { subscriptionId, pageSize, sortKey, searchKey },
    ],
    queryFn: ({ pageParam }) =>
      fetchAssignableEntities(
        subscriptionId,
        { offset: pageParam, limit: pageSize },
        sortKey,
        searchKey
      ),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
    placeholderData: keepPreviousData,
  });
}
