import type { SemaTopics } from '../types';

export type Mapping = {
  [sector: string]: {
    [categoryId: string]: string[];
  };
};

export const mapBySectors = (ids: string[], mapping?: Mapping, sectors?: string[]) => {
  if (!mapping) return;
  return Object.keys(mapping).reduce((result: SemaTopics, sector: string) => {
    if (sectors && !sectors.includes(sector)) return result;

    // all filtered unique sector entries
    const sectorEntries = [
      ...new Set([
        ...(result?.[sector] ?? []),
        // get array of mapped sector content for given ids
        ...(ids ?? []).reduce(
          (res: string[], id: string) => [...res, ...(mapping[sector][id] ?? [])],
          []
        ),
      ]),
    ];
    return {
      ...result,
      ...(sectorEntries.length > 0 && { [sector.toUpperCase()]: sectorEntries }),
    };
  }, {});
};

export const mergeSectorMaps = (map1?: SemaTopics, map2?: SemaTopics) => {
  const res: SemaTopics = { ...map1 };

  // sector content union of both maps without duplicates
  map2 &&
    Object.keys(map2).forEach((sector) => {
      res[sector] = [...new Set([...(res[sector] ?? []), ...(map2[sector] ?? [])])];
    });
  return res;
};

export const mapToSubCategories = (topLevelSectors: SemaTopics, mapping?: Mapping) => {
  if (!topLevelSectors || !mapping) return;
  return Object.keys(topLevelSectors).reduce(
    (result: SemaTopics, sector: string) => ({
      ...result,
      // get all unique subcategories for each top level category in one array per sector
      [sector.toUpperCase()]: topLevelSectors[sector].reduce(
        (res: string[], id: string) => [...new Set([...res, ...mapping[sector.toLowerCase()][id]])],
        []
      ),
    }),
    {}
  );
};
