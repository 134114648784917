import { FormattedMessage } from 'react-intl';

import { FilterDialogButton } from '../../filter-dialog-button';
import { NotificationDialogButton } from '../../notification-dialog-button';

interface SurveyDialogOpenButtonProps {
  isNotificationButton?: boolean;
  total?: number;
  selected: number;
  openDialog: () => void;
}

export const SurveyDialogOpenButton = ({
  isNotificationButton,
  total,
  selected,
  openDialog,
}: SurveyDialogOpenButtonProps) => {
  const showNotificationButton = () => (
    <NotificationDialogButton
      title={
        <FormattedMessage id="inbox.notification.survey.filter.name" defaultMessage="Survey" />
      }
      description={
        total && (
          <FormattedMessage
            id="inbox.notification.survey.filter.selected"
            defaultMessage="{count} / {total} selected"
            values={{
              count: selected > 0 ? selected : total,
              total: total,
            }}
          />
        )
      }
      onClick={openDialog}
    />
  );

  const showFilterButton = () => (
    <FilterDialogButton
      title={<FormattedMessage id="inbox.filter.surveys.name" defaultMessage="Surveys" />}
      description={total && `${selected > 0 ? selected : total} / ${total}`}
      onClick={openDialog}
    />
  );

  return isNotificationButton ? showNotificationButton() : showFilterButton();
};
