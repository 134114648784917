import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { DateRange } from '@mui/x-date-pickers-pro';
import type { FormattedCustomTimeRange } from '@trustyou/shared';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@trustyou/ui';
import dayjs, { type Dayjs } from 'dayjs';

import CustomDateRangePicker from './custom-date-range-picker';

import { actionMessages } from '../../constants/messages';
import { getCustomTimerangeValue } from '../../utils/mappers';

export type CustomDateRangeDialogProps = {
  isOpen: boolean;
  initialValue: FormattedCustomTimeRange;
  onApply: (range: DateRange<Dayjs>) => void;
  onClose: () => void;
};

export const CustomDateRangeDialog = ({
  isOpen,
  initialValue,
  onApply,
  onClose,
}: CustomDateRangeDialogProps) => {
  const intl = useIntl();
  const { start, end } = getCustomTimerangeValue(initialValue).timerange;
  const initialStart = start ? dayjs(start * 1000) : dayjs();
  const initialEnd = end ? dayjs(end * 1000) : dayjs();
  const [range, setRange] = useState<DateRange<Dayjs>>([initialStart, initialEnd]);

  const applyDateRange = () => {
    onApply(range);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {intl.formatMessage({ id: 'inbox.custom-date-range', defaultMessage: 'Custom Date Range' })}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '8px !important' }}>
        <CustomDateRangePicker value={range} onChange={setRange} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {intl.formatMessage(actionMessages.cancel)}
        </Button>
        <Button variant="contained" onClick={applyDateRange}>
          {intl.formatMessage(actionMessages.apply)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
