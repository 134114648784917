import { useEffect } from 'react';

import { type Subscription, useScopeStore, useSingleEntity } from '@trustyou/shared';

interface SingleEntitySelectorProps {
  subscription: Subscription;
}

export function SingleEntitySelector({ subscription }: SingleEntitySelectorProps) {
  const { setSelectedEntities } = useScopeStore();

  const { data, isSuccess } = useSingleEntity(subscription.id);

  useEffect(() => {
    if (isSuccess) {
      // FIXME: ASS-2037
      setTimeout(() => {
        setSelectedEntities(data.data);
      }, 50);
    }
  }, [data, isSuccess, setSelectedEntities]);

  return null;
}
