import { Select, type SelectProps } from '@mui/material';

import { FONT_SIZE } from './constants';

export function StyledSelect<T>({ sx, ...props }: SelectProps<T>) {
  return (
    <Select
      sx={{
        '& .MuiSelect-select': { fontSize: FONT_SIZE },
        ...sx,
      }}
      {...props}
    />
  );
}
