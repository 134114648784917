import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_ORGANIZATION_LOGO,
  UPDATE_ORGANIZATION_PERMISSION,
  commonFields,
  isDefined,
  useEditUserOrganization,
  usePermissionStore,
  useUserOrganization,
  validationErrors,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  ContentPane,
  DiscardChangesModal,
  Stack,
  TextField,
  Typography,
  snackbar,
} from '@trustyou/ui';
import * as yup from 'yup';

import { LogoUpload } from './LogoUpload';

import { styles } from './styles';

export interface OrganizationProfileFormData {
  organization_name: string;
}

const ORGANIZATION_NAME_MAX_LENGTH = 100;

const OrganizationProfile = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { hasPermissions } = usePermissionStore();
  const [selectedLogo, setSelectedLogo] = useState<string>();

  const { data: organizationDetails } = useUserOrganization(true);
  const organizationName = organizationDetails?.name;

  const editOrganization = useEditUserOrganization();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const isUpdateOrganizationAllowed = hasPermissions(UPDATE_ORGANIZATION_PERMISSION);

  const schema = yup.object().shape({
    organization_name: yup
      .string()
      .required(intl.formatMessage(validationErrors.missingOrganizationName)),
  });

  const formData = useForm<OrganizationProfileFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      organization_name: '_',
    },
  });

  const { register, formState, reset, handleSubmit } = formData;

  useEffect(() => {
    if (organizationName) {
      reset({
        organization_name: organizationName,
      });
    }
  }, [organizationName, reset]);

  const submitOrganizationNameChange = (values: OrganizationProfileFormData) => {
    editOrganization.mutate(
      {
        name: values.organization_name,
        ...(isDefined(selectedLogo) && { logo_uri: selectedLogo }),
      },
      {
        onSuccess: (data) => {
          snackbar.success(
            intl.formatMessage({
              id: 'operations.organization.changesSaved',
              defaultMessage: 'Changes saved',
            })
          );
          reset({ organization_name: data.name });
          setSelectedLogo(undefined);
        },
      }
    );
  };

  const cancelClicked = () => {
    if (formState.isDirty || isDefined(selectedLogo)) {
      setShowCancelConfirmation(true);
    }
  };

  const resetLogo = () => {
    setSelectedLogo(undefined);
    queryClient.removeQueries({ queryKey: [FETCH_ORGANIZATION_LOGO] });
  };

  const showTotals = !!organizationDetails?.summary;

  return (
    <ContentPane
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
      headers={[
        <Typography variant="h6" color="text.primary">
          <FormattedMessage
            id="settings.general.organization-profile.title"
            defaultMessage="Organization Profile"
          />
        </Typography>,
      ]}
    >
      <Box sx={styles.organizationDataWrapper}>
        <Stack>
          <LogoUpload updateAllowed={isUpdateOrganizationAllowed} onUpload={setSelectedLogo} />
          <form>
            <FormattedMessage
              id="settings.general.organization-profile.organization-name"
              defaultMessage="Organization name"
            >
              {(message) => (
                <TextField
                  sx={styles.organizationName}
                  data-testid="organization-profile-name"
                  label={message}
                  variant="outlined"
                  // disable while backend to change name is not ready
                  disabled={!isUpdateOrganizationAllowed || editOrganization.isPending}
                  {...register('organization_name')}
                  error={!!formState.errors.organization_name}
                  // We need to keep the same height of the form no matter have we error or not
                  helperText={formState.errors.organization_name?.message || ' '}
                  inputProps={{ maxLength: ORGANIZATION_NAME_MAX_LENGTH }}
                />
              )}
            </FormattedMessage>
          </form>
        </Stack>
        {showTotals && (
          <Stack direction="row" sx={styles.statsContainer}>
            <Stack>
              <Typography variant="h6">{organizationDetails.summary?.entities_count}</Typography>
              <Typography variant="caption" color="text.secondary">
                {intl.formatMessage(commonFields.entities)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6">{organizationDetails.summary?.members_count}</Typography>
              <Typography variant="caption" color="text.secondary">
                {intl.formatMessage(commonFields.users)}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Box>
      {isUpdateOrganizationAllowed && (
        <Box sx={styles.pageActions}>
          <Button
            color="primary"
            variant="text"
            disabled={!isDefined(selectedLogo) && !formState.isDirty}
            onClick={cancelClicked}
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            type="submit"
            variant="contained"
            data-testid="save-changes-organization-name"
            disabled={
              !isDefined(selectedLogo) &&
              (!formState.isDirty || !formState.isValid || editOrganization.isPending)
            }
            onClick={handleSubmit(submitOrganizationNameChange)}
          >
            <FormattedMessage {...commonFields.saveChanges} />
          </Button>
        </Box>
      )}
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={() => {
          reset();
          resetLogo();
          setShowCancelConfirmation(false);
        }}
        open={showCancelConfirmation}
      />
      <BackdropSpinner isLoading={editOrganization.isPending} />
    </ContentPane>
  );
};

export default OrganizationProfile;
