import { useState } from 'react';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_CONTENT_LIBRARY_PAGE_SIZE,
  useDataGridIntl,
  useLanguageStore,
} from '@trustyou/shared';
import { DataGridPro } from '@trustyou/ui';

import { useColumns } from './use-columns';
import { useRows } from './use-rows';

import type { ContentEnum } from '../../../client';
import { useContents } from '../../../service/hooks/use-questions';
import { CustomToolbar } from '../../shared/custom-toolbar';
import styles from '../../shared/styles';

const TEXT_BASED_TYPES: ContentEnum[] = [
  'email_invitation',
  'section',
  'text',
  'thankyou_message',
  'welcome_message',
];

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_CONTENT_LIBRARY_PAGE_SIZE,
  });
  const { data: contents, isPending: isLoading } = useContents(TEXT_BASED_TYPES);
  const columns = useColumns();
  const rows = useRows(contents);

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      loading={isLoading}
      pageSizeOptions={[10, 20, 30]}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      ignoreDiacritics
      slots={{
        toolbar: () => CustomToolbar({ hideColumns: true }),
      }}
      sx={{ ...styles.disableCellOutline, border: 'none' }}
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
