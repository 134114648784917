import { useIntl } from 'react-intl';

import type { FormControlProps } from '@mui/material';
import type { FiltersField } from '@trustyou/shared';

import { DropdownMultipleAutocomplete } from './dropdown-multiple-autocomplete';
import { useScoreOptions } from './use-score-options';

import { useFilters } from '../../hooks/use-filters';

export type DropdownScoreProps = FormControlProps & {
  fieldName: FiltersField;
};

export function DropdownScore({ fieldName, ...props }: DropdownScoreProps) {
  const intl = useIntl();
  const scoreOptions = useScoreOptions();
  const { currentView } = useFilters();

  return (
    <DropdownMultipleAutocomplete
      fieldName={fieldName}
      label={intl.formatMessage({
        id: 'inbox.filter.rating.label',
        defaultMessage: 'Rating',
      })}
      options={scoreOptions}
      initialValues={currentView.filters?.scores ?? []}
      {...props}
    />
  );
}
